import React from 'react';

import Input from './Input';
import { ErrorMessage } from 'formik';
import { formStyles } from '../../lib/constants/SiteVariables';

export default function StickyInput(props) {
  if (!props.id || !props.placeholder) throw 'Missing required props';

  return (
    <div className="form-floating flex flex-col relative focus-within:border-primary-blue" style={props.style}>
      <Input {...props} />
      <label
        htmlFor={props.id}
        className="placeholder absolute top-0 left-0 text-sm xxs:text-lg text-primary-blue bg-white -z-1 duration-300 origin-0"
      >
        {props.placeholder}
      </label>
      <ErrorMessage
        name={props.id}
        component="div"
        className={formStyles.validation}
      />
    </div>
  );
}
