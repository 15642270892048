import * as ActionTypes from '../ActionTypes';
import Rollbar from 'rollbar';
import { platform } from '../../lib/constants/SiteVariables';

const debugPlatforms = ['staging', 'production'];
const env = platform();
const rollbar = new Rollbar({
  enabled: debugPlatforms.includes(env),
  accessToken: process.env.REACT_APP_ROLLBAR,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    source_map_enabled: true,
    environment: env,
    code_version: process.env.REACT_APP_GIT_SHA,
    guess_uncaught_frames: true,
  },
});

export const asyncActions = (...actions) => {
  return (dispatch, getState) => {
    return actions
      .filter((a) => a)
      .reduce((previousPromise, nextAsyncFunction) => {
        return previousPromise.then(() => dispatch(nextAsyncFunction));
      }, Promise.resolve())
      .catch(async (err) => {
        const parsedError = await err;

        // Selectivly rethrow expceptions. Errors from our API will be rethrown
        // but network failures and runtime errors will go to the Redux general
        // store and another component will handle those globally.
        //
        // This is meant to be a distinction between user addressable issues
        // vs non addressable ones.
        if (
          !['runtime', 'unknown'].includes(parsedError.source) &&
          parsedError.status !== 401
        ) {
          throw parsedError;
        } else {
          dispatch({
            type: ActionTypes.SET_ERROR,
            payload: parsedError,
          });

          // Signal to the caller that this failed since we won't get
          // into a `catch` in these cases.
          return false;
        }
      });
  };
};

export const addError = (payload) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SET_ERROR,
      payload,
    });
  };
};

export const clearErrors = () => ({
  type: ActionTypes.CLEAR_ERRORS,
});

export const reportError = (errorObject) => {
  return async (dispatch, getState) => {
    try {
      rollbar.critical(
        errorObject,
        getState().general.get('debugging'),
        (err, data) => {
          if (!err && data) {
            dispatch(setDebugData('uuid', data.result.uuid));
          }
        },
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export const setDebugData = (key, val) => ({
  type: ActionTypes.SET_DEBUG,
  payload: {
    key: key,
    value: val,
  },
});

export const resetState = () => ({
  type: ActionTypes.RESET_GENERAL,
});

export const toggleRethrow = () => ({
  type: ActionTypes.TOGGLE_RETHROW,
});

export const loading = (bool) => ({
  type: ActionTypes.APP_LOADING,
  payload: bool,
});

export const setPossessedUserId = (userId) => ({
  type: ActionTypes.SET_POSSESSED_USER,
  payload: userId || '',
});

export const setPromoId = id => ({
  type: ActionTypes.SET_PROMO_ID,
  payload: id
})
