import React, { useState, useEffect, useContext, createContext } from 'react';
import Session from 'supertokens-auth-react/recipe/session';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import {
  pdGetUser,
  pdIsExpired,
  pdRemoveCreditKey,
  pdSaveToken,
} from '../lib/constants/PersistentData';
import {
  verify as userVerify,
  verifyToken as userVerifyToken,
} from '../store/actions/users';
import { resetState } from '../store/actions/general';
import { resetEntities } from '../store/actions/entities';
import { USE_SUPERTOKENS } from '../lib/constants/SiteVariables';

// Concepts based off of https://usehooks.com/useAuth/

const authContext = createContext();

export function ProvideAuth({ children }) {
  if (USE_SUPERTOKENS) return ProvideSuperTokensAuth({ children });
  return ProvidePersistentDataAuth({ children });
}

export function ProvidePersistentDataAuth({ children }) {
  const persistentDataAuth = usePersistentDataAuth();

  return (
    <authContext.Provider value={persistentDataAuth}>
      {children}
    </authContext.Provider>
  );
}

export function ProvideSuperTokensAuth({ children }) {
  const superTokensAuth = useSuperTokensAuth();

  return (
    <authContext.Provider value={superTokensAuth}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};

function usePersistentDataAuth() {
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signin = (verifiedUser, verifiedToken) => {
    pdSaveToken(verifiedUser, verifiedToken);
    setUser(verifiedUser);
  };

  const signout = () => {
    pdRemoveCreditKey();
    setUser(null);
    dispatch(resetEntities());
    dispatch(resetState());
  };

  const isAuthenticated = () => {
    const user = pdGetUser();
    const expired = pdIsExpired();
    return user && user.id && !expired;
  };

  const redirectToLogin = (location) => {
    return navigate('/login', { state: { from: location }, replace: true });
  };

  const verify = (type, contact, send_to_email) =>
    userVerify(type, contact, send_to_email);

  const verifyToken = (type, contact, token) =>
    userVerifyToken(type, contact, token);

  return {
    user,
    setUser,
    isAuthenticated,
    redirectToLogin,
    signin,
    signout,
    verify,
    verifyToken,
  };
}

function useSuperTokensAuth() {
  const sessionContext = useSessionContext();
  const [session, setSession] = useState({});
  const [user, setUser] = useState({});

  useEffect(() => {
    if (!sessionContext.loading) {
      setSession(sessionContext);
      setUser({ id: sessionContext.userId });
    }
  }, [sessionContext]);

  const isAuthenticated = () => {
    if (sessionContext.loading) return true; // return true until session data loaded
    return !sessionContext.loading && sessionContext.doesSessionExist;
  };

  const signin = (verifiedUser, verifiedToken) => {
    redirectToLogin();
  };

  const signout = () => {
    Session.signOut().then(() => redirectToLogin());
  };

  const redirectToLogin = (location) => {
    const currentUrl = window.location.origin + window.location.pathname;
    const redirectUrl = new URL('http://ck-login.creditkey.localhost'); // TODO: set based on infra/config
    redirectUrl.searchParams.set('r', currentUrl);
    return window.location.replace(redirectUrl);
  };

  return {
    user,
    setUser,
    isAuthenticated,
    redirectToLogin,
    signin,
    signout,
    // verify, // not needed
    // verifyToken, // not needed
    session,
  };
}
