import React from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ModalWrapper from '../layout/ModalWrapper';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useEntity from '../../../hooks/useEntity';
import useToast from '../../../hooks/useToast';
import { asyncActions, loading } from '../../../store/actions/general';
import { getAccount } from '../../../store/actions/accounts';
import { getCompany } from '../../../store/actions/companies';
import { supportPhone } from '../../../lib/constants/SiteVariables';
import {
  deletePaymentMethod,
  getPaymentMethods,
} from '../../../store/actions/payment_methods';

export default function DeletePmModal({
  isModalActive,
  toggleModal,
  payment_method,
}) {
  const dispatch = useDispatch();
  const [entity] = useEntity();
  const [user] = useCurrentUser();
  const { makeToast } = useToast();

  const deletePM = (payment_method) => {
    const encodedEmail = encodeURIComponent(user.email);

    dispatch(loading(true));
    dispatch(
      asyncActions(
        deletePaymentMethod(payment_method),
        getPaymentMethods(`filter[email]=${encodedEmail}`),
        entity.type === 'company'
          ? getCompany(user?.company_id, 'include=cta_banners')
          : getAccount(user?.account_id),
      ),
    )
      .then(() =>
        makeToast(
          'Payment Method Successfully Deleted',
          'positive-green',
          'check-circle',
        ),
      )
      .finally(() => dispatch(loading(false)));
  };

  return (
    <div data-testid={DeletePmModal.role}>
      <ModalWrapper isModalActive={isModalActive} toggleModal={toggleModal}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <FontAwesomeIcon
              icon={['far', 'exclamation-triangle']}
              className="text-negative-red mb-1"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 text-negative-red"
            >
              {!payment_method?.is_default
                ? 'Are you sure?'
                : 'Default payment methods cannot be deleted.'}
            </Dialog.Title>
            {!payment_method?.is_default ? (
              <div className="mt-2">
                <p className="text-sm text-opaque-navy">
                  Please confirm you'd like to delete
                  <span className="mx-1 font-avenir-medium text-primary-blue">
                    {payment_method?.payment_type === 'card'
                      ? payment_method?.credit_card_type
                      : payment_method?.payment_method_label}
                    <span className="ml-1">x</span>
                    {payment_method?.payment_method_last_4}
                  </span>
                  as a payment method.
                </p>
              </div>
            ) : (
              // ---------> You cannot remove a default PM <---------
                <div className="text-sm text-opaque-navy">
                <div className="mt-6">
                  Please set a new default payment method before proceeding{' '}
                  <br />
                  or contact our support team for further assistance.
                  <p className="mt-2">
                    <a
                      href="mailto: support@creditkey.com"
                      className="no-underline text-primary-blue mr-2 cursor-pointer"
                    >
                      support@creditkey.com
                    </a>
                    or
                    <a
                      href={`tel:${supportPhone}`}
                      className="ml-2 no-underline text-primary-blue cursor-pointer"
                    >
                      {supportPhone}
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          {!payment_method?.is_default && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                deletePM(payment_method);
                toggleModal();
              }}
            >
              Delete
            </button>
          )}
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-opaque-navy-border shadow-sm px-4 py-2 bg-white text-base font-medium text-navy-blue hover:text-opaque-navy sm:mt-0 sm:w-auto sm:text-sm"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </ModalWrapper>
    </div>
  );
}

DeletePmModal.role = 'delete-pm-modal-component';
