import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from '../../hooks/useAuth';

export default function AuthButton({
  onClick,
  extend,
  toggleExtend,
  className,
}) {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <div className="flex items-center cursor-pointer hover:bg-white transition">
      {auth?.isAuthenticated() ? (
        extend ? (
          <div
            className={
              className
                ? className
                : 'flex items-center font-avenir-book text-opaque-navy text-xs md:text-sm pl-4 md:pl-8 py-2 md:py-3 whitespace-nowrap'
            }
            onClick={() => {
              auth.signout();
              navigate('/');
            }}
            role={AuthButton.role}
          >
            <span>Sign out</span>
            <FontAwesomeIcon
              icon={['fal', 'arrow-right-from-bracket']}
              className="shrink-0 mx-2 text-sm cursor-pointer hover:text-primary-blue"
            />
          </div>
        ) : (
          <FontAwesomeIcon
            icon={['fal', 'arrow-right-from-bracket']}
            className="px-4 md:px-8 shrink-0 min-w-[24px] text-xl cursor-pointer hover:text-primary-blue"
            onClick={toggleExtend}
          />
        )
      ) : extend ? (
        <div
          className="flex items-center font-avenir-book text-black text-opacity-60 text-xs md:text-sm pl-4 md:pl-8 py-2 md:py-3 whitespace-nowrap"
          to="/login"
          role={AuthButton.role}
          onClick={onClick}
        >
          <span>Sign in</span>
          <FontAwesomeIcon
            icon={['fal', 'arrow-right-to-bracket']}
            className="shrink-0 mx-2 text-sm cursor-pointer hover:text-primary-blue"
          />
        </div>
      ) : (
        <FontAwesomeIcon
          icon={['fal', 'arrow-right-to-bracket']}
          className="px-4 md:px-8 shrink-0 min-w-[24px] text-xl cursor-pointer hover:text-primary-blue"
          onClick={toggleExtend}
        />
      )}
    </div>
  );
}

AuthButton.role = 'auth-button-component';
