import React from 'react';

import Loading from '../../../shared/components/Loading';
import ZendeskChatLink from '../../../shared/ZendeskChatLink';
import { normalizePhone, formatPhoneNumber } from '../../../../lib/utils/formatters';

export default function CompanyDetails({ entity, owner }) {
  if (!owner) return <Loading className='w-14 h-14' />;

  return (
    <div className="text-navy-blue px-4">
      <div className="flex flex-col xs:flex-row items-start xs:items-center pb-6 border-b">
        <p className="text-opaque-navy w-1/2">Owner Name</p>
        <p className="font-avenir-medium">
          {owner?.first_name} {owner?.last_name}
        </p>
      </div>
      <div className="flex flex-col xs:flex-row items-start xs:items-center py-6 border-b">
        <p className="text-opaque-navy w-1/2">Phone</p>
        <p className="font-avenir-medium">
          {formatPhoneNumber(normalizePhone(owner?.phone))}
        </p>
      </div>
      <div className="flex flex-col xs:flex-row items-start xs:items-center py-6 border-b">
        <p className="text-opaque-navy w-1/2">Email</p>
        <p className="font-avenir-medium">{owner?.email}</p>
      </div>
      {entity?.type === 'company' && (
        <>
          <div className="flex flex-col xs:flex-row items-start xs:items-center py-6 border-b">
            <p className="text-opaque-navy w-1/2">EIN</p>
            <p className="font-avenir-medium">{entity?.ein}</p>
          </div>
          <div className="flex flex-col xs:flex-row items-start xs:items-center py-6 border-b">
            <p className="text-opaque-navy w-1/2">Company Address</p>
            <div className="font-avenir-medium">
              <p>{entity?.address.address1}</p>
              <p>{entity?.address.address2}</p>
              <p>
                {entity?.address.city}, {entity?.address.state}{' '}
                {entity?.address.zip.slice(0, 5)}
              </p>
            </div>
          </div>
        </>
      )}
      <p className="text-opaque-navy mt-6">
        To update your contact information, please reach out to Credit Key
        Support:
        <a href="mailto: support@creditkey.com" className="underline mx-2">
          support@creditkey.com
        </a>
        or <ZendeskChatLink />
      </p>
    </div>
  );
}
