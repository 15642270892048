import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Box({ children }) {
  const { color, headerIcon, title, submit, subtext } = children;
  return (
    <div
      className={classNames(
        `h-full p-4 md:p-8 rounded-lg ${color} bg-opacity-10`,
      )}
    >
      <div className="flex items-center justify-between">
        <div
          className={classNames(
            `w-12 h-12 ${color} rounded-full text-white flex items-center justify-center`,
          )}
        >
          {headerIcon}
        </div>
      </div>
      <div className="font-avenir-black text-navy-blue text-lg md:text-xl mt-7 md:mt-14">
        {title}
      </div>
      <div className="font-avenir-book text-sm md:text-base mt-3 text-opaque-navy">
        {subtext}
      </div>
      <div
        className="flex items-center mt-2 md:mt-4 cursor-pointer"
        onClick={submit.handler}
      >
        <div className="font-avenir-book text-opaque-navy mr-2">
          {submit.label}
        </div>
        <FontAwesomeIcon
          icon={['far', 'chevron-right']}
          className="text-xs font-avenir-book text-opaque-navy pb-1"
        />
      </div>
    </div>
  );
}
