import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CTAButton from '../../shared/CTAButton';
import ConfirmationData from './layout/ConfirmationData';
import PaymentRecord from '../../../lib/records/payment';
import useToast from '../../../hooks/useToast';
import usePaymentMethods from '../../../hooks/usePaymentMethods';
import { asyncActions, loading } from '../../../store/actions/general';
import { getOrder } from '../../../store/actions/orders';
import {
  createLoanPayment,
  createPaymentPlanPayment,
} from '../../../store/actions/payments';

export default function ConfirmModal({
  order,
  toggleModal,
  modalState,
  nextScreen,
  previousScreen,
  resetScreen,
  resetModalState,
}) {
  const dispatch = useDispatch();
  const { makeToast } = useToast();
  const { payment_methods } = usePaymentMethods();
  const isLoading = useSelector((state) => state.general.get('loading'));
  const chosenMethod = payment_methods?.find(
    (pm) => pm.get('id') === modalState.payment_method_id,
  );
  const accountNum = ` x${chosenMethod?.payment_method_last_4}`;

  const onSubmit = async (values) => {
    dispatch(loading(true));
    dispatch(
      asyncActions(
        order.product_type === 'mambu_loan'
          ? createLoanPayment(new PaymentRecord(values))
          : createPaymentPlanPayment(new PaymentRecord(values)),
      ),
    )
      .then(() => dispatch(getOrder(order.id, 'include=payment_schedule')))
      .then(() => dispatch(loading(false)))
      .then(() => nextScreen())
      .catch(async (err) => {
        const error = await err;
        let msg;
        if (error.message === 'Unauthorized') {
          msg =
            'An error occurred. Please try again or contact our support team.';
        } else {
          msg = error.message;
        }
        makeToast(msg, 'negative-red', ['far', 'octagon-xmark']);
        dispatch(loading(false));
      });
  };

  return (
    <div role={ConfirmModal.role}>
      <section className="mt-4 mb-8">
        <div className="text-navy-blue flex flex-col items-center justify-center font-avenir-medium mb-8">
          <FontAwesomeIcon
            icon="check-circle"
            className="text-4xl xs:text-5xl mb-3"
          />
          <p className="text-base xs:text-xl text-center mx-4">
            Please confirm the details below before submitting your payment.
          </p>
        </div>
        <ConfirmationData
          chosenMethod={chosenMethod}
          accountNum={accountNum}
          order={order}
          modalState={modalState}
        />
        <div className="flex flex-col items-center justify-center mt-10">
          <CTAButton
            buttonClass="w-full bg-primary-blue text-white"
            disabled={isLoading}
            containerClass="my-4 w-full"
            buttonText="Submit Payment"
            onClick={() => onSubmit(modalState)}
            type="submit"
            isLoading={isLoading}
          />
          <div className="w-full flex items-center justify-around">
            <CTAButton
              buttonClass="w-full mr-1 bg-light-blue hover:text-white hover:bg-gray-500"
              disabled={isLoading}
              containerClass="w-full"
              buttonText="Back"
              onClick={previousScreen}
            />
            <CTAButton
              buttonClass="w-full ml-1 bg-light-blue hover:text-white hover:bg-gray-500"
              disabled={isLoading}
              containerClass="w-full"
              buttonText="Close"
              onClick={() => {
                toggleModal();
                resetScreen();
                resetModalState();
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

ConfirmModal.role = 'confirm-modal-component';
