import Network from "../../lib/utils/network";
import { receiveEntity } from "./entities";

const type = "merchants";
const api = Network();

const path = (resource) => {
  if (!resource) resource = "";
  return { resource: "api/" + type + "/" + resource };
};

export const getMerchant = (id) => {
  return (dispatch, getState) => {
    return api.get(path(id)).then((res) => dispatch(receiveEntity(res)));
  };
};
