import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useToast from './useToast';
import { asyncActions, setPossessedUserId } from '../store/actions/general';
import { getUser, getUserByEmail } from '../store/actions/users';

function usePossessedUser() {
  const dispatch = useDispatch();
  const { makeToast } = useToast();
  const possessedUserId = useSelector((state) =>
    state.general.get('possessed_user_id'),
  );
  const user = useSelector((state) => state.entities.get('user')).get(
    possessedUserId,
  );
  const [userIdentifier, setUserIdentifier] = useState(possessedUserId || '');
  const [loading, setLoading] = useState(false);

  const actions = {
    logInAsUser: (email) => setUserIdentifier(email.trim()),
    clearLoggedInAsUser: () => setUserIdentifier(''),
  };

  useEffect(() => {
    if (!userIdentifier) {
      dispatch(setPossessedUserId(''));
      return;
    }

    setLoading(true);
    const lookupFunc = userIdentifier.includes('@') ? getUserByEmail : getUser;
    dispatch(asyncActions(lookupFunc(encodeURIComponent(userIdentifier))))
      .then((res) => {
        if (!res) return;

        const lookupResult = Array.isArray(res?.payload?.data)
          ? res?.payload?.data?.at(0)
          : res?.payload?.data;

        if (!lookupResult) {
          makeToast(
            'User not found, double check your input and try again.',
            'negative-red',
            ['far', 'octagon-xmark'],
          );
        }

        dispatch(setPossessedUserId(lookupResult?.id));
      })
      .catch((err) => {
        if (err.status === 404) {
          makeToast(
            'User not found, double check your input and try again.',
            'negative-red',
            ['far', 'octagon-xmark'],
          );
        } else {
          makeToast(
            'User lookup failed, double check your input and try again.',
            'negative-red',
            ['far', 'octagon-xmark'],
          );
        }
      })
      .finally(() => setLoading(false));
  }, [userIdentifier]);

  return [user, actions, loading];
}

export default usePossessedUser;
