import React from 'react';
import { Disclosure } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Schedule } from '../components';

export default function PaymentScheduleDisclosure({ order }) {
  return (
    <div className="w-full my-4">
      <div className="mx-auto w-full rounded-2xl bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-6 py-4 text-left font-avenir-black text-navy-blue hover:bg-blue-200">
                <span>View Payment Schedule</span>
                <FontAwesomeIcon
                  icon={['far', 'angle-up']}
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-navy-blue font-avenir-black`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                <Schedule order={order} />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
