import React from 'react';
import classNames from 'classnames';

import { MaskedField } from './MaskedField';
import { formStyles } from '../../lib/constants/SiteVariables';

export default function Input(props) {
  return (
    <MaskedField
      {...props}
      name={props.id}
      className={classNames(
        'w-full form-control p-3 text-sm xxs:text-base text-opaque-navy bg-white bg-clip-padding border border-solid border-opaque-navy-border block rounded-lg transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-primary-blue focus:outline-one',
        formStyles.error(props.error),
      )}
      id={props.id}
    />
  );
}
