import React, { useEffect } from 'react';

import useModal from '../../../hooks/useModal';
import ModalWrapper from '../layout/ModalWrapper';
import { CompleteModal, ConfirmModal, PaymentModal } from '..';

export default function PaymentModalWrapper({
  order,
  isModalActive,
  toggleModal,
}) {
  const orderedScreens = [
    'create_payment',
    'confirm_payment',
    'complete_payment',
  ];

  const {
    modalState,
    setModalState,
    activeScreen,
    nextScreen,
    previousScreen,
    resetScreen,
    resetModalState
  } = useModal(orderedScreens);

  const modalProps = {
    order,
    isModalActive,
    modalState,
    setModalState,
    toggleModal,
    nextScreen,
    previousScreen,
    resetScreen,
    resetModalState
  };

  const getScreen = () => {
    if (!activeScreen) return null;

    switch (activeScreen) {
      case 'create_payment':
        return <PaymentModal {...modalProps} />;
      case 'confirm_payment':
        return <ConfirmModal {...modalProps} />;
      case 'complete_payment':
        return <CompleteModal {...modalProps} />;
    }
  };

  useEffect(() => {
    // Start at the first screen in the list
    resetScreen();
  }, [isModalActive]);

  if (!isModalActive) return null;

  return (
    <ModalWrapper
      isModalActive={isModalActive}
      toggleModal={toggleModal}
      header={
        <div className="font-avenir-black px-6 py-2 text-xl text-navy-blue">
          Make a payment
          <p className="font-avenir-book text-sm text-opaque-navy" role="complementary">
            You'll never be charged early payoff penalties
          </p>
        </div>
      }
    >
      {getScreen()}
    </ModalWrapper>
  );
}
