import React, { useState } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { Step2Form } from '../../forms';
import { asyncActions, loading } from '../../../store/actions/general';
import { emailRegex } from '../../../lib/constants/SiteVariables';
import { createCreditKeyAnywhereRequest } from '../../../store/actions/credit_key_anywhere_requests';

function mapFormikErrors(errors, setFieldError, scope = '') {
  Object.keys(errors).forEach((key) => {
    setFieldError(scope ? `${scope}.${key}` : key, errors[key].join(', '));
  });
}

export default function CKAStep2({ modalState, nextScreen }) {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState();

  const onSubmit = async (values, { setFieldError }) => {
    const cka_request = modalState.cka_request.merge(values);
    const data = new FormData();

    Object.keys(cka_request.toJS()).forEach((i) => {
      data.append(i, values[i]);
    });

    dispatch(loading(true));
    dispatch(asyncActions(createCreditKeyAnywhereRequest(data)))
      .then(() => nextScreen())
      .catch((err) => {
        if (err?.source === 'validation') {
          mapFormikErrors(err.message, setFieldError);
        }
      })
      .finally(() => dispatch(loading(false)));
  };

  return (
    <div className="min-w-20 my-6" role={CKAStep2.role}>
      <section className="flex flex-col justify-center items-center overflow-scroll">
        <div className="mx-6 text-center">
          <p className="is-size-5-desktop is-size-6-mobile has-text-weight-semibold">
            Just a few more details...
          </p>
        </div>
        <Formik
          initialValues={modalState.cka_request.toJS()}
          validate={async (values) => {
            const errors = {};

            if (!values.vendor_url) errors.vendor_url = '* Required';
            if (!values.description) errors.description = '* Required';

            if (values.vendor_contact_email) {
              if (!emailRegex.test(values.vendor_contact_email)) {
                errors.vendor_contact_email =
                  '* Please provide a valid email address';
              }
            }

            if (!values.vendor_contact_phone) {
              errors.vendor_contact_phone = '* Required';
            } else if (
              values.vendor_contact_phone.replace(/\D/g, '').length !== 11
            ) {
              errors.vendor_contact_phone =
                '* Please provide a valid phone number';
            }

            if (!values.invoice_document) {
              errors.invoice_document =
                '* Required: An invoice must be attached';
            } else if (
              !['jpeg', 'jpg', 'pdf', 'doc', 'docx', 'png'].includes(
                values.invoice_document.name.split('.').pop().toLowerCase(),
              )
            ) {
              errors.invoice_document =
                '* Invoice must have JPEG, JPG, PNG, PDF, DOC, or DOCX format';
            }

            return errors;
          }}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Step2Form
              {...props}
              fileName={fileName}
              setFileName={setFileName}
            />
          )}
        </Formik>
      </section>
    </div>
  );
}

CKAStep2.role = 'cka-step-2-component';
