import React, { useEffect } from 'react';

import CreditKeyAnywhereRequestRecord from '../../../lib/records/credit_key_anywhere_request';
import useModal from '../../../hooks/useModal';
import { CKAStep1, CKAStep2, CKASuccess, ModalWrapper } from '..';
import ModalFooter from '../layout/ModalFooter';

export default function CKAModalWrapper({ isModalActive, toggleModal }) {
  const orderedScreens = ['cka_step1', 'cka_step2', 'cka_success'];

  const defaultFormState = {
    cka_request: new CreditKeyAnywhereRequestRecord(),
  };

  const {
    activeScreen,
    nextScreen,
    resetScreen,
    modalState,
    setModalState,
    resetModalState,
  } = useModal(orderedScreens, defaultFormState);

  const modalProps = {
    resetModalState,
    nextScreen,
    modalState,
    setModalState,
    resetScreen,
    toggleModal
  };

  const getScreen = () => {
    if (!activeScreen) return null;
    switch (activeScreen) {
      case 'cka_step1':
        return <CKAStep1 {...modalProps} />;
      case 'cka_step2':
        return <CKAStep2 {...modalProps} />;
      case 'cka_success':
        return <CKASuccess {...modalProps} />;
    }
  };

  useEffect(() => {
    // Start at the first screen in the list
    resetScreen();
  }, [isModalActive]);

  return (
    <div role={CKAModalWrapper.role}>
      <ModalWrapper toggleModal={toggleModal} isModalActive={isModalActive}>
        {getScreen()}
        <ModalFooter className="text-sm mt-2" />
      </ModalWrapper>
    </div>
  );
}

CKAModalWrapper.role = 'cka-modal-wrapper-component';
