import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncActions } from '../store/actions/general';
import { useAuth } from '../hooks/useAuth';
import { getUser } from '../store/actions/users';
import { pdGetUser } from '../lib/constants/PersistentData';

function useLoggedInUser() {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) =>
    state.entities.get('user'),
  ).first();

  useEffect(() => {
    if (auth.isAuthenticated() && !loggedInUser && !loading) {
      const { id } = pdGetUser() || auth.user;
      if (id === undefined) return;

      setLoading(true);
      dispatch(asyncActions(getUser(id))).finally(() => setLoading(false));
    }
  }, [auth, loggedInUser]);

  useEffect(() => {
    if (loggedInUser) {
      auth.setUser(loggedInUser);
    }
  }, [loggedInUser]);

  return [auth?.user];
}

export default useLoggedInUser;
