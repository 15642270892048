import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageHeader from './layout/PageHeader';
import ZendeskChatLink from './ZendeskChatLink';
import { supportPhone } from '../../lib/constants/SiteVariables';

export default function Support() {
  return (
    <div role={Support.role} className="min-h-screen px-4 md:px-12 xxl:px-32">
      <PageHeader
        title={
          <Link to="/">
            <div className="flex justify-between items-center">
              <FontAwesomeIcon
                className="text-2xl mb-1 mr-8"
                icon={['far', 'chevron-left']}
              />
              <span className="font-avenir-black text-2xl sm:text-3xl mx-5 sm:mx-0">
                We're sorry, something went wrong.
              </span>
            </div>
          </Link>
        }
      />
      <p className="px-4 my-14 font-avenir-medium text-lg text-primary-blue">Questions? Issues? Please contact Credit Key Support:</p>
      <div className="flex flex-col">
        <div className="px-4">
          <div className="flex items-center pb-6 border-b">
            <p className="text-opaque-navy w-1/2">Phone</p>
            <p className="font-avenir-medium">{supportPhone}</p>
          </div>
          <div className="flex items-center py-6 border-b">
            <p className="text-opaque-navy w-1/2">Email</p>
            <a href="mailto: support@creditkey.com" className='font-avenir-medium'>support@creditkey.com</a>
          </div>
          <div className="flex items-center py-6 border-b">
            <p className="text-opaque-navy w-1/2">Live Chat</p>
            <span className='font-avenir-medium'>
              <ZendeskChatLink />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

Support.role = 'support-component';
