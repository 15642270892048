import * as ActionTypes from "../ActionTypes";

export function clearEntities(type) {
  return {
    type: ActionTypes.CLEAR_ENTITIES,
    payload: type,
  };
}

export function clearEntity(data, type) {
  return {
    type: ActionTypes.CLEAR_ENTITY,
    payload: data,
  };
}

export function receiveEntities(data, type) {
  return {
    type: ActionTypes.RECEIVE_ENTITIES,
    payload: data,
  };
}

export function receiveEntity(data, type) {
  if (type) data.type = type;

  return {
    type: ActionTypes.RECEIVE_ENTITY,
    payload: data,
  };
}

export function updateEntity(resource, type) {
  return {
    data: {
      id: resource.id,
      type: type,
      attributes: resource.toJS(),
    },
  };
}

export function resetEntities() {
  return {
    type: ActionTypes.RESET_ENTITIES,
  }
};
