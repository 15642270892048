import React from 'react';

import Loading from '../../shared/components/Loading';
import PageHeader from '../../shared/layout/PageHeader';
import useEntity from '../../../hooks/useEntity';
import useCurrentUser from '../../../hooks/useCurrentUser';

export default function PageLayout({ children }) {
  const [user] = useCurrentUser();
  const [entity] = useEntity();

  if (!user || !entity) return <Loading />;

  return (
    <div className="min-h-screen px-4 md:px-12 xxl:px-32" role={PageLayout.role}>
      <PageHeader title="My Orders" />
      {children}
    </div>
  );
}

PageLayout.role = 'order-page-layout-component';