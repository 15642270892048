import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useToast from './useToast';
import { clearErrors, reportError } from '../store/actions/general';
import { pdIsExpired } from '../lib/constants/PersistentData';
import { useAuth } from './useAuth';

function useErrorHandling() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const asyncErrors = useSelector((state) => state.general.get('errors'));
  const auth = useAuth();
  const { makeToast } = useToast();

  useEffect(() => {
    if (asyncErrors?.size > 0) {
      if (asyncErrors.find((i) => i.status === 401)) {
        const expired = pdIsExpired();

        if (expired) {
          makeToast(
            'You have been logged out due to inactivity',
            'negative-red',
            ['far', 'octagon-xmark'],
          );
          auth.signout();
        } else {
          makeToast(
            'Whoops, something went wrong! Please contact Credit Key Support for assistance: (844) 334-3636',
            'negative-red',
            ['far', 'octagon-xmark'],
          );
        }
      } else if (
        asyncErrors.find((i) => ['runtime', 'unknown'].includes(i.source))
      ) {
        makeToast(
          'Whoops, something went wrong! Please contact Credit Key Support for assistance: (844) 334-3636',
          'negative-red',
          ['far', 'octagon-xmark'],
        );
        dispatch(reportError(asyncErrors.toJS()));
        // TODO: show something or push them to a support screen?
        navigate('/support');
      }
    }

    dispatch(clearErrors());
  }, [asyncErrors]);
}

export default useErrorHandling;
