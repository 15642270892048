export default class Heap {
  constructor(component = '') {
    if (component) this.component = component?.toLowerCase();
  }

  load(platform) {
    if (window.heap.loaded) return true;

    if (platform === 'production') {
      window.heap.load('378366210', {
        secureCookie: true,
      });
    } else {
      window.heap.load('951627502', {
        secureCookie: true,
      });
    }
  }

  identify(id) {
    // don't track in development
    if (process.env.NODE_ENV !== 'production') return false;

    window.heap.identify(id);
  }

  track({ merchant = {}, session = {}, user = {}, payload = {} }) {
    // don't track in development
    if (process.env.NODE_ENV !== 'production') return false;

    const default_payload = {
      merchant_public_key: merchant.public_key,
      merchant_name: merchant.name,
      session_type: session.session_type,
      email: user.email,
    };

    const final_payload = { ...default_payload, ...payload };

    window.heap.track('Borrower Portal', final_payload);
  }
}
