import React from 'react';

import OrderTable from './table/OrderTable';
import PageLayout from './PageLayout';
import useOrders from '../../../hooks/useOrders';

export default function Orders() {
  const { orders, ordersLoading, pageCount, fetchData } = useOrders();

  return (
    <div role={Orders.role}>
      <PageLayout>
        <OrderTable
          orders={orders}
          ordersLoading={ordersLoading}
          pageCount={pageCount}
          fetchData={fetchData}
        />
      </PageLayout>
    </div>
  );
}

Orders.role = 'orders-index';
