import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CTAButton from '../shared/CTAButton';
import Error from '../shared/Error';
import FullStory from '../../lib/utils/full_story';
import Heap from '../../lib/utils/heap';
import ModalFooter from '../modals/layout/ModalFooter';
import TopNav from '../layout/TopNav';
import UserRecord from '../../lib/records/user';
import UserTokenVerification from '../shared/UserTokenVerification';
import { asyncActions, loading } from '../../store/actions/general';
import { useAuth } from '../../hooks/useAuth';

const heap = new Heap();
const fs = new FullStory();

export default function LoginTokenForm() {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.general.get('loading'));
  const { verify, verifyToken, signin } = useAuth();
  const [error, setError] = useState(false);
  const { from } = location.state || { from: { pathname: '/' } };

  const checkToken = async (val) => {
    const res = await verifyToken(params.type, params.contact, val)();
    return res;
  };

  const resendCode = () => {
    dispatch(loading(true));

    return dispatch(asyncActions(verify('phone', params.contact, true)))
      .then(() => dispatch(loading(false)))
      .catch((err) => dispatch(loading(false)));
  };

  const onSubmit = async (givenToken) => {
    setError(false);
    dispatch(loading(true));
    const verified = await checkToken(givenToken);

    if (verified.locked) {
      navigate('/user_lockout');
      dispatch(loading(false));
    } else if (verified.authorized) {
      save(givenToken, verified);
    } else {
      setError(true);
      dispatch(loading(false));
    }
  };

  const save = (givenToken, verified) => {
    const verifiedUser = new UserRecord(verified.user);

    heap.identify(verifiedUser.email);

    if (typeof fs !== 'undefined') {
      fs.identify(verifiedUser.email, { displayName: verifiedUser.name });
      fs.event({ user: verifiedUser });
    }

    dispatch(loading(true));
    dispatch(asyncActions(signin(verifiedUser, verified.token)))
      .then(() => navigate(from, { replace: true }))
      .finally(() => dispatch(loading(false)));
  };

  if (!params || (!params.type && !params.contact)) {
    return <Error />;
  }

  return (
    <>
      <TopNav />
      <div
        className="min-h-full flex flex-col justify-center xxs:py-0 lg:py-4 sm:px-6 lg:px-8"
        role={LoginTokenForm.role}
      >
        <div className="mt-8 xxs:mx-auto xxs:w-full xxs:max-w-lg">
          <div className="bg-white my-4 xxs:py-10 sm:py-16 lg:py-20 px-10 xxs:shadow xxs:rounded-lg sm:px-14 text-center">
            <h2 className="my-2 text-3xl font-avenir-black text-navy-blue">
              We just texted you
            </h2>
            <p className="my-2 text-base font-avenir-book text-opaque-navy">
              Please enter the verification code we sent to
              <span className="ml-1 text-navy-blue font-avenir-medium block">
                {params.contact}.
              </span>
            </p>
            <UserTokenVerification onSubmit={onSubmit} />
            {!isLoading && error && (
              <div className="bg-negative-red bg-opacity-50 border-l-4 border-negative-red p-4 rounded-md">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <FontAwesomeIcon
                      icon={['far', 'exclamation-triangle']}
                      className="pt-1 text-lg text-negative-red"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-base font-avenir-medium text-negative-red">
                      Invalid Token
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-between items-center w-full my-6">
              <p className="text-sm xxs:text-base text-navy-blue">
                Didn't receive the code?
              </p>
              <button className="text-xs xxs:text-sm font-avenir-medium rounded-full px-2 py-1 border border-primary-blue border-opacity-40 text-primary-blue hover:bg-primary-blue hover:border-opacity-5 hover:bg-opacity-20">
                <Link to="/login" className="ml-1">
                  Change Number
                </Link>
              </button>
            </div>
            <p
              className="text-left text-xs text-navy-blue underline cursor-pointer hover:text-primary-blue mb-6"
              onClick={resendCode}
            >
              Send to my account email instead
            </p>
            <CTAButton
              ariaLabel="login-button"
              buttonClass="w-full bg-primary-blue text-white rounded-full py-3 px-6 cursor-pointer text-sm md:text-base inline-block hover:bg-navy-blue flex justify-center items-center"
              buttonText="Continue"
              icon={['far', 'lock']}
              disabled={isLoading}
              isLoading={isLoading}
            />
            <ModalFooter className="text-opaque-navy text-xs mt-8" />
          </div>
        </div>
      </div>
    </>
  );
}

LoginTokenForm.role = 'login-token-component';
