import React from 'react';

import Loading from '../../shared/components/Loading';
import PageHeader from '../../shared/layout/PageHeader';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useEntity from '../../../hooks/useEntity';
import { PaymentMethodModalWrapper } from '../../modals';

export default function PageLayout({ children, ...headerProps }) {
  const [user] = useCurrentUser();
  const [entity] = useEntity();

  const props = { ...headerProps };

  if (!user || !entity) return <Loading />;

  return (
    <div className="min-h-screen px-4 md:px-12 xxl:px-32" role={PageLayout.role}>
      <PageHeader title="Payment Methods" />
      <PaymentMethodModalWrapper {...props} />
      {children}
    </div>
  );
}

PageLayout.role = 'payment-methods-page-layout-component';
