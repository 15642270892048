import React from 'react';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../../../shared/components/Loading';
import { ScheduleTimeline, NoScheduleTimeline } from './';
import { formatCurrency } from '../../../../lib/utils/formatters';
import { fromISO } from '../../../../lib/utils/datetime_formatters';

export default function Schedule({ order }) {
  if (!order) return <Loading />;

  return (
    <div className="p-6" role={Schedule.role}>
      <ol className="relative border-l border-opaque-navy-border">
        <li className="mb-10 ml-8">
          <div className="flex justify-between items-start">
            <div>
              <span className="flex absolute -left-3.5 justify-center items-center w-7 h-7 bg-positive-green rounded-full ring-8 ring-white">
                <div className="text-white font-avenir-black text-sm mt-0.5">
                  <FontAwesomeIcon icon="check" />
                </div>
              </span>
              <h3 className="flex items-center mb-1 text-lg font-semibold text-navy-blue dark:text-white">
                Order Processed
              </h3>
              <time className="block mb-2 text-sm font-normal leading-none text-opaque-navy">
                {fromISO(order.created_at).toLocaleString(DateTime.DATE_MED)}
              </time>
            </div>
            <div className="text-base">
              <span className="font-avenir-black ml-1 text-navy-blue">
                {formatCurrency(order.product.amount)}
              </span>
            </div>
          </div>
        </li>
        {parseFloat(order.amount_refunded) > 0 && (
          <li className="mb-16 ml-8">
            <div className="flex justify-between items-start">
              <div className="flex justify-center items-center">
                <span className="flex absolute -left-3.5 justify-center items-center w-7 h-7 bg-gray-500 rounded-full ring-8 ring-white">
                  <div className="text-white font-avenir-black text-sm mt-1">
                    <FontAwesomeIcon icon="arrow-rotate-left" />
                  </div>
                </span>
                <h3 className="flex items-center text-base">
                  Refund(s) Processed
                </h3>
              </div>
              <div className="text-lg">
                <span className="font-avenir-black ml-1">
                  {formatCurrency(order.amount_refunded)}
                </span>
              </div>
            </div>
          </li>
        )}
        {order.product.payment_schedule.size > 0 ? (
          <ScheduleTimeline order={order} />
        ) : (
          <NoScheduleTimeline />
        )}
      </ol>
    </div>
  );
}

Schedule.role = 'schedule-component';
