import Network from '../../lib/utils/network';
import { receiveEntity } from './entities';

const api = Network();

const path = (resource) => {
  if (!resource) resource = '';
  return {
    resource: 'api/pi4/accounts/' + resource };
};

export const getAccount = (id) => {
  return (dispatch, getState) => {
    return api.get(path(id)).then((res) => dispatch(receiveEntity(res)));
  };
};
