import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Notification({ icon, headline, message }) {
  return (
    <div className="relative bg-primary-blue bg-opacity-10 rounded-md mb-3">
      <div className="mx-auto max-w-7xl p-4 px-6 text-center md:text-left">
        <div className="flex items-center gap-4">
          <span className="rounded-lg bg-primary-blue p-2 hidden md:flex">
            <FontAwesomeIcon icon={icon} className="h-6 w-6 text-white" />
          </span>
          <div className="font-avenir-medium text-navy-blue tracking-wide">
            <p className="uppercase font-avenir-black mr-1 text-primary-blue tracking-wider text-[1.075rem]">
              {headline}
            </p>
            {message}
          </div>
        </div>
      </div>
    </div>
  );
}
