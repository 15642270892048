import { Record } from 'immutable';
import { server } from '../constants/SiteVariables';

export default class MerchantRecord extends Record({
  id: null,
  platform: '',
  name: '',
  supports_standalone_csr: null,
  support_email: '',
  active_promo: {},
  promos: [],
  public_key: '',
  url: '',
  restricted_industry: null,
  slug: '',
  default_checkout_method: null
}) {
  get learn_more_link() {
    let learnMoreSlug;

    (this.slug === 'not-used' || this.slug === '') ? learnMoreSlug = 'learn-more' : learnMoreSlug = this.slug;

    return server(false) + '/' + learnMoreSlug;
  }
}
