import React from 'react';
import PageHeader from '../../shared/layout/PageHeader';

export default function PageLayout({ children }) {
  return (
    <div className="min-h-screen px-4 md:px-12 xxl:px-32" role={PageLayout.role}>
      <PageHeader title="My Profile" />
      {children}
    </div>
  );
}

PageLayout.role = 'profile-page-layout-component';
