import { useSelector } from "react-redux";

function usePromo() {
  const defaultMerchant = useSelector(state => state.entities.get('merchant').get(process.env.REACT_APP_VIRTUAL_CARD_MERCHANT_ID));

  const actions = {
    getPromoFromMerchant: (id, merchant) => {
      if (!merchant) merchant = defaultMerchant;
      return (merchant?.promos || []).find(p => p.get('id') === id);
    }
  }

  return actions;
}

export default usePromo;
