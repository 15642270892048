function BaseGridItem({ children, ...config }) {
  return <>{children}</>;
}

function BaseGrid({ children }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8">
      {children}
    </div>
  );
}

export { BaseGrid, BaseGridItem };
