import React from 'react';
import { Formik } from 'formik';
import { DateTime } from 'luxon';

import { Step1Form } from '../../forms/';

export default function CKAStep1({ modalState, setModalState, nextScreen }) {
  const dateFrom = DateTime.now().minus({ days: 45 }).toFormat('MMMM d, yyyy');

  const onSubmit = async (values) => {
    setModalState({
      cka_request: modalState.cka_request.merge(values),
    });
    nextScreen();
  };

  return (
    <div className="min-w-20" role={CKAStep1.role}>
      <section className="flex flex-col justify-center items-center overflow-scroll">
        <div className="mx-6 text-center">
          <p className="text-lg my-2 font-avenir-black">Submit a new invoice</p>
          <p className="my-2">
            Pay for any qualified business invoice with a new vendor in just a
            few steps.
          </p>
        </div>
        <Formik
          initialValues={modalState.cka_request.toJS()}
          validate={async (values) => {
            const errors = {};
            if (!values.confirmed_invoice_date)
              errors.confirmed_invoice_date = '* Required';
            if (!values.confirmed_only_business_purchases)
              errors.confirmed_only_business_purchases = '* Required';

            if (!values.vendor_name) errors.vendor_name = '* Required';
            if (!values.invoice_amount) errors.invoice_amount = '* Required';
            if (parseFloat(values.invoice_amount) === 0.0)
              errors.invoice_amount = '* Amount must be greater than zero (0)';

            return errors;
          }}
          onSubmit={onSubmit}
        >
          {(props) => <Step1Form {...props} dateFrom={dateFrom} />}
        </Formik>
      </section>
    </div>
  );
}

CKAStep1.role = 'cka-step-1-component';
