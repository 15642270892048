import React from 'react';
import { useSelector } from 'react-redux';
import { VCModalWrapper } from '../../../modals';
import useModal from '../../../../hooks/useModal';
import usePromo from '../../../../hooks/usePromo';
import useCompany from '../../../../hooks/useCompany';
import CTAButton from '../../../shared/CTAButton';
import { formatCurrency } from '../../../../lib/utils/formatters';

export default function Promo() {
  const { isModalActive, toggleModal } = useModal();
  const promos = usePromo();
  const [company] = useCompany();
  const promoId = useSelector(state => state.general.get('promo_id'));
  const promo = promos.getPromoFromMerchant(company?.promo_id || promoId);
  const activeBanner = company?.cta_banners.find((b) => b.get('enabled'));

  if (!promo || !activeBanner) return <></>;
  if (activeBanner && activeBanner.get('name') !== 'virtual_card') return (
    <div className="rounded-lg border border-grey bg-yellow-100 mb-4 p-4 text-center">
      <p>
        <strong>Limited Time Only!</strong> Pay zero interest for {promo.get('max_net_duration')} days on all Credit Key orders
      </p>
    </div>
  );

  return (
    <div className="rounded-lg border border-grey bg-yellow-100 mb-4 p-4 text-center">
      <p>
        <strong>Limited Time Offer!</strong> Pay zero interest for {promo.get('max_net_duration')} days on virtual card orders
        {promo.get('min_order_amount') > 0 && <span> over {formatCurrency(promo.get('min_order_amount'))}</span>}
      </p>
      <CTAButton
        onClick={toggleModal}
        buttonClass='px-32 bg-primary-blue text-white'
        buttonText="Unlock Offer"
        containerClass="flex justify-center pt-6"
      />

      <VCModalWrapper isModalActive={isModalActive} toggleModal={toggleModal} />
    </div>
  )
}
