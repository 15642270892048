import React from 'react';

// Layout
import PageHeader from './shared/layout/PageHeader';

// Pages
import MainDashboard from './pages/home/MainDashboard';

export default function Index() {
  return (
    <div className='px-4 md:px-12 xxl:px-32'>
      <PageHeader title='Dashboard'/>
      <MainDashboard />
    </div>
  );
}
