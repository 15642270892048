import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useCompany from './useCompany';
import useConsumerAccount from './useConsumerAccount';
import useEntity from './useEntity';
import { asyncActions } from '../store/actions/general';
import { clearOrders, getOrders } from '../store/actions/orders';

function useOrders(pageIndex = 0) {
  const dispatch = useDispatch();
  const [account] = useConsumerAccount();
  const [company] = useCompany();
  const [entity] = useEntity();
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const orders = useSelector((state) => state.entities.get('order')).filter(
    (i) => [company?.id, account?.id].includes(i.get('entity_id')),
  );

  const activeOrders = orders.filter((i) =>
    ['active', 'funded', 'created'].includes(i.product.status),
  );

  const fetchData = ({ pageIndex }) => {
    setOrdersLoading(true);
    dispatch(clearOrders());
    !ordersLoading &&
      dispatch(
        asyncActions(
          getOrders(
            entity,
            `filter[${entity.type}]=${entity.id}&page=${
              pageIndex + 1
            }&per_page=15&include=virtual_card_details,payment_schedule`,
          ),
        ),
      )
        .then((res) => setPageCount(res.payload.metadata.total_pages))
        .finally(() => setOrdersLoading(false))
        .catch((err) =>
          console.log('An error occurred when trying to GET orders.', err),
        );
  };

  useEffect(() => {
    if (entity && !ordersLoading) {
      fetchData({ pageIndex });
    }
  }, [entity]);

  return { orders, ordersLoading, activeOrders, pageCount, fetchData };
}

export default useOrders;
