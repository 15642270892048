import Network from '../../lib/utils/network';
import { pdSaveToken } from '../../lib/constants/PersistentData';
import {
  receiveEntity,
  receiveEntities,
  updateEntity,
  clearEntity,
} from './entities';

const api = Network();

const path = (resource, params = '') => {
  if (!resource) resource = '';

  return {
    resource: 'api/users/' + resource,
    params: params ? `?${params}` : '',
  };
};

export const createUser = (user) => {
  return (dispatch, getState) => {
    return api.post(path(), user).then((res) => dispatch(receiveEntity(res)));
  };
};

export const getUser = (id, include = '', raw = false) => {
  return (dispatch, getState) => {
    return api
      .get(path(id, include))
      .then((res) => (raw ? res : dispatch(receiveEntity(res))));
  };
};

export const getUserByEmail = (email, include = '', raw = false) => {
  return (dispatch, getState) => {
    return api
      .get(path(null, `filter[email]=${email}&${include}`))
      .then((res) => (raw ? res : dispatch(receiveEntities(res))));
  };
};

export const onChange = (e, user) => {
  return (dispatch, getState) => {
    return dispatch(
      receiveEntity(
        updateEntity(user.set(e.target.name, e.target.value), 'user'),
      ),
    );
  };
};

export const setupNewUser = (user) => {
  return (dispatch, getState) => {
    return dispatch(receiveEntity(updateEntity(user, 'user')));
  };
};

export const updateUser = (user) => {
  return (dispatch, getState) => {
    return api
      .edit(path(user.id), user)
      .then((res) => dispatch(receiveEntity(res)));
  };
};

export const saveToken = (token) => {
  return (dispatch, getState) => {
    pdSaveToken(token);
  };
};

export const validateBirthDate = (birthdate) => {
  return (dispatch, getState) => {
    return api.post(path('0/validate_birthdate'), { birthdate: birthdate });
  };
};

export const validatePhone = (phone_number) => {
  return (dispatch, getState) => {
    return api.post(path('0/validate_phone_number'), {
      phone_number: phone_number,
    });
  };
};

// send request to API to verify contact type (email/phone) and send login token
export const verify = (type, contact, send_to_email=false) => {
  return (dispatch, getState) => {
    return api.post(path('borrower/verify'), {
      type: type,
      contact: contact,
      send_to_email: send_to_email
    });
  };
};

export const verifyToken = (type, contact, token) => {
  return (dispatch, getState) => {
    return api.post(path('borrower/verified'), {
      type: type,
      contact: contact,
      verification_code: token,
    });
  };
};

export const getCompanyUsers = (companyId) => {
  return (dispatch, getState) => {
    return api
      .get(path(null, `filter[company_id]=${companyId}`))
      .then((res) => dispatch(receiveEntities(res)));
  };
};

export const createCompanyUser = (companyId, user) => {
  return (dispatch, getState) => {
    return api
      .post(path(), { user: user, company_id: companyId })
      .then((res) => dispatch(receiveEntities(res)));
  };
};

export const deleteCompanyUser = (user) => {
  return (dispatch, getState) => {
    return api.delete(path(user.id)).then((res) =>
      dispatch(
        clearEntity({
          data: { id: user.id, type: 'user', attributes: user },
        }),
      ),
    );
  };
};
