import Network from '../../lib/utils/network';
import { clearEntity, receiveEntity, receiveEntities } from './entities';

const api = Network();

const path = (resource, params = '', namespace = '') => {
  if (!resource) resource = '';

  return {
    resource: 'payment_methods/' + resource,
    namespace: `api/${namespace}`,
    params: params ? `?${params}` : '',
  };
};

export const generateBankAgreement = (user) => {
  return (dispatch, getState) => {
    return api.post(path('0/generate_bank_agreement'), { user_id: user.id });
  };
};

export const getPaymentMethods = (filters = '') => {
  return (dispatch, getState) => {
    return api
      .get(path(null, filters))
      .then((res) => dispatch(receiveEntities(res)));
  };
};

export const createPaymentMethodBank = (payment_method, type) => {
  return (dispatch, getState) => {
    return api
      .post(path('bank_account'), { payment_method })
      .then((res) => dispatch(receiveEntity(res)));
  };
};

export const createPaymentMethodPi4Card = (payment_method, type) => {
  return (dispatch, getState) => {
    return api
      .post(path(null, '', 'pi4'), { payment_method })
      .then((res) => dispatch(receiveEntity(res)));
  };
};

export const updatePaymentMethod = (payment_method) => {
  const namespace = payment_method.payment_type === 'card' ? 'pi4' : '';

  return (dispatch, getState) => {
    return api
      .edit(path(payment_method.id, '', namespace), { payment_method })
      .then((res) => dispatch(receiveEntity(res)));
  };
};

export const deletePaymentMethod = (payment_method) => {
  const namespace = payment_method.payment_type === 'card' ? 'pi4' : '';

  return (dispatch, getState) => {
    return api
      .delete(path(payment_method.id, '', namespace), { payment_method })
      .then((res) => dispatch(clearEntity(res)));
  };
};
