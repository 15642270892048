import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Loading({ className, containerClass }) {
  return (
    <div className={`flex justify-center ${containerClass}`}>
      <FontAwesomeIcon className={`text-primary-blue h-20 w-20 ${className}`} icon={'fa-spinner'} spin />
    </div>
  );
}
