import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { NavLink, Link } from 'react-router-dom';

import AuthButton from './AuthButton';
import logo from '../../lib/images/logo.svg';

export default function MobileNav({ primaryRoutes }) {
  return (
    <Popover className="relative bg-white inline-block md:hidden">
      <div>
        <div className="flex justify-start items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10 mb-8 px-6">
          <div className="-mr-2 -my-2">
            <Popover.Button className="bg-white text-navy-blue rounded-md p-2 inline-flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-blue">
              <span className="sr-only">Open menu</span>
              <FontAwesomeIcon icon={['far', 'bars']} className="text-lg" />
            </Popover.Button>
          </div>
          <div className="flex justify-start lg:w-0 lg:flex-1 mx-5">
            <Link to="/">
              <img src={logo} alt="Credit Key Logo" />
            </Link>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-left md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-light-blue divide-y-2 divide-gray-100">
            <div className="pt-4 pb-6 px-5">
              <div className="flex justify-start items-center">
                <div className="-mr-2 -my-2">
                  <Popover.Button className="text-navy-blue rounded-md p-2 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-blue">
                    <span className="sr-only">Close menu</span>
                    <FontAwesomeIcon
                      icon={['far', 'xmark']}
                      className="text-xl"
                    />
                  </Popover.Button>
                </div>
                <div className="flex justify-start lg:w-0 lg:flex-1 mx-5">
                  <Link to="/">
                    <img src={logo} alt="Credit Key Logo" />
                  </Link>
                </div>
              </div>
              <div className="mt-10 px-2">
                <nav className="grid gap-y-8">
                  {primaryRoutes.map((item) => {
                    return (
                      <div key={item.name}>
                        {item.name === 'Help Center' ? (
                          <a
                            href={item.url}
                            target={item.target}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <FontAwesomeIcon
                              icon={item.icon}
                              className="text-primary-blue"
                            />
                            <div className="ml-3 text-base text-navy-blue">
                              {item.name}
                            </div>
                          </a>
                        ) : (
                          <Popover.Button
                            as={NavLink}
                            to={item.url}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <FontAwesomeIcon
                              icon={item.icon}
                              className="text-primary-blue"
                            />
                            <span className="ml-3 text-base text-navy-blue">
                              {item.name}
                            </span>
                          </Popover.Button>
                        )}
                      </div>
                    );
                  })}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <AuthButton
                  extend={true}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-white bg-primary-blue hover:bg-navy-blue"
                />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
