import { useDispatch, useSelector } from "react-redux";
import { getMerchant } from "../store/actions/merchants";

function useVirtualCard() {
  const dispatch = useDispatch();
  const merchant = useSelector(state => state.entities.get('merchant').get(process.env.REACT_APP_VIRTUAL_CARD_MERCHANT_ID));

  const actions = {
    loadVirtualCardMerchant: (id = process.env.REACT_APP_VIRTUAL_CARD_MERCHANT_ID) => {
      if (merchant) return false;
      dispatch(getMerchant(id))
        .catch(err => console.log(err));
    },
  };

  return [merchant, actions];
}

export default useVirtualCard;
