import { DateTime } from 'luxon';

export const isLocalItemExpired = (item, override) => {
  if (!item || !item.timestamp) return true;

  const now = DateTime.now().toMillis() / 1000;
  const timestamp = item.timestamp;

  return now > timestamp;
}

export const getLocalItem = label => {
  const item = localStorage.getItem(label);
  return JSON.parse(item);
}

export const setLocalItem = (label, data) => {
  localStorage.setItem(label, JSON.stringify(data));
}

export const removeLocalItem = label => {
  localStorage.removeItem(label);
}
