import useCompany from './useCompany';
import useConsumerAccount from './useConsumerAccount';
import useCurrentUser from './useCurrentUser';

function useEntity() {
  const [user] = useCurrentUser();
  const [company, companyActions] = useCompany();
  const [account, accountActions] = useConsumerAccount();

  const actions = {
    loadForUser: (user) => {
      if (user.hasAccount) {
        accountActions.loadForUser(user);
      }
      if (user.hasCompany) {
        companyActions.loadForUser(user);
      }
    },
  };

  const converted = account && company;
  const convertedEntity = converted && ['declined', 'new'].includes(company.underwriting_status) ? account : company;
  const userEntity = !converted && user?.hasAccount ? account : company;

  // TODO: maybe return companyActions or accountActions based on the entity type?
  return [converted ? convertedEntity : userEntity, actions];
}

export default useEntity;
