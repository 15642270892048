import React, { useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Pagination({
  pageCount,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageIndex,
  gotoPage,
}) {
  const [currentPage, setCurrentPage] = useState(pageIndex + 1);

  const pageNumbers =
    pageCount > 0 ? [...Array(pageCount + 1).keys()].slice(1) : [];

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-end">
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm cursor-pointer mt-1"
          aria-label="Pagination"
        >
          <button
            onClick={() => {
              previousPage();
              canPreviousPage && setCurrentPage(currentPage - 1);
            }}
            disabled={!canPreviousPage}
            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-75 disabled:cursor-not-allowed"
          >
            <span className="sr-only">Previous</span>
            <FontAwesomeIcon icon="arrow-left-long" className="px-2" />
          </button>
          {pageNumbers?.map((pg) => {
            return (
              <a
                key={pg}
                onClick={() => {
                  gotoPage(pg - 1);
                  setCurrentPage(pg);
                }}
                aria-current="page"
                className={classNames(
                  'relative inline-flex items-center text-sm font-medium border px-4 py-2 focus:z-20',
                  currentPage === pg
                    ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50"',
                )}
              >
                {pg}
              </a>
            );
          })}
          <button
            onClick={() => {
              nextPage();
              canNextPage && setCurrentPage(currentPage + 1);
            }}
            disabled={!canNextPage}
            className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-75 disabled:cursor-not-allowed"
          >
            <span className="sr-only">Next</span>
            <FontAwesomeIcon icon="arrow-right-long" className="px-2" />
          </button>
        </nav>
      </div>
    </div>
  );
}
