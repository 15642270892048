import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getApprovedTerms } from '../store/actions/companies';
import { getMerchant } from '../store/actions/merchants';
import { loading } from '../store/actions/general';

function useDefaultTerms(company) {
  const dispatch = useDispatch();
  const [termOptions, setTermOptions] = useState();
  const defaultTermsList = company?.recharge_settings.toJS();
  const merchants = useSelector((state) => state.entities.get('merchant'))
    .valueSeq()
    .toJS();

  useEffect(() => {
    if (defaultTermsList.length) {
      let options = [];
      dispatch(loading(true));
      defaultTermsList?.forEach((i) => {
        dispatch(getMerchant(i.merchant_id));
        dispatch(getApprovedTerms(company.id, i.merchant_id))
          .then((res) => {
            options.push({
              merchant_id: i.merchant_id,
              terms: res.data.attributes.terms,
            });
          })
          .finally(() => {
            setTermOptions(options);
            dispatch(loading(false));
          });
      });
    }
  }, [company]);

  const defaultTermSettings = defaultTermsList.map((i) => {
    return {
      merchant_id: i.merchant_id,
      merchant_name: merchants.find((m) => i.merchant_id === m.id)?.name,
      default_term: {
        term: i.default_term,
        term_period: i.default_term_period,
      },
      term_options: termOptions?.find((o) => i.merchant_id === o.merchant_id)
        ?.terms,
    };
  });

  return { defaultTermSettings };
}

export default useDefaultTerms;
