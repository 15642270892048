import React, { useEffect } from 'react';

import useModal from '../../../hooks/useModal';
import {
  VirtualCardModal,
  ConfirmVCModal,
  VCRulesModal,
  ModalWrapper,
} from '..';
import ModalFooter from '../layout/ModalFooter';
import { pdGetUser } from '../../../lib/constants/PersistentData';

export default function VCModalWrapper({ isModalActive, toggleModal }) {
  const showVC = pdGetUser()?.show_vc_notice === undefined;
  const orderedScreens = showVC ? ['vc_rules', 'create_vc', 'confirm_vc'] : ['create_vc', 'confirm_vc'];

  const defaultFormState = {
    vc_request: {
      merchant_name: '',
      merchant_url: '',
      order_amount: '',
    },
  };

  const {
    activeScreen,
    setActiveScreen,
    nextScreen,
    previousScreen,
    resetScreen,
    modalState,
    setModalState,
  } = useModal(orderedScreens, defaultFormState);

  const modalProps = {
    nextScreen,
    previousScreen,
    setActiveScreen,
    modalState,
    setModalState,
    resetScreen,
  };

  const getScreen = () => {
    if (!activeScreen) return null;

    switch (activeScreen) {
      case 'vc_rules':
        return <VCRulesModal {...modalProps} />;
      case 'create_vc':
        return <VirtualCardModal {...modalProps} />;
      case 'confirm_vc':
        return <ConfirmVCModal {...modalProps} />;
    }
  };

  useEffect(() => {
    // Start at the first screen in the list
    resetScreen();
  }, [isModalActive]);

  return (
    <div data-testid={VCModalWrapper.role}>
      <ModalWrapper
        toggleModal={toggleModal}
        isModalActive={isModalActive}
        header=""
      >
        {getScreen()}
        <ModalFooter className="text-sm mt-8" />
      </ModalWrapper>
    </div>
  );
}

VCModalWrapper.role = 'vc-modal-wrapper-component';
