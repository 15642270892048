import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CTAButton({
  ariaLabel,
  buttonClass,
  buttonText,
  containerClass,
  icon,
  onClick,
  disabled,
  isLoading,
}) {
  return (
    <div className={`flex ${containerClass}`}>
      <button
        className={classNames(
          'py-2 shadow-md transition duration-150 ease-in-out font-avenir-medium rounded-full cursor-pointer text-sm md:text-base inline-block hover:bg-navy-blue',
          buttonClass,
          { 'cursor-not-allowed bg-opacity-50 hover:bg-opacity-50': disabled },
        )}
        type="submit"
        aria-label={ariaLabel}
        onClick={onClick}
        disabled={disabled}
      >
        <div className="flex justify-center items-center">
          <span className="hidden xs:inline">
            {icon && !isLoading && (
              <FontAwesomeIcon icon={icon} className="mr-2" />
            )}
            {isLoading && (
              <FontAwesomeIcon className="mr-2" icon={'fa-spinner'} spin />
            )}
          </span>
          <span>{buttonText}</span>
        </div>
      </button>
    </div>
  );
}
