import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { asyncActions } from '../store/actions/general';
import useCurrentUser from '../hooks/useCurrentUser';
import { getCompany, updateCompany } from '../store/actions/companies';

function useCompany() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [user] = useCurrentUser();
  const companies = useSelector((state) => state.entities.get('company'));
  const company = companies.get(user?.company_id);

  const actions = {
    loadForUser: (user) => {
      if (loading || !user?.company_id || companies.has(user?.company_id))
        return;

      setLoading(true);
      dispatch(asyncActions(getCompany(user?.company_id, 'include=cta_banners')))
        .then((res) => {
          return companies.get(user?.company_id);
        })
        .finally(() => setLoading(false));
    },

    update: (company) => dispatch(updateCompany(company, 'include=cta_banners')),
  };

  return [company, actions];
}

export default useCompany;
