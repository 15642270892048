import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import useToast from '../../../../hooks/useToast';
import Loading from '../../../shared/components/Loading';

export default function VCList({ virtual_cards }) {
  const { makeToast } = useToast();
  const [hide, setHide] = useState([]);
  const [clipped, setClipped] = useState(false);

  const sortedCards = virtual_cards
    ?.sort(function (a, b) {
      return new Date(b.get('created_at')) - new Date(a.get('created_at'));
    })
    .valueSeq();

  const cardNumberDisplay = (vc, idx) => {
    if (clipped)
      return `x${vc.get('virtual_card_details').get('number').slice(-4)}`;

    if (!hide[idx]) {
      return (
        <>
          {vc.get('virtual_card_details').get('number').substring(0, 4)}{'  '}
          {vc.get('virtual_card_details').get('number').substring(4, 8)}{'  '}
          {vc.get('virtual_card_details').get('number').substring(8, 12)}{'  '}
          {vc.get('virtual_card_details').get('number').substring(12, 16)}
        </>
      );
    } else {
      return (
        <>
          ••••{'  '}
          ••••{'  '}
          ••••{'  '}
          {vc.get('virtual_card_details').get('number').substring(12, 16)}
        </>
      );
    }
  };

  const columnHeaders = [
    { name: 'Date', className: 'hidden md:block lg:hidden 3xl:block' },
    { name: 'Merchant', className: 'pl-6 md:pl-1' },
    { name: 'Card Number', className: null },
    { name: 'Exp.', className: 'hidden md:block lg:hidden mxl:block' },
    { name: 'CVC', className: null },
  ];

  window.addEventListener('resize', () => {
    if (
      (window.innerWidth >= 1024 && window.innerWidth <= 1167) ||
      window.innerWidth < 415
    ) {
      setClipped(true);
    } else {
      setClipped(false);
    }
  });

  useEffect(() => {
    setHide(new Array(sortedCards?.size).fill(true));
  }, [virtual_cards]);

  if (!virtual_cards) return <Loading />;

  return (
    <div className="flex flex-col max-h-[14rem] overflow-y-scroll">
      <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="text-navy-blue">
              <tr>
                {columnHeaders.map((header, idx) => (
                  <th
                    key={idx}
                    scope="col"
                    className={classNames(
                      'py-3.5 text-left text-sm font-avenir-medium',
                      header.className,
                      header.name !== 'Merchant' && 'sm:pl-1 md:pl-0',
                    )}
                  >
                    {header.name}
                  </th>
                ))}
                <th scope="col" className="relative py-3.5 sm:pr-1 md:pr-0">
                  <span className="sr-only">Reveal</span>
                </th>
              </tr>
            </thead>
            <tbody className="overflow-y-scroll">
              {sortedCards?.map((vc, idx) => (
                <tr key={idx}>
                  <td className="whitespace-nowrap text-sm text-opaque-navy sm:pl-1 md:pl-0 hidden md:block lg:hidden 3xl:block">
                    {new Date(vc.get('created_at')).toLocaleDateString()}
                  </td>
                  <td className="whitespace-nowrap py-2 text-sm text-opaque-navy pl-6 md:pl-1 pt-3">
                    <span title={vc?.get('merchant').name}>
                      {vc?.get('merchant').name.substring(0, 6)}...
                    </span>
                    <span className="block md:hidden lg:block 3xl:hidden">
                      {new Date(vc.get('created_at')).toLocaleDateString()}
                    </span>
                    <span className="block md:hidden lg:block mxl:hidden">
                      Exp: {vc?.get('virtual_card_details').get('expiration')}
                    </span>
                  </td>
                  <td
                    className={classNames(
                      'whitespace-nowrap py-4 text-sm text-opaque-navy',
                      { 'tracking-wide': hide[idx] },
                    )}
                  >
                    {cardNumberDisplay(vc, idx)}
                    <FontAwesomeIcon
                      icon="clipboard"
                      className="text-lg text-gray-600 ml-2 hover:text-gray-900 cursor-pointer mt-0.5"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          vc?.get('virtual_card_details').get('number'),
                        );
                        makeToast(
                          'Card Number Copied!',
                          'positive-green',
                          'check-circle',
                        );
                      }}
                    />
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-opaque-navy hidden md:block lg:hidden mxl:block md:pt-5 lg:pt-4 mxl:pt-[1.35rem]">
                    {vc?.get('virtual_card_details').get('expiration')}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-opaque-navy pt-[1.28rem] xxl:pt-[1.35rem]">
                    <span
                      className={classNames({
                        'px-[1.1rem] py-1 rounded-full bg-gray-300': hide[idx],
                      })}
                    >
                      {!hide[idx] && vc?.get('virtual_card_details').get('cvc')}
                    </span>
                  </td>
                  <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                    <button
                      type="button"
                      className="flex tracking-wider text-navy-blue hover:text-primary-blue text-sm ml-4"
                      onClick={() =>
                        setHide(
                          hide.map((item, index) => {
                            if (index === idx) return (hide[index] = !item);
                            return item;
                          }),
                        )
                      }
                    >
                      {hide[idx] ? (
                        <FontAwesomeIcon icon="eye" className="mr-2 mt-0.5" />
                      ) : (
                        <FontAwesomeIcon
                          icon={['fas', 'lock-keyhole']}
                          className="mr-2 mt-0.5 text-primary-blue"
                        />
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <hr className="bg-border-gray h-[2px]" />
    </div>
  );
}
