import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import Card from '../../shared/layout/Card';
import CTAButton from '../../shared/CTAButton';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useToast from '../../../hooks/useToast';
import { exportPaymentHistory } from '../../../store/actions/payments';

export default function PaymentHistoryExport() {
  const lastYear = () => new Date().getFullYear() - 1;
  const firstDayOfLastYear = () => new Date(lastYear(), 0, 1);
  const lastDayOfLastYear = () => new Date(lastYear(), 11, 31);
  const format = (d) => `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

  const [startDate, setStartDate] = useState(firstDayOfLastYear());
  const [endDate, setEndDate] = useState(lastDayOfLastYear());
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useCurrentUser();
  const dispatch = useDispatch();
  const { makeToast } = useToast();

  const handleSubmit = () => {
    setIsLoading(true);
    dispatch(
      exportPaymentHistory({
        userId: user.id,
        startDate: format(startDate),
        endDate: format(endDate),
      }),
    )
      .then((res) => {
        makeToast(res.message, 'positive-green', 'check-circle');
      })
      .catch((err) => {
        makeToast(
          'An error occurred. Please try again or contact our support team.',
          'negative-red',
          ['far', 'octagon-xmark'],
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="col-span-full">
      <Card>
        {{
          header: {
            type: '',
            title: 'Export Payment History',
          },
          content: (
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <div className='my-4 sm:my-0 flex flex-col 3xs:flex-row gap-4'>
                <div className="inline-block">
                  <label htmlFor="start_date" className="block text-sm">
                    Start Date
                  </label>
                  <DatePicker
                    id="start_date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="w-full form-control p-3 text-sm xxs:text-base text-opaque-navy bg-white bg-clip-padding border border-solid border-opaque-navy-border block rounded-lg transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-primary-blue focus:outline-one input"
                  />
                </div>
                <div className="inline-block">
                  <label htmlFor="end_date" className="block text-sm">
                    End Date
                  </label>
                  <DatePicker
                    id="end_date"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="w-full form-control p-3 text-sm xxs:text-base text-opaque-navy bg-white bg-clip-padding border border-solid border-opaque-navy-border block rounded-lg transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-primary-blue focus:outline-one input"
                  />
                </div>
              </div>
              <CTAButton
                ariaLabel="export-payment-history"
                buttonClass="px-14 xs:px-16 bg-primary-blue text-white"
                buttonText="Export"
                containerClass="flex justify-end"
                icon={['fa', 'file-arrow-down']}
                onClick={handleSubmit}
                disabled={isLoading}
                isLoading={isLoading}
              />
            </div>
          ),
        }}
      </Card>
    </div>
  );
}
