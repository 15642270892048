import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';

import CTAButton from '../../shared/CTAButton';
import ColumnInput from '../../shared/ColumnInput';
import ErrorAlert from '../../modals/payment-methods/ErrorAlert';
import PaymentMethodRecord from '../../../lib/records/payment_method';
import StickyCheckbox from '../../shared/StickyCheckbox';
import StickyInput from '../../shared/StickyInput';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useToast from '../../../hooks/useToast';
import { asyncActions, loading } from '../../../store/actions/general';
import { getCompany } from '../../../store/actions/companies';
import {
  createPaymentMethodBank,
  getPaymentMethods,
  generateBankAgreement,
} from '../../../store/actions/payment_methods';

export default function BankForm({
  hasAlert,
  isLoading,
  nextScreen,
  setHasAlert,
  toggleModal,
}) {
  const form = useRef(null);
  const [user] = useCurrentUser();
  const dispatch = useDispatch();
  const [bankAgreement, setBankAgreement] = useState('');
  const { makeToast } = useToast();

  useEffect(() => {
    dispatch(loading(true));
    dispatch(asyncActions(generateBankAgreement(user)))
      .then((res) => {
        res && setBankAgreement(res.attributes.data);
      })
      .then(() => dispatch(loading(false)));
  }, [dispatch, user]);

  const onSubmit = async (values, { setFieldError, resetForm }) => {
    let encodedEmail = encodeURIComponent(user.email);
    dispatch(loading(true));
    dispatch(
      asyncActions(
        createPaymentMethodBank(new PaymentMethodRecord(values), 'bank'),
        getPaymentMethods(`filter[email]=${encodedEmail}`),
        getCompany(user?.company_id, 'include=cta_banners'),
      ),
    )
      .then(() => {
        resetForm();
        makeToast(
          'New Payment Method Added!',
          'positive-green',
          'check-circle',
        );
        toggleModal();
      })
      .catch((err) => {
        const invalid = err.message.includes('invalid_bank');
        const excessive = err.message.includes('excessive_attempts');

        if (excessive) {
          setHasAlert(true);
        } else if (invalid) {
          setFieldError(
            'bank_account',
            'Double check the account number and try again.',
          );
          setFieldError(
            'bank_routing',
            'Double check the routing number and try again.',
          );
        }
      })
      .finally(() => dispatch(loading(false)));
  };

  return (
    <Formik
      innerRef={form}
      initialValues={{
        agree: '',
        bank_routing: '',
        bank_account: '',
        account_holder: '',
        user_id: user.id,
      }}
      validate={async (values) => {
        const errors = {};
        if (!values.agree) errors.agree = '* Required';
        if (!values.bank_routing) {
          errors.bank_routing = '* Required';
        } else if (values.bank_routing.trim().length < 9) {
          errors.bank_routing = 'Routing No. must be exactly 9 characters long';
        }
        if (!values.account_holder) errors.account_holder = '* Required';
        if (!values.bank_account) errors.bank_account = '* Required';
        return errors;
      }}
      onSubmit={onSubmit}
    >
      {({ errors, setFieldValue }) => (
        <Form>
          <div className="flex flex-col m-auto w-4/5">
            <ErrorAlert hasAlert={hasAlert} nextScreen={nextScreen} />
            <ColumnInput error={errors.bank_routing}>
              <StickyInput
                id="bank_routing"
                inputMode="numeric"
                mask="999999999"
                placeholder="Routing Number"
              />
            </ColumnInput>
            <ColumnInput error={errors.bank_account}>
              <StickyInput
                id="bank_account"
                inputMode="numeric"
                mask={/^\d*$/}
                placeholder="Account Number"
              />
            </ColumnInput>
            <ColumnInput error={errors.account_holder}>
              <StickyInput id="account_holder" placeholder="Name on Account" />
            </ColumnInput>
            <div
              className="form-control block w-full p-3 text-sm font-normal text-opaque-navy bg-white bg-clip-padding border border-solid border-opaque-navy-border rounded transition ease-in-out m-0 focus:text-navy-blue focus:bg-white focus:border-navy-blue focus:outline-none h-40 max-h-40 overflow-y-scroll"
              dangerouslySetInnerHTML={{ __html: bankAgreement }}
            />
            <div className="mb-0">
              <div>
                <div className="flex justify-center my-2">
                  <StickyCheckbox
                    id="agree"
                    placeholder="I authorize Credit Key to charge scheduled payments to my bank account."
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
            </div>
            <CTAButton
              ariaLabel="save-payment-method"
              buttonClass="px-14 xs:px-16 bg-primary-blue text-white"
              buttonText="Save Payment Method"
              containerClass='my-4 mx-auto'
              disabled={Object.keys(errors).length !== 0 || isLoading}
              icon={['far', 'lock']}
              isLoading={isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
