import React, { useState } from 'react';
import classNames from 'classnames';

import CompanyDetails from './components/CompanyDetails';
import Loading from '../../shared/components/Loading';
import PageLayout from './PageLayout';
import UsersTable from './components/table/UsersTable';
import useCompanyUsers from '../../../hooks/useCompanyUsers';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useEntity from '../../../hooks/useEntity';

export default function Profile() {
  const [entity] = useEntity();
  const [user] = useCurrentUser();
  const [pageContent, setPageContent] = useState('details');

  const { companyUsers, owner } = useCompanyUsers();

  if (!user || !entity) return <Loading className="w-14 h-14" />;

  return (
    <PageLayout>
      <div role={Profile.role}>
        <div className="text-base text-center text-navy-blue mb-8 border-b">
          <ul className="flex flex-wrap -mb-px">
            <li>
              <div
                onClick={() => setPageContent('details')}
                className={classNames(
                  'inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-opaque-navy hover:border-opaque-navy cursor-pointer',
                  {
                    'active text-primary-blue border-blue-600 font-avenir-medium':
                      pageContent === 'details',
                  },
                )}
                aria-current="page"
              >
                {entity?.type === 'company' ? 'Company' : 'User'} Details
              </div>
            </li>
            {user.get('id') === owner?.id && entity?.type === 'company' && (
              <li>
                <div
                  onClick={() => setPageContent('users')}
                  className={classNames(
                    'inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-opaque-navy hover:border-opaque-navy cursor-pointer',
                    {
                      'active text-primary-blue border-primary-blue font-avenir-medium':
                        pageContent === 'users',
                    },
                  )}
                  aria-current="page"
                >
                  Users
                </div>
              </li>
            )}
          </ul>
        </div>
        {entity ? (
          pageContent === 'details' ? (
            <div>
              <CompanyDetails entity={entity} owner={owner} />
            </div>
          ) : (
            <UsersTable companyUsers={companyUsers} company={entity} />
          )
        ) : (
          <Loading />
        )}
      </div>
    </PageLayout>
  );
}

Profile.role = 'profile-component';
