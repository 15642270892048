import { Record } from 'immutable';
import { DateTime } from 'luxon';
import { differenceOfDays, fromISO } from '../utils/datetime_formatters';
import { convertRawValues } from '../utils/record_utils';

export default class PaymentPlanRecord extends Record({
  id: null,
  order_key: null,
  payment_plan_status: '',
  installments: '',
  amount: '',
  disbursement_date: '',
  payoff_balance: '',
  is_past_due: '',
  last_past_due_at: '',
  next_payment_due_date: '',
  next_payment_due_amount: '',
  interest_fee_percent: '',
  payment_schedule: [],
  transactions: [],
}) {
  constructor(data) {
    data = convertRawValues(data);
    super(data);
  }
  get progress() {
    if (['pending', 'paid'].includes(this.status)) {
      return 100;
    }
    return (this.paid_amount / this.amount) * 100;
  }

  get status() {
    return this.payment_plan_status;
  }

  get daysPastDue() {
    let today = DateTime.now();
    let due_date = fromISO(this.last_past_due_at);

    return differenceOfDays(today, due_date);
  }

  get isPastDue() {
    return this.is_past_due === true;
  }

  get next_payment_date() {
    return this.next_payment_due_date;
  }

  get total_balance() {
    return this.payoff_balance;
  }

  get paid_amount() {
    let sum = 0;
    this.payment_schedule.forEach((schedule) => {
      if (['paid', 'pending'].includes(schedule.get('status'))) {
        sum += parseFloat(schedule.get('amount'));
      }
    });
    return sum;
  }
}
