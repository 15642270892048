import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../../shared/components/Loading';
import PageHeader from '../../shared/layout/PageHeader';
import useEntity from '../../../hooks/useEntity';

export default function PageLayout({ order, children }) {
  const [entity] = useEntity();

  if (!entity) return <Loading />;

  return (
    <div role={PageLayout.role} className='min-h-screen mb-16 px-4 md:px-12 xxl:px-32'>
      <PageHeader
        title={
          <Link to="/orders">
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                className="text-2xl mb-1"
                icon={['far', 'chevron-left']}
              />
              <span className="hidden mx-5 mb-1 w-12 h-12 sm:flex justify-center items-center bg-transparent border-dark-gray border-2 rounded-full text-primary-blue font-avenir-black text-xl">
                <FontAwesomeIcon icon={['far', 'file-invoice-dollar']} />
              </span>
              <span className="font-avenir-medium text-2xl sm:text-3xl mx-5 sm:mx-0">
                {order?.merchant.name} Order ID: {order?.merchant_order_id}
              </span>
            </div>
          </Link>
        }
      />

      <div>{children}</div>
    </div>
  );
}

PageLayout.role = 'order-detail-page-layout';
