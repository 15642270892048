import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import FullStory from './lib/utils/full_story';
import Heap from './lib/utils/heap';
import { createStore } from './store';
import { platform } from './lib/constants/SiteVariables';

import './index.css';

const { store, history } = createStore();
const prepare = () => {
  if (process.env.REACT_APP_ENABLE_MOCKING) {
    const { mockWorker } = require('./lib/utils/service_worker_mocks');

    return mockWorker.start({
      onUnhandledRequest: 'warn',
    });
  } else {
    return Promise.resolve();
  }
};

const heap = new Heap();
heap.load(platform());

const fullStory = new FullStory();
fullStory.init(platform());

prepare().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
  );
});

if (module.hot) {
  module.hot.accept(function (err) {
    console.log(err, 'An error occurred while accepting new version');
  });
}
