import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import logo from '../../lib/images/logo.svg';
import ModalFooter from '../modals/layout/ModalFooter';
import TopNav from '../layout/TopNav';
import UserVerification from '../shared/UserVerification';
import { asyncActions, loading } from '../../store/actions/general';
import { useAuth } from '../../hooks/useAuth';

export default function Login() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const save = (values, input_type) => {
    dispatch(loading(true));
    dispatch(asyncActions(auth.verify(input_type, values.contact)))
      .then((res) => {
        if (!res) return;

        navigate(`/login-token/${input_type}/${values.contact}`, {
          state: location.state,
        });
      })
      .finally(() => dispatch(loading(false)));
  };

  if (auth.isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <TopNav />
      <div
        className="min-h-full flex flex-col justify-center xxs:py-0 lg:py-4 sm:px-6 lg:px-8"
        role={Login.role}
      >
        <div className="mt-8 xxs:mx-auto xxs:w-full xxs:max-w-lg">
          <div className="bg-white my-4 xxs:py-10 sm:py-20 px-10 sm:shadow sm:rounded-lg sm:px-14 text-center">
            <div className="sm:flex justify-center mb-8 hidden">
              <img src={logo} alt="Credit Key logo" />
            </div>
            <h2 className="my-2 text-3xl font-avenir-black text-navy-blue">
              Welcome to Credit Key
            </h2>
            <p className="my-2 text-base font-avenir-book text-opaque-navy">
              Please sign in. We'll send a 6-digit code to you.
            </p>
            <UserVerification
              defaultValue=""
              buttonText="Sign in"
              onSubmit={save}
            />
            <ModalFooter className="text-opaque-navy text-xs mt-8" />
          </div>
        </div>
      </div>
    </>
  );
}

Login.role = 'login-component';
