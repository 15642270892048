import React, { useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Pagination from './components/Pagination';

export default function BaseTable({
  tableInstance,
  expandedRow,
  noDataFoundWarning,
  onClickHandler,
  tableClass,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    rows,
    prepareRow,
    visibleColumns,
    state: { pageIndex },
  } = tableInstance;

  const rowList = page ? page : rows;
  const usePagination = page ? true : false;

  window.addEventListener('resize', () => {
    tableInstance.columns.forEach((column) => {
      if (window.innerWidth <= column.hideColumn && column.isVisible) {
        column.toggleHidden(true);
      }
      if (window.innerWidth >= column.hideColumn && !column.isVisible) {
        column.toggleHidden(false);
      }
    });
  });

  useEffect(() => {
    tableInstance.columns.forEach((column) => {
      if (window.innerWidth <= column.hideColumn && column.isVisible) {
        column.toggleHidden(true);
      }
      if (window.innerWidth >= column.hideColumn && !column.isVisible) {
        column.toggleHidden(false);
      }
    });
  }, []);

  return (
    <>
      <table {...getTableProps()} className={`min-w-full ${tableClass}`}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
              {headerGroup.headers.map((column) => (
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-avenir-book text-sm text-opaque-navy"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon={['far', 'angle-down']}
                          className="text-primary-blue ml-1"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={['far', 'angle-up']}
                          className="text-primary-blue ml-1"
                        />
                      )
                    ) : (
                      ' '
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody
          {...getTableBodyProps()}
          className="text-sm sm:text-base -mx-2 overflow-auto text-navy-blue"
        >
          {rows.length > 0 ? (
            rowList.map((row, i) => {
              prepareRow(row);
              const expandedRowProps = expandedRow
                ? row.getRowProps(row.getToggleRowExpandedProps())
                : {};

              return (
                <Fragment key={i}>
                  <tr
                    {...expandedRowProps}
                    onClick={() =>
                      onClickHandler ? onClickHandler(row.original) : {}
                    }
                    style={
                      row.isExpanded
                        ? { background: '#59738E', color: 'white' }
                        : null
                    }
                    className={classNames(
                      'py-4 border-b last:border-b-0 hover:bg-primary-blue hover:bg-opacity-10',
                      onClickHandler ? 'cursor-pointer' : '',
                    )}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: classNames(
                              'px-6 py-4 whitespace-nowrap',
                              cell.column.className,
                            ),
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr className="bg-light-blue">
                      <td colSpan={visibleColumns.length}>{expandedRow}</td>
                    </tr>
                  )}
                </Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan={visibleColumns.length} className="px-4 pt-3 pb-1">
                {noDataFoundWarning}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {usePagination && (
        <Pagination
          pageCount={pageCount}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
        />
      )}
    </>
  );
}
