import React from 'react';
import { DateTime } from 'luxon';

import useOrderFees from '../../../../hooks/useOrderFees';
import { fromISO } from '../../../../lib/utils/datetime_formatters';
import { formatCurrency } from '../../../../lib/utils/formatters';

export default function FeesDisplay({ order }) {
  const {
    compressedFeeSummary,
    feeDisplay,
    setFeeDisplay,
    getFeeTitle,
    getFeeBreakdownDisplay,
  } = useOrderFees(order);

  return (
    <div className="flex flex-col">
      <h1 className="font-avenir-medium text-navy-blue px-4 py-2">
        Click on a fee type below to see more details
      </h1>
      <div className="divide-y">
        {compressedFeeSummary?.map((f) => {
          return (
            <div
              key={f.fee_type}
              onClick={() => {
                setFeeDisplay((prevFeeDisplay) => ({
                  ...prevFeeDisplay,
                  [f.fee_type]: !feeDisplay[f.fee_type],
                }));
              }}
              className="flex flex-col hover:bg-light-blue hover:rounded-md hover:cursor-pointer"
            >
              <div className="p-4 flex items-center justify-between">
                <div className="text-navy-blue font-avenir-black">
                  <span>
                    {getFeeTitle(f.fee_type)}
                    (s)
                  </span>
                  <span className="ml-1.5 text-sm text-gray-400">
                    x{f.count}
                  </span>
                </div>
                {f.fee_type === 'PROCESSING_FEE' && f.adjustments ? (
                  <p className="text-navy-blue font-avenir-black">
                    {formatCurrency(
                      f.totalAmount -
                        f.adjustments.fees[f.adjustments.fees.length - 1].get(
                          'amount',
                        ),
                    )}
                  </p>
                ) : (
                  <p className="text-navy-blue font-avenir-black">
                    {f.adjustments
                      ? formatCurrency(
                          f.totalAmount - f.adjustments.totalAmount,
                        )
                      : formatCurrency(f.totalAmount)}
                  </p>
                )}
              </div>
              {feeDisplay && feeDisplay[f.fee_type] && (
                <div className="px-10 divide-y mb-2">
                  {f.adjustments
                    ? getFeeBreakdownDisplay(f, f.adjustments)
                    : f.fees.map((i) => {
                        return (
                          <div
                            key={i.get('id')}
                            className="py-2 flex justify-between text-navy-blue"
                          >
                            <p>
                              {fromISO(
                                i.get('value_date'),
                              ).toLocaleString(DateTime.DATE_SHORT)}
                            </p>
                            <p>{formatCurrency(i.get('amount'))}</p>
                          </div>
                        );
                      })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
