import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';

import {
  getLocalItem,
  setLocalItem,
  removeLocalItem,
  isLocalItemExpired,
} from '../utils/local_storage';

const defaultLabel = 'creditkey';

const removeAuthorizationIfExpired = (data) => {
  if (data && isLocalItemExpired(data)) data = pdRemoveToken(data);

  return data;
};

export const pdRemoveToken = (data) => {
  data.user = {};

  setLocalItem(defaultLabel, data);

  return getLocalItem(defaultLabel);
};

const pdGetData = () => {
  return removeAuthorizationIfExpired(getLocalItem(defaultLabel));
};

export const pdExpire = () => {
  setLocalItem(defaultLabel, {
    user: {},
    timestamp: Math.floor(
      DateTime.now().minus({ hours: 24 }).toMillis() / 1000,
    ),
  });
};

export const pdIsExpired = () => {
  const ck = getLocalItem(defaultLabel);
  return ck && isLocalItemExpired(ck);
};

export const pdGetUser = () => {
  const ck = pdGetData();

  if (ck && !isEmpty(ck.user)) return ck.user;
  return false;
};

export const pdSaveToken = (user, token) => {
  if (!token.access_token) return false;

  let ck = getLocalItem(defaultLabel);
  if (!ck) {
    ck = {};
  }

  ck.timestamp = token.created_at + token.expires_in;
  ck.user = {
    id: user.id,
    token: token,
  };

  setLocalItem(defaultLabel, ck);
};

export const pdRemoveCreditKey = () => {
  removeLocalItem(defaultLabel);
};

export const pdHideVirtualCardNotice = () => {
  let ck = getLocalItem(defaultLabel);

  ck.user = { show_vc_notice: false, ...ck.user };
  setLocalItem(defaultLabel, ck);
};
