import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthButton from './AuthButton';
import logo from '../../lib/images/logo.svg';

export default function SideNav({ primaryRoutes }) {
  const [extend, setExtend] = useState(false);

  const toggleExtend = () => {
    setExtend(!extend);
  };

  window.addEventListener(
    'resize',
    () => window.innerWidth <= 1425 && setExtend(false),
  );

  return (
    <>
      <div className="invisible w-[5.5rem]" />
      <div
        className={`hidden md:inline-block top-0 left-0 bg-light-blue text-navy-blue fixed h-full z-40 ease-in-out duration-500 ${
          extend ? 'w-72' : 'overflow-hidden w-[5.5rem]'
        }`}
      >
        <div
          className="flex items-center mr-2 md:mr-4 my-8 cursor-pointer shrink-0"
          onClick={toggleExtend}
        >
          <FontAwesomeIcon
            icon={['far', 'chevron-left']}
            className={classNames(
              'mr-2 text-xs ml-5 transition-opacity duration-500 ease-out',
              extend ? 'opacity-100' : 'opacity-0',
            )}
          />
          <FontAwesomeIcon icon={['far', 'bars']} className="text-lg" />
          <FontAwesomeIcon
            icon={['far', 'chevron-right']}
            className={classNames(
              'ml-2 text-xs transition-opacity duration-500 ease-out',
              extend ? 'opacity-0' : 'opacity-100',
            )}
          />
          <img
            src={logo}
            alt="Credit Key"
            className={classNames(
              'min-w-[133px] mx-[1.35rem] transition-opacity duration-1000 ease-out',
              extend ? 'opacity-100' : 'opacity-0',
            )}
          />
        </div>
        <div className="mt-6 md:mt-12 flex items-center">
          <div className="w-full">
            {primaryRoutes.map((_r, idx) => {
              return (
                <div className="text-navy-blue font-avenir-book" key={idx}>
                  {_r.name === 'Help Center' ? (
                    <a
                      href={_r.url}
                      target={_r.target}
                      className="text-navy-blue font-avenir-book w-1 h-full rounded-tr-md rounded-br-md cursor-pointer hover:text-navy-blue transition flex items-center py-2 md:py-3"
                    >
                      <FontAwesomeIcon
                        icon={_r.icon}
                        className="px-8 shrink-0 min-w-[24px] text-xl"
                      />
                      <div className="text-sm md:text-base whitespace-nowrap">
                        {_r.name}
                      </div>
                    </a>
                  ) : (
                    <NavLink
                      exact="true"
                      to={_r.url}
                      target={_r.target}
                      className={({ isActive }) =>
                        classNames(
                          'w-1 h-full rounded-tr-md rounded-br-md cursor-pointer hover:text-navy-blue transition flex items-center py-2 md:py-3',
                          {
                            'bg-primary-blue text-primary-blue font-avenir-medium':
                              isActive,
                          },
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={_r.icon}
                        className="px-8 shrink-0 min-w-[24px] text-xl"
                      />
                      <div className="text-sm md:text-base whitespace-nowrap">
                        {_r.name}
                      </div>
                    </NavLink>
                  )}
                </div>
              );
            })}
            <div
              className={classNames(
                'w-4/5 mt-24 mb-8 m-auto border-t border-gray-300 transition-opacity duration-500 ease-out',
                extend ? 'opacity-100' : 'opacity-0',
              )}
            />
            <AuthButton extend={extend} toggleExtend={toggleExtend} />
          </div>
        </div>
      </div>
    </>
  );
}
