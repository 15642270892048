import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useEntity from './useEntity';
import { getCompanyUsers } from '../store/actions/users';

function useCompanyUsers() {
  const dispatch = useDispatch();
  const [entity] = useEntity();
  const [loading, setLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);

  const companyUsers = useSelector((state) => state.entities.get('user'))
    .filter((i) => {
      if (entity?.type === 'account') return i.account_id === entity?.get('id');
      return i.company_id === entity?.get('id');
    })
    ?.valueSeq();

  const owner = companyUsers?.valueSeq().find((u) => u.role === 'borrower');

  useEffect(() => {
    if (
      companyUsers.size === 1 &&
      companyUsers?.first().get('id') === owner?.get('id')
    ) {
      setUsersLoading(true);
    }
  }, [companyUsers]);

  useEffect(() => {
    if (entity && entity?.type === 'company' && !loading && !usersLoading) {
      setLoading(true);
      dispatch(getCompanyUsers(entity?.id)).finally(() => {
        setLoading(false);
        setUsersLoading(true);
      });
    }
  }, [entity, companyUsers]);

  return { companyUsers, owner };
}

export default useCompanyUsers;
