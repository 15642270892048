import React from 'react';
import { Form } from 'formik';

import CTAButton from '../../shared/CTAButton';
import ColumnInput from '../../shared/ColumnInput';
import StickyInput from '../../shared/StickyInput';
import StickyCheckbox from '../../shared/StickyCheckbox';
import useCompany from '../../../hooks/useCompany';
import vc from '../../../lib/images/vc-image.png';
import { formatCurrency } from '../../../lib/utils/formatters';

export default function VirtualCardForm({
  errors,
  activePromo,
  values,
  setFieldValue,
}) {
  const [company] = useCompany();

  const sublimits = {
    max: parseInt(company?.vc_sub_limits?.get('limit_amount')),
    used: parseInt(
      company?.vc_sub_limits?.get('limit_amount') -
        company?.vc_sub_limits?.get('remaining_limit_amount'),
    ),
    remaining: parseInt(company?.vc_sub_limits?.get('remaining_limit_amount')),
  };

  return (
    <Form>
      <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start p-4 gap-6">
        <img
          src={vc}
          alt="Virtual Card Icon"
          className="w-3/4 xs:w-1/2 sm:w-2/5 mx-auto"
        />
        <div className="flex flex-col items-center sm:items-start w-3/4 sm:w-3/5 py-4 sm:py-0 border-t border-b border-black border-opacity-10 sm:border-none">
          <div className="flex items-start font-avenir-medium text-xl mb-2 text-center">
            <p>Virtual Card Limit</p>
            <span className="text-sm">*</span>
          </div>
          <div className="flex flex-col xs:flex-row justify-between items-center w-full mb-1">
            <p>Maximum Amount:</p>
            <span className="font-avenir-black text-navy-blue xs:ml-1 sm:text-right">
              {formatCurrency(sublimits.max)}
            </span>
          </div>
          <div className="flex flex-col xs:flex-row justify-between items-center w-full mb-1">
            <p>Amount in Use:</p>
            <span className="font-avenir-black text-opaque-navy xs:ml-1 sm:text-right">
              {formatCurrency(sublimits.used)}
            </span>
          </div>
          <div className="flex flex-col xs:flex-row justify-between items-center w-full">
            <p>Remaining Amount:</p>
            <span className="font-avenir-black text-positive-green xs:ml-1 sm:text-right">
              {formatCurrency(sublimits.remaining)}
            </span>
          </div>
        </div>
      </div>
      <section className="mt-2 xs:mt-6">
        <div className="mx-6">
          <div>
            <p className="text-center text-2xl xs:text-3xl my-1 font-avenir-black text-navy-blue">
              Credit Key Virtual Card
            </p>
            <p className="text-center text-opaque-navy mb-0">
              You've been selected for VIP access to virtual cards!
            </p>
          </div>
        </div>
        <div className="pt-6 xs:pt-8 mx-4 flex flex-col justify-center items-center">
          <div className="w-full flex flex-col xs:flex-row justify-between items-start gap-x-2">
            <ColumnInput error={errors.merchant_name}>
              <StickyInput
                id="merchant_name"
                name="merchant_name"
                placeholder="Merchant Name"
                type="text"
              />
            </ColumnInput>
            <ColumnInput error={errors.merchant_url}>
              <StickyInput
                id="merchant_url"
                name="merchant_url"
                placeholder="Merchant Website"
                type="text"
              />
            </ColumnInput>
          </div>
          <ColumnInput error={errors.order_amount}>
            <StickyInput
              id="order_amount"
              name="order_amount"
              mask={Number}
              thousandsSeparator=","
              scale={2}
              radix="."
              padFractionalZeros={true}
              placeholder="Order Amount"
            />
          </ColumnInput>
        </div>

        {((activePromo && errors.order_amount) || values.bypass) && (
          <div className="mx-4 mb-4">
            <StickyCheckbox
              id="bypass"
              placeholder={`Skip promotion, get 0% for 30 days`}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </div>
        )}
      </section>
      <CTAButton
        buttonClass="w-full mx-4 py-4 bg-primary-blue text-white"
        buttonText="Continue"
        containerClass="my-1 justify-center"
        icon={['far', 'lock']}
      />
      <div className="w-full mx-auto px-6 sm:px-12 my-2">
        <p className="text-sm text-gray-500 text-center">
          *Virtual Card transactions are capped at 50% of your trade credit
          limit up to a maximum of $25,000.
        </p>
      </div>
    </Form>
  );
}
