import Network from "../../lib/utils/network";
import { receiveEntity } from "./entities";

const type = 'companies';
const api = Network();

const path = (resource, params = '') => {
  if (!resource) resource = "";

  return {
    resource: "api/" + type + "/" + resource,
    params: params ? `?${params}` : ''
  };
};

export const validateEin = (ein) => {
  return (dispatch, getState) => {
    return api.post(path("0/validate_ein"), { ein: ein });
  };
};

export const getCompany = (id, filters) => {
  return (dispatch, getState) => {
    return api.get(path(id, filters)).then((res) => dispatch(receiveEntity(res)));
  };
};

export const getApprovedTerms = (company_id, merchant_id) => {
  return (dispatch, getState) => {
    return api.post(path(company_id + '/approved_terms'), { merchant_id });
  }
}

export const updateCompany = (company, filters) => {
  return (dispatch, getState) => {
    return api.edit(path(company.id, filters), company)
      .then(res => dispatch(receiveEntity(res)))
  }
}

export const getMinTermRate = (id) => {
  return (dispatch, getState) => {
    return dispatch(getCredit(id, "Terms", "min_rate"));
  };
};

export const getCreditLineAvailable = (id) => {
  return (dispatch, getState) => {
    return dispatch(getCredit(id, "Lines", "pending_amount_available"));
  };
};

export const getCredit = (id, type, method) => {
  return (dispatch, getState) => {
    if (!id || !type || !method)
      return Promise.reject("Missing required params");

    return api.post(path(id + "/credit"), {
      type: type,
      method: method,
    });
  };
};

export const getVirtualCardDetails = (id, order_key) => {
  return (dispatch, getState) => {
    return api.post(path(id + "/virtual_card_details"), { order_key });
  };
};
