import React from 'react';

export default function ModalFooter({ className }) {
  return (
    <footer
      className={`flex flex-col items-center text-opaque-navy bg-white border-t-0 p-t-0 ${className}`}
      role={ModalFooter.role}
    >
      <div className="flex flex-col items-center text-center">
        <div>
          Loans made by Lead Bank. Visit
          <a
            href="https://www.creditkey.com/"
            target="_new"
            className="mx-1 underline text-primary-blue hover:text-navy-blue"
          >
            creditkey.com
          </a>
          for more info.
        </div>
        <div className="flex justify-between divide-x my-1">
          <a
            href="https://www.creditkey.com/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="px-2 no-underline text-primary-blue hover:text-navy-blue"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.creditkey.com/legal/terms-of-service-and-electronic-disclosures"
            target="_blank"
            rel="noreferrer"
            className="px-2 no-underline text-primary-blue hover:text-navy-blue"
          >
            Terms of Service
          </a>
          <a
            href="https://lead.bank/privacy-and-terms"
            target="_blank"
            rel="noreferrer"
            className="px-2 no-underline text-primary-blue hover:text-navy-blue"
          >
            Lead Bank Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

ModalFooter.role = 'modal-footer-component';
