import React from 'react';

export default function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  defaultPlaceholder,
}) {
  return (
    <div className="mb-4">
      <div className="xl:w-96">
        <div className="input-group relative flex flex-wrap items-stretch w-full rounded">
          <input
            type="search"
            className="form-control relative flex-auto min-w-0 block w-full mx-3 px-3 leading-tight font-normal text-opaque-navy bg-white bg-clip-padding border border-solid border-opaque-navy-border rounded transition ease-in-out m-0 focus:text-navy-blue focus:bg-white focus:border-primary-blue focus:outline-none"
            value={globalFilter || ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={defaultPlaceholder}
            aria-label="Search"
            aria-describedby="search-input"
          />
        </div>
      </div>
    </div>
  );
}
