import { fromJS, List } from 'immutable';
import * as ActionTypes from "../ActionTypes";

export const generalState = fromJS({
  debugging: {
    key: "",
    workflow: "",
    action: "",
    uuid: null,
  },
  errors: [],
  rethrow: false,
  loading: false,
  possessed_user_id: null,
  promo_id: null,
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function general(state = generalState, action) {
  switch (action.type) {
    case ActionTypes.RESET_GENERAL:
      return generalState;

    case ActionTypes.CLEAR_ERRORS:
      return setState(state, state.set('errors', List()));

    case ActionTypes.SET_DEBUG:
      return setState(
        state,
        state.setIn(["debugging", action.payload.key], action.payload.value)
      );

    case ActionTypes.SET_ERROR:
      return setState(
        state,
        state.set("errors", state.get("errors").push(action.payload))
      );

    case ActionTypes.TOGGLE_RETHROW:
      return setState(state, state.set("rethrow", !state.get("rethrow")));

    case ActionTypes.APP_LOADING:
      return setState(state, state.set('loading', action.payload));

    case ActionTypes.SET_POSSESSED_USER:
      return setState(
        state,
        state.set('possessed_user_id', action.payload)
      );

    case ActionTypes.SET_PROMO_ID:
      return setState(state, state.set('promo_id', action.payload));

    default:
      return state;
  }
}
