import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CTAButton from '../../shared/CTAButton';
import { formatCurrency } from '../../../lib/utils/formatters';

export default function CompleteModal({ order, modalState, toggleModal }) {
  return (
    <div className="my-16" role={CompleteModal.role}>
      <section>
        <div className="flex flex-col items-center justify-center text-center">
          <FontAwesomeIcon
            icon={['fal', 'calendar-check']}
            className="text-6xl mb-3 text-positive-green"
          />
          <p className="text-2xl font-avenir-medium">
            We've scheduled your payment.
          </p>
          <p className="text-sm mx-4 my-2 text-opaque-navy">
            Please allow 2-3 business days for the payment to post to your loan.
            You can view your updated payment schedule after closing this
            window.
          </p>
        </div>
        <div className="flex items-center justify-between divide-x px-5 text-primary-blue my-6">
          <div className="w-1/2 flex flex-col justify-center items-center">
            <p className="text-base text-opaque-navy">Order ID</p>
            <span className="font-avenir-black text-xl xs:text-3xl w-5/6 text-center break-words">{order.merchant_order_id}</span>
          </div>
          <div className="w-1/2 flex flex-col justify-center items-center">
            <p className="text-base text-opaque-navy">Payment Amount</p>
            <span className="font-avenir-black text-xl xs:text-3xl">
              {formatCurrency(modalState.amount)}
            </span>
          </div>
        </div>
      </section>
      <CTAButton
        buttonClass="w-full mx-10 mt-6 bg-primary-blue text-white"
        containerClass="w-full"
        buttonText="Close"
        onClick={toggleModal}
      />
    </div>
  );
}

CompleteModal.role = 'complete-modal-component';
