import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CKAModalWrapper from '../../../modals/cka/CKAModalWrapper';

import Box from '../../../shared/layout/Box';
import useModal from '../../../../hooks/useModal';

export default function CreditKeyAnywhere() {
  const { isModalActive, toggleModal } = useModal();
  return (
    <div className="md:col-span-full lg:col-span-2" role={CreditKeyAnywhere.role}>
      <Box>
        {{
          type: 'cka',
          color: 'bg-primary-blue',
          headerIcon: <FontAwesomeIcon icon={['far', 'file-invoice-dollar']} />,
          title: 'Pay your invoices with Credit Key Anywhere',
          submit: {
            label: 'Submit New Invoice',
            handler: () => window.open('https://www.creditkey.com/anywhere', '_blank')
          },
        }}
      </Box>
      <CKAModalWrapper
        isModalActive={isModalActive}
        toggleModal={toggleModal}
      />
    </div>
  );
}

CreditKeyAnywhere.role = 'credit-key-anywhere-widget';
