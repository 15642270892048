import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from 'react-table';

import BaseTable from '../../../shared/BaseTable';
import Loading from '../../../shared/components/Loading';
import TableHeader from './TableHeader';
import { OrderTableColumns } from './OrderTableColumns';

export default function OrderTable({
  orders,
  ordersLoading,
  pageCount,
  fetchData,
}) {
  const navigate = useNavigate();
  const [active, setActive] = useState('all');

  const data = useMemo(() => orders?.valueSeq() || [], [orders?.size]);
  const columns = useMemo(() => OrderTableColumns, []);
  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 15,
        sortBy: [
          { id: 'created_at', desc: true },
          { id: 'status', desc: true },
        ],
      },
      manualSortBy: true,
      disableSortBy: true,
      manualPagination: true,
      autoResetPage: false,
      pageCount: pageCount,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { pageIndex } = instance.state;

  const onFetchDataDebounced = useAsyncDebounce(fetchData, 250);

  // If provided with a data fetching function for any server side pagination / filtering etc,
  // listen for related changes and load new page data.
  useEffect(() => {
    if (!fetchData) return;

    onFetchDataDebounced({ pageIndex });
  }, [pageIndex]);

  if (ordersLoading) return <Loading />;

  return (
    <div
      className="flex flex-col max-h-container border rounded-lg xxs:max-w-table xxl:max-w-full overflow-x-scroll mb-6 mx-auto"
      role={OrderTable.role}
    >
      <TableHeader
        tableInstance={instance}
        active={active}
        setActive={setActive}
      />
      <div className="overflow-x-auto">
        <div className="inline-block sm:px-4 lg:px-6">
          <div className="overflow-x-auto max-h-table">
            <BaseTable
              tableInstance={instance}
              noDataFoundWarning={
                active === 'all'
                  ? 'No orders found.'
                  : `No ${active} orders found.`
              }
              onClickHandler={(value) => navigate(`/orders/${value.id}`)}
              tableClass="sm:mt-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

OrderTable.role = 'order-table-component';
