import { useState } from 'react';

function useDropdown(defaultContentId = '') {
  const [contentItems, setContentItems] = useState(defaultContentId);
  const setContent = (id) => setContentItems(id);

  return {
    contentItems,
    setContentItems,
    setContent,
  };
}

export default useDropdown;
