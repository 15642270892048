import React from 'react';
import { Form } from 'formik';

import ApprovedGoods from '../../modals/cka/ApprovedGoods';
import CTAButton from '../../shared/CTAButton';
import ColumnInput from '../../shared/ColumnInput';
import StickyCheckbox from '../../shared/StickyCheckbox';
import StickyInput from '../../shared/StickyInput';

export default function Step1Form({ errors, setFieldValue, dateFrom }) {
  return (
    <Form className="divide-y">
      <div className="my-5 mx-16 flex flex-col items-center">
        <ColumnInput error={errors.vendor_name}>
          <StickyInput
            id="vendor_name"
            name="vendor_name"
            placeholder="Vendor Name"
            type="text"
          />
        </ColumnInput>
        <ColumnInput error={errors.invoice_amount}>
          <StickyInput
            id="invoice_amount"
            name="invoice_amount"
            mask={Number}
            thousandsSeparator=","
            scale={2}
            radix="."
            padFractionalZeros={true}
            placeholder="Invoice Amount"
          />
        </ColumnInput>
        <p className="font-avenir-black text-center text-lg mt-4 text-primary-blue">
          Does your invoice qualify?
        </p>
      </div>
      <div className="mx-6">
        <div className="m-4">
          <p className="text-left">
            1. The invoice must be less than 45 days old.
          </p>
          <div className="flex justify-center my-2">
            <StickyCheckbox
              id="confirmed_invoice_date"
              placeholder={`Invoice date is ${dateFrom} or later`}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
      </div>
      <div className="mx-6">
        <div className="m-4">
          <p className="text-left">
            2. This must be a purchase of goods or services for your business.
          </p>
          <ApprovedGoods />
          <div className="flex justify-center my-2">
            <StickyCheckbox
              id="confirmed_only_business_purchases"
              placeholder="This is for a qualified business purchase"
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
      </div>
      <CTAButton
        buttonClass='px-32 bg-primary-blue text-white'
        buttonText="Continue"
        containerClass="my-6 pt-6 justify-center"
        icon={['far', 'lock']}
      />
    </Form>
  );
}
