import React from 'react';
import CTAButton from '../../shared/CTAButton';
import { pdHideVirtualCardNotice } from '../../../lib/constants/PersistentData';

export default function VCRulesModal({ nextScreen, setActiveScreen }) {
  return (
    <div role={VCRulesModal.role}>
      <section>
        <div>
          <p className="text-center text-2xl my-2 font-avenir-black text-primary-blue uppercase tracking-wider">
            Virtual Card Usage
          </p>
          <div className="px-6 my-5">
            <p className="mb-0">
              Use of virtual cards are for commercial loan purposes only.
            </p>
            <ul role="list" className="list-disc text-left space-y-1 pl-3 my-3">
              <li>
                Virtual cards are not to be used for personal, family, or
                household use.
              </li>
              <li>
                Processing virtual cards through your merchant account is
                strictly prohibited.
              </li>
            </ul>
            <p className="mb-0">
              Transactions found to be in violation will result in virtual card
              access being revoked and potential suspension of your Credit Key
              account.
            </p>
          </div>
        </div>
        <div className="content flex flex-col items-center my-2">
          <CTAButton
            ariaLabel="back"
            buttonClass="px-16 bg-primary-blue text-white"
            buttonText="I Understand"
            onClick={nextScreen}
          />
          <div
            onClick={() => {
              pdHideVirtualCardNotice();
              setActiveScreen('create_vc');
            }}
            className="text-table-gray text-sm mt-3 cursor-pointer hover:text-gray-800 flex justify-center items-center"
          >
            <p>Do Not Show Again</p>
          </div>
        </div>
      </section>
    </div>
  );
}

VCRulesModal.role = 'vc-rules-modal-component';
