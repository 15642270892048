import React from 'react';

export default function BankPendHelp() {
  return (
    <div>
      <section className="text-center py-6">
        <h3 className="font-avenir-medium text-lg text-primary-blue">
          Connecting you with Credit Key support...
        </h3>
        <h4 className="text-base my-2">
          Please attach a voided check in the chat window.
        </h4>
      </section>
    </div>
  );
}
