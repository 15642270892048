import React, { Fragment } from 'react';
import { Menu, Transition, Listbox } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Dropdown = ({ button, children }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>{button}</Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export const ListBox = ({
  initialValue,
  buttonText,
  handleChange,
  listBoxClass,
  children,
}) => {
  return (
    <Listbox as="div" value={initialValue} onChange={handleChange}>
      <div
        className={`${listBoxClass} mx-auto rounded-md border border-dark-gray focus:outline-none focus:shadow-outline-blue focus:border-primary-blue transition ease-in-out duration-150 text-sm xs:text-base sm:leading-5`}
      >
        <Listbox.Button className="py-2 px-6 w-full flex items-center justify-between hover:bg-dark-gray">
          <div>{buttonText}</div>

          <FontAwesomeIcon icon={['far', 'angle-down']} />
        </Listbox.Button>

        <Listbox.Options className="bg-dark-gray absolute z-10 w-full rounded-b-md">{children}</Listbox.Options>
      </div>
    </Listbox>
  );
};
