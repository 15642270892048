import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../components/Loading';

export default function ListBox({ children }) {
  const { color, headerIcon, headerSubtext, title, submit, subtext, loading } =
    children;
  return (
    <div
      className={classNames(
        `h-full p-4 md:p-8 rounded-lg ${color} bg-opacity-10`,
      )}
    >
      {loading ? (
        <Loading containerClass="pt-20" />
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div
              className={classNames(
                `w-12 h-12 ${color} rounded-full text-white flex items-center justify-center`,
              )}
            >
              {headerIcon}
            </div>
            <div
              className={`flex items-center text-sm cursor-pointer ${color} text-white px-4 py-2.5 pt-3 rounded-3xl hover:bg-burnt-yellow`}
              onClick={submit.handler}
            >
              {headerSubtext}
              <FontAwesomeIcon
                icon="plus"
                className="text-xs font-avenir-book pb-0.5 ml-2"
              />
            </div>
          </div>
          <div className="font-avenir-black text-navy-blue text-lg md:text-xl mt-5">
            {title}
          </div>
          <div className="font-avenir-book text-sm md:text-base mt-3 text-opaque-navy">
            {subtext}
          </div>
          <div className="flex items-center mt-2 md:mt-4">
            <div className="font-avenir-book text-opaque-navy mr-2 mt-2 text-sm">
              {submit.label}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
