import AccountDetails from './AccountDetails';
import ActiveOrdersTable from './table/ActiveOrdersTable';
import CompanyOverview from './CompanyOverview';
import Loading from '../../shared/components/Loading';
import PrimaryPaymentMethod from './PrimaryPaymentMethod';
import PaymentHistoryExport from './PaymentHistoryExport';
import useEntity from '../../../hooks/useEntity';
import useOrders from '../../../hooks/useOrders';
import WidgetRow from './widgets/WidgetRow';
import { BaseGrid, BaseGridItem } from '../../shared/layout/BaseGrid';
import Promo from './widgets/Promo';
import Notification from '../../shared/components/Notification';

export default function MainDashboard() {
  const [entity] = useEntity();
  const { orders, ordersLoading } = useOrders();

  const getPrimaryWidget = () => {
    if (!entity)
      return (
        <div className="md:row-span-2 md:col-span-2 h-full flex flex-col rounded-lg border border-black border-opacity-5 p-4 md:p-8">
          <Loading />
        </div>
      );

    return entity?.type === 'company' ? (
      <CompanyOverview />
    ) : (
      <AccountDetails />
    );
  };

  if (!entity) return <Loading className="w-14 h-14" containerClass="my-16" />;

  return (
    <div className="pb-6 md:pb-9">
      <Promo />
      <Notification
        icon={['far', 'megaphone']}
        headline="Important Update: New Customer Support Hours!"
        message={
          <p>
            Our support team is now available Monday to Friday from 9:00 AM - 1:00 PM & 2:00 PM - 6:00 PM CST.
          </p>
        }
      />
      <BaseGrid>
        <BaseGridItem>{getPrimaryWidget()}</BaseGridItem>

        <BaseGridItem>
          <PrimaryPaymentMethod />
        </BaseGridItem>

        <WidgetRow />

        <BaseGridItem>
          <ActiveOrdersTable orders={orders} ordersLoading={ordersLoading} />
        </BaseGridItem>

        <BaseGridItem>
          <PaymentHistoryExport />
        </BaseGridItem>
      </BaseGrid>
    </div>
  );
}
