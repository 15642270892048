import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useModal from '../../../../../hooks/useModal';
import DeleteUserModal from '../../../../modals/DeleteUserModal';

export default function DeleteUserButton({ user }) {
  const { isModalActive, toggleModal } = useModal();

  return (
    <>
      <DeleteUserModal
        isModalActive={isModalActive}
        toggleModal={toggleModal}
        user={user}
      />
      <div
        className="flex items-center cursor-pointer"
        onClick={() => toggleModal()}
      >
        <span className="mr-2">Remove</span>
        <FontAwesomeIcon
          icon="times"
          className="font-avenir-black text-xl mb-0.5"
        />
      </div>
    </>
  );
}
