import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTable, useSortBy } from 'react-table';

import AddUserForm from '../AddUserForm';
import BaseTable from '../../../../shared/BaseTable';
import Loading from '../../../../shared/components/Loading';
import { UserTableColumns } from './UserTableColumns';

export default function UsersTable({ company, companyUsers }) {
  const [open, setOpen] = useState(false);

  const data = useMemo(() => companyUsers && companyUsers, [companyUsers]);
  const columns = useMemo(() => UserTableColumns, []);
  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: 'role', desc: false }],
      },
    },
    useSortBy,
  );

  if (!companyUsers || !company)
    return <Loading className="mt-6" containerClass="col-span-full" />;

  return (
    <div
      role={UsersTable.role}
      className="col-span-full rounded-lg py-1 overflow-x-scroll"
    >
      <BaseTable
        tableInstance={instance}
        noDataFoundWarning="You do not currently have any additional users."
      />
      <div className="my-6">
        <div
          className="inline-flex items-center gap-x-1 text-positive-green font-avenir-medium mx-6 cursor-pointer hover:text-green-700"
          onClick={() => setOpen(!open)}
        >
          <FontAwesomeIcon icon="plus" className="mr-1 mb-1" />
          Add New Purchaser
        </div>

        {open && (
          <div className="flex-auto overflow-hidden border-t border-gray-200 text-sm leading-6 py-4">
            <div className="p-4">
              <AddUserForm company={company} setOpen={setOpen} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

UsersTable.role = 'users-table-component';
