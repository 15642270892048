import Session from 'supertokens-auth-react/recipe/session';

export function getApiDomain() {
  return (
    process.env.REACT_APP_SUPERTOKENS_API_DOMAIN || 'http://localhost:54320'
  );
}

export function getWebsiteDomain() {
  return (
    process.env.REACT_APP_SUPERTOKENS_WEBSITE_DOMAIN || 'http://localhost:54321'
  );
}

export const SuperTokensConfig = {
  appInfo: {
    appName: 'Credit Key Borrower Portal',
    apiDomain: getApiDomain(),
    websiteDomain: getWebsiteDomain(),
    websiteBasePath: '/',
  },
  recipeList: [
    Session.init({
      sessionTokenBackendDomain: '.creditkey.localhost',
      sessionTokenFrontendDomain: '.creditkey.localhost',
    }),
  ],
};
