import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useCompany from '../../../hooks/useCompany';
import useDefaultTerms from '../../../hooks/useDefaultTerms';
import { formatCurrency } from '../../../lib/utils/formatters';

import Card from '../../shared/layout/Card';
import Tooltip from '../../shared/components/Tooltip';
import DefaultTermSettings from './DefaultTermSettings';

export default function CompanyOverview() {
  const isLoading = useSelector((state) => state.general.get('loading'));
  const [company, companyActions] = useCompany();
  const { defaultTermSettings } = useDefaultTerms(company);

  const remainingCredit =
    company.credit_line.remaining_amount < 0
      ? 0
      : company.credit_line.remaining_amount;

  let statusIcon = '';
  let approvedUser = company?.underwriting_status === 'approved';

  switch (company?.underwriting_status) {
    case 'pending':
    case 'hold':
      statusIcon = 'warning-yellow';
      break;
    case 'approved':
      statusIcon = 'positive-green';
      break;
    case 'declined':
      statusIcon = 'negative-red';
      break;
    default:
      statusIcon = 'primary-blue';
  }

  // this widget is only for Company data
  if (!company) return null;

  return (
    <div className="md:row-span-2 md:col-span-2">
      <Card>
        {!isLoading && {
          header: {
            title: company?.name,
            subtitle: company?.underwriting_status,
            subtitle_color: statusIcon,
          },
          content: (
            <>
              {/* <!-- Credits --> */}
              <div className="grid grid-cols-1 xs:grid-cols-3 md:grid-cols-1 lg:grid-cols-2 mxl:grid-cols-3 text-navy-blue">
                {/* <!-- Approved credit--> */}
                <div className="md:my-2">
                  <div className="font-avenir-black text-navy-blue text-xl md:text-2xl">
                    {!approvedUser
                      ? '--'
                      : formatCurrency(company.credit_line.amount)}
                  </div>
                  <div className="flex items-center mt-1">
                    <div className="mr-1 font-avenir-book text-opaque-navy text-sm">
                      <span>Maximum Credit</span>
                    </div>
                  </div>
                </div>
                {/* <!-- Credit in Use --> */}
                <div className="md:my-2">
                  <div className="font-avenir-black text-xl md:text-2xl text-opaque-navy">
                    {!approvedUser
                      ? '--'
                      : formatCurrency(company.credit_line.total_used)}
                  </div>
                  <div className="flex items-center mt-1">
                    <div className="mr-1 font-avenir-book text-opaque-navy text-sm">
                      <span>Credit in Use</span>
                    </div>
                  </div>
                </div>
                {/* <!-- Remaining credit--> */}
                <div className="md:my-2">
                  <div className="font-avenir-black text-xl md:text-2xl text-positive-green">
                    {!approvedUser ? '--' : formatCurrency(remainingCredit)}
                  </div>
                  <div className="flex items-center mt-1">
                    <div className="mr-1 font-avenir-book text-opaque-navy text-sm">
                      <span>Remaining Credit</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Rate --> */}
              <div className="py-3 -mx-4 md:-mx-8 mt-3 md:mt-6 border-t border-b border-black border-opacity-5 ">
                <div className="flex justify-between px-4 md:px-8">
                  <div className="flex items-center mt-1">
                    <div className="font-avenir-book text-opaque-navy text-sm flex items-center align-center">
                      <span className="mr-1 text-sm">Base Rate</span>
                      {company.min_rate !== 'N/A' && approvedUser && (
                        <Tooltip
                          tooltip_text={
                            <div>
                              Rate may range from
                              <p className="mx-1 text-primary-blue font-avenir-black">
                                {company.min_rate} - {company.max_rate}%
                              </p>
                              based on term length selection
                            </div>
                          }
                          trigger_class="hover:text-primary-blue cursor-pointer"
                          container_class="text-center p-4 px-6 bg-dark-gray"
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'circle-info']}
                            className="text-xxs"
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="font-avenir-medium text-sm text-navy-blue md:text-base">
                    {company.min_rate !== 'N/A' && approvedUser
                      ? `${company.min_rate}%`
                      : '--'}
                  </div>
                </div>
              </div>
              <div className="bg-primary-blue bg-secondary-purple bg-warning-yellow"></div>
            </>
          ),
          footer: {
            title: !defaultTermSettings.length ? null : <p>Default Terms</p>,
            content: (
              <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                {defaultTermSettings && (
                  <DefaultTermSettings
                    defaultTermSettings={defaultTermSettings}
                    company={company}
                    companyActions={companyActions}
                  />
                )}
              </div>
            ),
          },
        }}
      </Card>
    </div>
  );
}
