import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import classNames from 'classnames';

import Container from './Container';
import Loading from '../shared/components/Loading';
import PossessUser from './PossessUser';
import SideNav from './SideNav';
import MobileNav from './MobileNav';
import useCompany from '../../hooks/useCompany';
import useConsumerAccount from '../../hooks/useConsumerAccount';
import useCurrentUser from '../../hooks/useCurrentUser';
import useEntity from '../../hooks/useEntity';
import useErrorHandling from '../../hooks/useErrorHandling';
import useLoggedInUser from '../../hooks/useLoggedInUser';
import useVirtualCard from '../../hooks/useVirtualCard';
import { fromISO } from '../../lib/utils/datetime_formatters';
import { platform, supportLink } from '../../lib/constants/SiteVariables';
import { useAuth } from '../../hooks/useAuth';

const primaryRoutes = [
  {
    icon: ['fas', 'house-blank'],
    name: 'Home',
    url: '/',
  },
  {
    icon: ['fal', 'cart-shopping'],
    name: 'Orders',
    url: '/orders',
  },
  {
    icon: ['fal', 'money-bill-transfer'],
    name: 'Payment Methods',
    url: '/payment-methods',
  },
  {
    icon: ['fal', 'user'],
    name: 'My Profile',
    url: '/profile',
  },
  {
    icon: ['fal', 'circle-question'],
    name: 'Help Center',
    url: supportLink,
    target: '_blank',
  },
];

export default function PortalLayout({ children }) {
  const auth = useAuth();
  const [account] = useConsumerAccount();
  const [company] = useCompany();
  const [curUser] = useCurrentUser();
  const [loggedInUser] = useLoggedInUser();
  const [entity, entityActions] = useEntity();
  const [vcMerchant, vcActions] = useVirtualCard();
  const isAdminUser = loggedInUser?.is_admin_user;
  const isBorrowerLoggedIn = !isAdminUser && curUser;
  const isAdminPossessingAUser =
    isAdminUser && curUser && loggedInUser.id !== curUser.id;

  const orders = useSelector((state) => state.entities.get('order')).filter(
    (i) => [company?.id, account?.id].includes(i.get('entity_id')),
  );

  useErrorHandling();

  useEffect(() => {
    if (platform() !== 'development') {
      window.ckLoadZendeskWidget();
    }
  }, []);

  useEffect(() => {
    if (platform() === 'development') return;
    if (
      entity &&
      orders.size > 0 &&
      entity.type === 'company' &&
      entity.underwriting_status === 'approved'
    ) {
      window.delighted.survey({
        email: entity.borrower.email,
        name: entity.borrower.full_name,
        createdAt: fromISO(entity.latest_approval_date)
          .toUTC()
          .toISO({ suppressMilliseconds: true }),
        properties: {
          ckID: entity.id,
          company: entity.name,
          transactions: orders.size,
        },
      });
    }
  }, [entity, orders]);

  useEffect(() => {
    if (!curUser) return;

    entityActions.loadForUser(curUser);

    if (!vcMerchant) {
      vcActions.loadVirtualCardMerchant();
    }
  }, [curUser, entityActions]);

  return (
    <div
      className={classNames('h-screen font-avenir-book', {
        'md:grid md:grid-cols-13': loggedInUser,
      })}
      role={PortalLayout.role}
    >
      {loggedInUser && <SideNav primaryRoutes={primaryRoutes} />}
      {auth?.user && !curUser ? (
        <Loading containerClass="my-16" />
      ) : (
        <Container
          utilClasses={classNames(
            !loggedInUser
              ? 'w-full'
              : 'grid grid-rows-auto-1fr md:pt-10 md:container',
          )}
        >
          <Toaster
            position="top-right"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
              duration: 6000,
              style: {
                boxShadow: 'none',
                minWidth: '20%',
                background: 'none',
              },
            }}
          />
          {loggedInUser && <MobileNav primaryRoutes={primaryRoutes} />}
          {isAdminUser && <PossessUser />}
          <div>
            {(isAdminPossessingAUser || isBorrowerLoggedIn || !loggedInUser) &&
              children}
          </div>
        </Container>
      )}
    </div>
  );
}

PortalLayout.role = 'portal-layout-component';
