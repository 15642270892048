import React, { useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';

export default function UserTokenVerification({ onSubmit }) {
  const [token, setToken] = useState('');

  useEffect(() => {
    if (token.length === 6) {
      submitToken(token);
    }
  }, [token]);

  const submitToken = async (givenToken) => {
    onSubmit(givenToken);
    setToken('');
  };

  const verificationInputChanged = (value) => {
    setToken(value);
  };

  return (
    <div role="form">
      <VerificationInput
        autoFocus={true}
        debug={false}
        length={6}
        onChange={verificationInputChanged}
        placeholder="-"
        removeDefaultStyles
        validChars="0-9"
        value={token}
        classNames={{
          character: 'input border rounded-md mx-0.5 xxs:mx-2 p-3 3xs:p-4 flex justify-center items-center focus:border-primary-blue focus-within:border-primary-blue cursor-pointer text-base 3xs:text-lg font-avenir-black text-navy-blue',
          container: 'w-4/5 mx-auto 3xs:w-full tokens my-4 xxs:my-8 flex justify-center items-center h-16',
          characterInactive: 'text-dark-gray bg-white border-table-gray',
          characterSelected: 'border-primary-blue border-2',
        }}
      />
    </div>
  );
}