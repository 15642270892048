import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

import entities from './reducers/entities';
import general from './reducers/general';

let history;

export const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export const rootReducer = combineReducers({
  router: routerReducer,
  entities,
  general,
});

export const setupStore = (initialState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(routerMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
  });
};

export const createStore = (initialState = {}) => {
  const store = setupStore(initialState);
  history = createReduxHistory(store);

  if (module.hot && process.env.NODE_ENV !== 'production') {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept(rootReducer, () => {
      store.replaceReducer(rootReducer);
    });
  }

  return { store, history };
};

export { entitiesState } from './reducers/entities';
export { generalState } from './reducers/general';
