import { List, Record } from 'immutable';

import AddressRecord from './address';
import CreditLine from './credit_line';
import User from './user';
import { convertRawValues } from '../utils/record_utils';

export default class CompanyRecord extends Record({
  id: null,
  type: 'company',
  name: '',
  underwriting_status: 'unknown',
  bank_verification_attempts: 0,
  applicant_fico_score: '',
  quick_display: '',
  tier: '',
  ein: '',
  is_past_due: false,
  min_rate: '',
  max_rate: '',
  credit_line: new CreditLine(),
  is_bank_connected: '',
  is_bank_connection_pending: '',
  cta_banners: [],
  vc_sub_limits: {},
  latest_approval_date: '',
  address: new AddressRecord(),
  borrower: new User(),
  recharge_settings: List(),
  promo_id: '',
}) {
  constructor(data) {
    data = convertRawValues(data);

    if (data && data.get('address')) {
      data = data.set('address', new AddressRecord(data.get('address')));
    }

    if (data && data.get('credit_line')) {
      data = data.set('credit_line', new CreditLine(data.get('credit_line')));
    }

    if (data && data.get('borrower')) {
      data = data.set('borrower', new User(data.get('borrower')));
    }

    super(data);
  }
}
