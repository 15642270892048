import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const getIcon = (status) => {
  let scheduleIcon = '';
  let iconClass = '';

  switch (status) {
    case 'returned':
    case 'canceled':
    case 'error':
    case 'declined':
    case 'late':
      scheduleIcon = <FontAwesomeIcon icon="exclamation" />;
      iconClass = 'negative-red';
      break;

    case 'created':
    case 'pending':
    case 'authorized':
    case 'scheduled':
      scheduleIcon = <FontAwesomeIcon icon={['far', 'clock']} />;
      iconClass = 'warning-yellow';
      break;

    case 'paid':
      scheduleIcon = <FontAwesomeIcon icon={['far', 'dollar-sign']} />;
      iconClass = 'primary-blue';
      break;

    case 'refunded':
      scheduleIcon = <FontAwesomeIcon icon="arrow-rotate-left" />;
      iconClass = 'gray-500';
      break;

    case 'pending/late':
      scheduleIcon = <FontAwesomeIcon icon={['far', 'clock']} />;
      iconClass = 'negative-red';
      break;

    case 'partial/pending':
      scheduleIcon = <FontAwesomeIcon icon={['far', 'chart-pie-simple']} />;
      iconClass = 'warning-yellow';
      break;

    case 'partial':
    case 'partial/late':
      scheduleIcon = <FontAwesomeIcon icon={['far', 'chart-pie-simple']} />;
      iconClass = status.includes('late') ? 'negative-red' : 'primary-blue';
      break;

    case 'partial/pending/late':
      scheduleIcon = (
        <FontAwesomeIcon icon={['far', 'circle-exclamation-check']} />
      );
      iconClass = 'negative-red';
      break;

    default:
      scheduleIcon = <FontAwesomeIcon icon="question" />;
      iconClass = 'gray-500';
      break;
  }

  return { iconClass, scheduleIcon };
};
