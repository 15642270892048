import { useEffect, useState } from 'react';
import {
  getCompressedFeeSummary,
  calculateFeeTotals,
  getFeeTitle,
  getFeeBreakdownDisplay,
} from '../components/pages/order-detail/components/fee_display_helpers';

function useOrderFees(order) {
  const [feeSummary, setFeeSummary] = useState();
  const [feeDisplay, setFeeDisplay] = useState();
  const [compressedFeeSummary, setCompressedFeeSummary] = useState();

  useEffect(() => {
    if (order) {
      setFeeSummary(calculateFeeTotals(order));
    }
  }, [order]);

  useEffect(() => {
    if (feeSummary) {
      const { result, feeDisplaySettings } =
        getCompressedFeeSummary(feeSummary);

      setCompressedFeeSummary(result);
      setFeeDisplay(feeDisplaySettings);
    }
  }, [feeSummary]);

  return {
    compressedFeeSummary,
    feeDisplay,
    setFeeDisplay,
    getFeeTitle,
    getFeeBreakdownDisplay,
  };
}

export default useOrderFees;
