import React from 'react';
import { useDispatch } from 'react-redux';

import useCurrentUser from '../../../../hooks/useCurrentUser';
import useToast from '../../../../hooks/useToast';
import { asyncActions, loading } from '../../../../store/actions/general';
import {
  getPaymentMethods,
  updatePaymentMethod,
} from '../../../../store/actions/payment_methods';

export const DefaultButton = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [user] = useCurrentUser();
  const { makeToast } = useToast();

  const setDefaultPayment = async (payment_method) => {
    const encodedEmail = encodeURIComponent(user.email);
    try {
      dispatch(loading(true));
      dispatch(
        asyncActions(
          updatePaymentMethod(payment_method.set('is_default', true)),
          getPaymentMethods(`filter[email]=${encodedEmail}`),
        ),
      )
        .then(() =>
          makeToast(
            'New Default Payment Method Set!',
            'positive-green',
            'check-circle',
          ),
        )
        .finally(() => {
          loading(false);
        });
    } catch (err) {
      makeToast(
        'An error occurred. Please try again or contact our support team.',
        'negative-red',
        ['far', 'octagon-xmark'],
      );
      dispatch(loading(false));
    }
  };

  return (
    <button
      className="dropdown-item text-sm py-2 px-4 font-avenir-medium block w-full whitespace-nowrap bg-transparent text-primary-blue hover:bg-primary-blue hover:bg-opacity-20 text-left"
      onClick={() => setDefaultPayment(props.payment_method)}
      role={DefaultButton.role}
      ref={ref}
    >
      Set As Default
    </button>
  );
});

DefaultButton.role = 'default-button';
