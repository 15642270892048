import React from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useModal from '../../../hooks/useModal';
import useToast from '../../../hooks/useToast';
import { DefaultTermSettingsModal } from '../../modals';
import { loading } from '../../../store/actions/general';

export default function DefaultTermSettings({
  company,
  companyActions,
  defaultTermSettings,
}) {
  const dispatch = useDispatch();
  const { makeToast } = useToast();
  const { toggleModal, isModalActive, modalState, setModalState } = useModal(
    [],
    defaultTermSettings[0],
  );

  const updateRechargeTerm = (merchant_id, term) => {
    return companyActions.update(
      company.set(
        'recharge_settings',
        company?.recharge_settings.update(
          company?.recharge_settings.findIndex(
            (i) => i.get('merchant_id') === merchant_id,
          ),
          (item) =>
            item
              .set('default_term', term?.term)
              .set('default_term_period', term?.term_period),
        ),
      ),
    );
  };

  const getTermDisplay = (term) => {
    if (term.default_term.term_period === 'days') return '30 Days';

    if (term.default_term?.term > 1) {
      return `${term.default_term?.term} Months`;
    } else {
      return `${term.default_term?.term} Month`;
    }
  };

  const onSave = (merchant_id, term) => {
    dispatch(loading(true));
    updateRechargeTerm(merchant_id, term)
      .then(() =>
        makeToast(
          'Recharge Settings Successfully Updated',
          'positive-green',
          'check-circle',
        ),
      )
      .catch(() =>
        makeToast(
          'An error occurred. Please contact Credit Key Support',
          'negative-red',
          ['far', 'octagon-xmark'],
        ),
      )
      .finally(() => dispatch(loading(false)));
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      {defaultTermSettings && (
        <DefaultTermSettingsModal
          modalState={modalState}
          onSave={onSave}
          isModalActive={isModalActive}
          toggleModal={toggleModal}
        />
      )}
      {defaultTermSettings.map((s, idx) => {
        return (
          <div
            key={idx}
            className="flex flex-col xs:flex-row md:flex-col xl:flex-row gap-2 justify-between items-start xs:items-center md:items-start xl:items-center"
          >
            <p className="font-avenir-book text-opaque-navy text-sm pr-1">
              Merchant Name:
              <strong className="ml-1">{s.merchant_name}</strong>
            </p>
            <div className="flex items-center">
              <p className="font-avenir-book text-opaque-navy text-sm pr-1">
                Default Term:
                <strong className="ml-1">{getTermDisplay(s)}</strong>
                {company?.tier !== 'NETX' && (
                  <FontAwesomeIcon
                    className="ml-2 text-primary-blue cursor-pointer"
                    icon={['far', 'edit']}
                    onClick={() => {
                      setModalState(s);
                      toggleModal();
                    }}
                  />
                )}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

DefaultTermSettings.role = 'default-term-settings-component';
