import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import useErrorHandling from '../../hooks/useErrorHandling';
import usePossessedUser from '../../hooks/usePossessedUser';
import { useAuth } from '../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CTAButton from '../shared/CTAButton';

export default function PossessUser() {
  const location = useLocation();
  const userEmailRef = useRef();
  const params = new URLSearchParams(location.search);
  const urlUserId = params.get('login_as');
  const auth = useAuth();
  const [possessedUser, userActions, userLoading] = usePossessedUser();

  const isAdminUser = auth?.user?.is_admin_user;
  const clearUser = () => {
    userEmailRef.current.value = '';
    userActions.clearLoggedInAsUser();
  };
  const loadUser = () => userActions.logInAsUser(userEmailRef.current.value);

  useEffect(() => {
    if (urlUserId) {
      userActions.logInAsUser(urlUserId);
    }
  }, [urlUserId]);

  useEffect(() => {
    if (possessedUser) {
      userEmailRef.current.value = possessedUser.email;
    }
  }, [possessedUser]);

  useErrorHandling();

  if (!isAdminUser) return null;

  return (
    <div role={PossessUser.role}>
      <div className="rounded-md bg-warning-yellow bg-opacity-30 p-4 w-full">
        <div className="flex">
          <div className="ml-3">
            {possessedUser ? (
              <>
                <h3 className="text-lg font-avenir-medium text-yellow-800">
                  Possessing User:
                </h3>
                <div className="mt-2 text-yellow-700">
                  <p>Name: {possessedUser?.full_name}</p>
                  <p>Email: {possessedUser?.email}</p>
                </div>
              </>
            ) : (
              <div>
                <p className="uppercase text-lg tracking-widest font-avenir-medium">
                  Waiting...
                </p>
                <p className="text-base">Please input a user email below.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="my-6 mt-8">
        <div className="flex justify-between border-b border-dark-gray pb-8">
          <div className="w-1/3">
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FontAwesomeIcon icon={['far', 'envelope']} />
              </div>
              <input
                type="email"
                name="email"
                id="email"
                className="w-full focus:ring-indigo-500 focus:border-indigo-500 block pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Borrower User Email"
                ref={userEmailRef}
                disabled={userLoading}
              />
            </div>
          </div>
          <div className="flex ">
            <CTAButton
              buttonText="View User Dashboard"
              buttonClass="px-6 text-white bg-primary-blue"
              containerClass="mx-2"
              onClick={loadUser}
              isLoading={userLoading}
            />
            <CTAButton
              buttonText="Clear"
              buttonClass="px-6 text-opaque-navy bg-dark-gray hover:text-white hover:bg-gray-500"
              containerClass="mx-2"
              onClick={clearUser}
            />
          </div>
        </div>
      </div>

      {!possessedUser && (
        <div className="my-6">
          <div className="my-3 text-primary-blue font-avenir-medium">
            Input user email above to load their borrower dashboard...
          </div>
        </div>
      )}
    </div>
  );
}

PossessUser.role = 'possess-user-component';
