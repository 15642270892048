import React from 'react';
import { DateTime } from 'luxon';
import { startCase } from 'lodash';

import { fromISO } from '../../../../lib/utils/datetime_formatters';
import { formatCurrency } from '../../../../lib/utils/formatters';
import { getIcon } from '../status_helper';

export const ScheduleTimeline = ({ order }) => {
  return order.product.payment_schedule.map((ps, idx) => {
    
    const { iconClass, scheduleIcon } = getIcon(ps.get('status'));

    return (
      <li className="mb-8 xs:mb-16 ml-8" key={idx}>
        <div className="flex justify-between items-start">
          <div className="flex justify-center items-start">
            <span
              className={`flex absolute -left-3.5 justify-center items-center w-7 h-7 bg-${iconClass} rounded-full ring-8 ring-white`}
            >
              <div className="text-white font-avenir-black text-sm mt-1">
                {scheduleIcon}
              </div>
            </span>
            <div className="flex flex-col">
              <h3 className="flex items-center text-base text-navy-blue">
                {startCase(ps.get('status'))}:
                <span className="font-avenir-black text-navy-blue ml-2">
                  {fromISO(ps.get('date')).toLocaleString(DateTime.DATE_MED)}
                </span>
              </h3>
            </div>
          </div>
          <div className="text-base">
            <span className="font-avenir-black text-navy-blue ml-1">
              {formatCurrency(ps.get('amount'))}
            </span>
          </div>
        </div>
      </li>
    );
  });
};

export const NoScheduleTimeline = () => {
  return (
    //TODO: ADD TOOLTIP
    //"If your order is still pending, &#10;your payments will be scheduled &#10;when your order is shipped.&#10;"
    <li className="ml-8">
      <div className="flex justify-between items-start">
        <div className="flex justify-center items-center">
          <span
            className={`flex absolute -left-3.5 justify-center items-center w-7 h-7 bg-gray-700 rounded-full ring-8 ring-white`}
          />
          <h3 className="flex items-center text-base">No Upcoming Payments*</h3>
        </div>
      </div>
    </li>
  );
};
