import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';

import GlobalFilter from '../../../shared/GlobalFilter';

const statusTabs = [
  {
    name: 'all',
    icon: ['far', 'cart-shopping'],
    filter_value: '',
  },
  {
    name: 'active',
    icon: ['far', 'file-invoice-dollar'],
    filter_value: ['funded', 'active'],
  },
  {
    name: 'pending',
    icon: ['far', 'hourglass-clock'],
    filter_value: ['created', 'pending'],
  },
  {
    name: 'paid',
    icon: 'check-circle',
    filter_value: 'paid',
  },
  {
    name: 'canceled',
    icon: ['fas', 'trash-can-xmark'],
    filter_value: ['canceled', 'void'],
  },
];

export default function TableHeader({ tableInstance, active, setActive }) {
  const {
    state: { globalFilter },
    setGlobalFilter,
    setFilter,
  } = tableInstance;

  return (
    <div className="m-4">
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-col md:flex-row justify-between md:items-center -mb-px text-sm font-avenir-book text-center text-navy-blue">
          <div className="flex justify-between md:justify-start overflow-x-scroll">
            {statusTabs.map((s, idx) => {
              return (
                <li
                  className="mr-2 cursor-pointer"
                  key={idx}
                  onClick={() => {
                    setActive(s.name);
                    setFilter('product.status', s.filter_value);
                  }}
                >
                  <div
                    className={classNames(
                      'inline-flex p-4 rounded-t-lg border-b-2 border-transparent',
                      active === s.name
                        ? 'text-primary-blue border-primary-blue font-avenir-medium'
                        : 'hover:text-opaque-navy hover:border-opaque-navy',
                    )}
                  >
                    <FontAwesomeIcon
                      className="mr-2 mb-0.5 text-base"
                      icon={s.icon}
                    />
                    <span>{startCase(s.name)}</span>
                  </div>
                </li>
              );
            })}
          </div>
          <div className="mt-4 md:mt-0">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              defaultPlaceholder="Search Orders"
            />
          </div>
        </ul>
      </div>
    </div>
  );
}
