import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ApprovedGoods() {
  const Approved = () => {
    let items = [
      'Business equipment',
      'Business goods/supplies',
      'Business services',
    ];
    return items.map((item, idx) => {
      return (
        <li key={idx} className="flex items-center">
          <span className="fa-li text-sm text-positive-green mr-2 ">
            <FontAwesomeIcon icon="check" />
          </span>
          <span className="text-sm">{item}</span>
        </li>
      );
    });
  };

  const Restricted = () => {
    let items = [
      'Personal use',
      'Monthly/recurring charges',
      'THC / Alcohol / Tobacco / Firearms',
    ];

    return items.map((item, idx) => {
      return (
        <li key={idx} className="flex items-center">
          <span className="fa-li text-sm text-negative-red mr-2 ">
            <FontAwesomeIcon icon="ban" />
          </span>
          <span className="text-sm">{item}</span>
        </li>
      );
    });
  };

  return (
    <div className="flex justify-between my-6" role={ApprovedGoods.role}>
      <div className="flex flex-col w-1/2">
        <div
          className="fa-ul"
          style={{ marginLeft: 'var(--fa-li-margin, 1.5em)' }}
        >
          <Approved />
        </div>
      </div>
      <div className="flex flex-col w-1/2">
        <div
          className="fa-ul"
          style={{ marginLeft: 'var(--fa-li-margin, 1.5em)' }}
        >
          <Restricted />
        </div>
      </div>
    </div>
  );
}

ApprovedGoods.role = 'approved-goods-component';
