import React from 'react';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import {
  formatCurrency,
  formatPercent,
} from '../../../../lib/utils/formatters';
import { fromISO } from '../../../../lib/utils/datetime_formatters';
import OrderStatus from '../../../order_table/OrderStatus';
import Tooltip from '../../../shared/components/Tooltip';
import usePaymentMethods from '../../../../hooks/usePaymentMethods';

export default function OrderStats({ order }) {
  const { defaultBankLabel, defaultCardLabel } = usePaymentMethods();

  const stats = [
    {
      name: 'Status',
      stat: <OrderStatus order={order} isTable={true} />,
      className: 'col-span-2',
    },
    {
      name: 'Order Amount',
      stat: formatCurrency(order.net_amount),
      className: 'col-span-2 mxl:col-span-1',
    },
    {
      name: `Interest (${
        order?.product_type === 'mambu_loan'
          ? `${order.product.monthly_interest}%`
          : formatPercent(order.product.interest_fee_percent * 100)
      })`,
      stat: formatCurrency(order.expected_interest),
      className: 'col-span-2 mxl:col-span-1',
    },
    {
      name: 'Additional Fees',
      stat: (
        <Tooltip
          tooltip_text="This notates fees processed at the time of the order. Any additional fees can be found in the fee breakdown below."
          trigger_class="cursor-pointer flex items-start max-w-max"
          container_class="text-center p-4 px-6 bg-dark-gray text-sm w-1/3"
          placement="bottom-end"
        >
          <span className="border-b border-dotted border-black">
            {formatCurrency(order.processing_fee_amount)}
          </span>
          <span className="text-sm ml-0.5">*</span>
        </Tooltip>
      ),
      className: 'col-span-3 mxl:col-span-1',
    },
    {
      name: 'Loan Amount',
      stat: formatCurrency(
        parseFloat(order.net_amount) +
          parseFloat(order.expected_interest) +
          parseFloat(order.processing_fee_amount),
      ),
      className: 'col-span-3 mxl:col-span-1',
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between my-3 mx-2">
        <div className="flex items-center">
          <p className="text-opaque-navy">Order Date:</p>
          <p className="font-avenir-medium ml-2 text-navy-blue">
            {fromISO(order.created_at).toLocaleString(DateTime.DATE_SHORT)}
          </p>
        </div>
        <div className="flex items-center">
          <p className="text-opaque-navy">Paying From:</p>
          <p className="font-avenir-medium ml-2 text-navy-blue">
            {order.entity_type !== 'account'
              ? defaultBankLabel ||
                'Please connect a bank account to pay for this order'
              : defaultCardLabel ||
                'Please connect a credit/debit card to pay for this order'}
          </p>
        </div>
      </div>
      <dl className="mb-6 grid grid-cols-1 gap-5 sm:grid-cols-6">
        {stats.map((item) => (
          <div
            key={item.name}
            className={classnames(
              'overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6',
              item.className,
            )}
          >
            <dt className="truncate text-base text-gray-900">{item.name}</dt>
            <dd className="mt-1 text-2xl font-avenir-medium tracking-tight text-navy-blue">
              {item.stat}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
