import React from 'react';
import { Link } from 'react-router-dom';

import useModal from '../../../../hooks/useModal';
import usePaymentMethods from '../../../../hooks/usePaymentMethods';
import { PaymentModalWrapper } from '../../../modals';
import { formatCurrency } from '../../../../lib/utils/formatters';

export default function PaymentProgress({ order }) {
  const { payment_methods } = usePaymentMethods();
  const { isModalActive, setIsModalActive, toggleModal } = useModal();

  const active = ['active', 'funded'].includes(order?.product.status);
  let progressColor;

  if (order?.product.isPastDue) {
    progressColor = 'negative-red';
  } else if (active && order?.progress !== 100) {
    progressColor = 'positive-green';
  } else {
    progressColor = 'gray-500';
  }

  return (
    <div className="my-4 box" role={PaymentProgress.role}>
      <PaymentModalWrapper
        order={order}
        isModalActive={isModalActive}
        setIsModalActive={setIsModalActive}
        toggleModal={toggleModal}
      />
      <div className="mx-auto">
        <div className="p-6 rounded-lg bg-positive-green bg-opacity-10 sm:p-10">
          <div className="flex flex-col justify-between">
            <div className="flex items-center justify-between flex-wrap order-last sm:order-first">
              <p className="truncate text-xl flex items-center">
                <span className="font-avenir-black mr-2 hidden sm:inline text-navy-blue">
                  Payment Summary
                </span>
              </p>
              {active &&
                order.product.next_payment_due_amount &&
                (payment_methods.size === 0 ? (
                  <div className="mt-2 flex-shrink-0 w-full sm:mt-0 sm:w-auto">
                    <Link
                      className="px-5 py-2 border border-transparent rounded-full shadow-sm font-avenir-medium text-base text-white bg-negative-red"
                      to="/payment-methods"
                    >
                      Connect A Bank
                    </Link>
                  </div>
                ) : (
                  <div className="mt-2 flex-shrink-0 w-full sm:mt-0 sm:w-auto">
                    <button
                      className="px-8 sm:px-5 pt-2 pb-1.5 border border-transparent rounded-full shadow-sm font-avenir-medium text-sm sm:text-base text-white bg-primary-blue hover:bg-navy-blue"
                      onClick={toggleModal}
                    >
                      Make a Payment
                    </button>
                  </div>
                ))}
            </div>
            <div className="mb-3 sm:mb-0 sm:mt-10 flex justify-between items-center text-sm sm:text-base">
              <div className="w-1/4">
                <p className="text-opaque-navy">Total amount</p>
                <p className="font-avenir-black text-navy-blue text-base sm:text-xl mt-1.5">
                  {formatCurrency(order.net_amount)}
                </p>
              </div>
              <div className="w-full rounded-lg bg-gray-200 h-4 w-1/2">
                <div
                  className={`bg-${progressColor} h-4 rounded-lg`}
                  style={{ width: `${order.product.progress}%` }}
                ></div>
              </div>
              <div className="w-1/4 text-right mr-2">
                <p className="text-opaque-navy">Remaining balance</p>
                <p className="font-avenir-black text-navy-blue text-base sm:text-xl mt-1.5">
                  {formatCurrency(order.total_balance)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PaymentProgress.role = 'payment-progress-component';
