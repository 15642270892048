import request from './request';
import Session from 'supertokens-auth-react/recipe/session';
import { server, USE_SUPERTOKENS } from '../constants/SiteVariables';
import { pdGetUser } from '../constants/PersistentData';
import { assign } from 'lodash';

export async function addAuthorization(options) {
  if (USE_SUPERTOKENS) return _addAuthorizationFromSuperTokens(options);
  return _addAuthorizationFromPersistentData(options);
}

async function _addAuthorizationFromPersistentData(options) {
  const user = pdGetUser();

  if (user) {
    assign(options.headers, {
      Authorization: 'Bearer ' + user.token.access_token,
    });
  } else {
    delete options.headers.Authorization;
  }

  return options;
}

async function _addAuthorizationFromSuperTokens(options) {
  if (await Session.doesSessionExist()) {
    const accessToken = await Session.getAccessToken();
    assign(options.headers, { Authorization: 'Bearer ' + accessToken });
  } else {
    delete options.headers.Authorization;
  }

  return options;
}

/**
 * @function Network
 * @description Factory function to create a object that can send
 * requests to a specific resource on the server.
 * @param {string} resource The resource used for config
 */
const Network = (resource) => {
  let buildURL = ({ params, id, resource, namespace } = {}) => {
    let parameters = [server()];

    if (namespace)
      parameters = parameters.concat([namespace.replace(/\/$/, '')]);
    if (resource) parameters = parameters.concat([resource]);
    if (id) parameters = parameters.concat([id]);

    if (params) {
      return parameters.join('/') + params;
    }
    return parameters.join('/');
  };

  // Default options used for every request
  let defaultOptions = {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    // NOTE: Because our existing Rails API implementation needs to
    // allow "wildcard" hostnames we are unable to make HTTP requests
    // w/ any credentials whatsoever, i.e. our SuperTokens cookie,
    // hence we omit sending any.
    credentials: 'omit',
  };

  const buildOptions = async (options) => {
    const tempOptions = assign(defaultOptions, options);
    return assign(tempOptions, await addAuthorization(tempOptions));
  };

  const handleGlobalErrors = async (url, err) => {
    let resp = {};

    if (err instanceof Error) {
      resp = {
        error: err.toString(),
        source: 'runtime',
      };
    } else {
      const textResponses = [202, 204, 401, 403];
      resp = await err[textResponses.includes(err.status) ? 'text' : 'json']();
    }

    return {
      message: resp?.error || resp?.errors || err?.statusText,
      status: err?.status,
      source: resp?.source,
      requestedUrl: url,
    };
  };

  return {
    /**
     * @function post
     * @description Make a POST request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    post: async (path, body, options = {}) => {
      const requestOptions = await buildOptions(options);
      const req = request(
        buildURL(path),
        assign(requestOptions, {
          method: 'POST',
          body: JSON.stringify(body),
        }),
      );

      return new Promise((resolve, reject) => {
        req
          .then((res) => resolve(res))
          .catch((err) => reject(handleGlobalErrors(buildURL(path), err)));
      });
    },

    postFormData: async (path, body, options = {}) => {
      const requestOptions = await buildOptions(options);
      // Need to let the browser set this for multipart payloads
      delete requestOptions.headers['Content-Type'];

      const req = request(
        buildURL(path),
        assign(requestOptions, {
          method: 'POST',
          body: body,
        }),
      );

      return new Promise((resolve, reject) => {
        req
          .then((res) => resolve(res))
          .catch((err) => reject(handleGlobalErrors(buildURL(path), err)));
      });
    },

    /**
     * @function get
     * @description Make a GET request.
     * @param {string} path
     * @param {object} options
     * @returns {promise}
     */
    get: async (path, options = {}) => {
      const requestOptions = await buildOptions(options);
      const req = request(
        buildURL(path),
        assign(requestOptions, {
          method: 'GET',
          body: null,
        }),
      );

      return new Promise((resolve, reject) => {
        req
          .then((res) => resolve(res))
          .catch((err) => reject(handleGlobalErrors(buildURL(path), err)));
      });
    },

    /**
     * @function edit
     * @description Make a PUT request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    edit: async (path, body, options = {}) => {
      const requestOptions = await buildOptions(options);
      const req = request(
        buildURL(path),
        assign(requestOptions, {
          method: 'PUT',
          body: JSON.stringify(body),
        }),
      );

      return new Promise((resolve, reject) => {
        req
          .then((res) => resolve(res))
          .catch((err) => reject(handleGlobalErrors(buildURL(path), err)));
      });
    },

    /**
     * @function patch
     * @description Make a PATCH request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    patch: async (path, body, options = {}) => {
      const requestOptions = await buildOptions(options);
      const req = request(
        buildURL(path),
        assign(requestOptions, {
          method: 'PATCH',
          body: JSON.stringify(body),
        }),
      );

      return new Promise((resolve, reject) => {
        req
          .then((res) => resolve(res))
          .catch((err) => reject(handleGlobalErrors(buildURL(path), err)));
      });
    },

    /**
     * @function delete
     * @description Make a DELETE request.
     * @param {string} path
     * @param {object} options
     * @returns {promise}
     */
    delete: async (path, body, options = {}) => {
      const requestOptions = await buildOptions(options);
      const req = request(
        buildURL(path),
        assign(requestOptions, {
          method: 'DELETE',
          body: JSON.stringify(body),
        }),
      );

      return new Promise((resolve, reject) => {
        req
          .then((res) => resolve(res))
          .catch((err) => reject(handleGlobalErrors(buildURL(path), err)));
      });
    },

    ping: () => request(buildURL(), { method: 'GET' }),
  };
};

export default Network;
