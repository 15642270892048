import { Record } from 'immutable';
import { convertRawValues } from '../utils/record_utils';

export default class CreditKeyAnywhereRequestRecord extends Record({
  id: null,
  vendor_name: '',
  vendor_url: '',
  vendor_contact_first_name: '',
  vendor_contact_last_name: '',
  vendor_contact_email: '',
  vendor_contact_phone: '',
  invoice_amount: '0.00',
  order_id: '',
  description: '',
  confirmed_invoice_date: false,
  confirmed_only_business_purchases: false,
  invoice_document: null
}) {
  constructor(data) {
    data = convertRawValues(data);
    super(data);
  }
}
