import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPromoId } from '../../store/actions/general';

export default function Promo() {
  const dispatch = useDispatch();
  const { promo_id } = useParams();

  useEffect(() => {
    if (promo_id) {
      dispatch(setPromoId(promo_id));
    }
  }, []);

  return <Navigate to="/login" />;
}
