import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { asyncActions } from '../store/actions/general';
import useCurrentUser from '../hooks/useCurrentUser';
import { getAccount } from '../store/actions/accounts';

function useConsumerAccount() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [user] = useCurrentUser();
  const accounts = useSelector((state) => state.entities.get('account'));
  const account = accounts?.get(user?.account_id);

  const actions = {
    loadForUser: (user) => {
      if (loading || !user?.account_id || accounts.has(user?.account_id))
        return;

      setLoading(true);
      dispatch(asyncActions(getAccount(user?.account_id)))
       .then((res) => {
         return accounts.get(user?.account_id);
       })
       .finally(() => setLoading(false));
    },
  };

  return [account, actions];
}

export default useConsumerAccount;
