import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import useCompany from '../../../hooks/useCompany';
import useCurrentUser from '../../../hooks/useCurrentUser';
import usePromo from '../../../hooks/usePromo';
import { asyncActions } from '../../../store/actions/general';
import {
  buildRequestPayload,
  startVirtualCardOrder,
} from '../../../store/actions/ecomm';
import { VirtualCardForm } from '../../forms';
import { isNotANumber } from '../../../lib/utils/validations';
import { formatCurrency } from '../../../lib/utils/formatters';

export default function VirtualCardModal({ setModalState, nextScreen }) {
  const dispatch = useDispatch();
  const [user] = useCurrentUser();
  const [company] = useCompany();
  const promo = usePromo();
  const promoId = useSelector((state) => state.general.get('promo_id'));
  const activePromo = promo.getPromoFromMerchant(promoId);

  const onSubmit = async (values) => {
    await dispatch(
      asyncActions(startVirtualCardOrder(buildRequestPayload(values, user))),
    ).then((res) => {
      if (!res) return;

      setModalState(res);
      setTimeout(function () {
        return window.open(res.checkout_url);
      }, 1 * 1000);

      nextScreen();
    });
  };

  return (
    <div role={VirtualCardModal.role}>
      <Formik
        initialValues={{
          merchant_name: '',
          merchant_url: '',
          order_amount: '',
          promo_id: promoId,
          bypass: false,
        }}
        validate={async (values) => {
          const errors = {};

          if (!values.merchant_name) errors.merchant_name = '* Required';
          if (!values.merchant_url) errors.merchant_url = '* Required';
          if (!values.order_amount) errors.order_amount = '* Required';

          const amount = parseFloat(
            values.order_amount.replace(/[^.0-9]/g, ''),
          );
          if (amount === 0.0 || isNotANumber(amount)) {
            errors.order_amount = '* Amount must be greater than zero (0)';
          }

          if (amount > company.credit_line.remaining_amount) {
            errors.order_amount =
              '* Amount exceeds remaining credit limit. Please lower amount and try again.';
          }

          if (amount > company?.vc_sub_limits?.get('remaining_limit_amount')) {
            errors.order_amount =
              '* Amount exceeds remaining card limit. Please lower amount and try again.';
          }

          if (
            !values.bypass &&
            activePromo &&
            amount < activePromo.get('min_order_amount')
          ) {
            errors.order_amount = `You need to spend ${formatCurrency(
              activePromo.get('min_order_amount'),
            )} to qualify for the 0% for ${activePromo.get(
              'max_net_duration',
            )} day promotion`;
          }

          return errors;
        }}
        onSubmit={onSubmit}
      >
        {(props) => <VirtualCardForm {...props} activePromo={activePromo} />}
      </Formik>
    </div>
  );
}

VirtualCardModal.role = 'virtual-card-modal';
