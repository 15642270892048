import { Record } from 'immutable';

import { convertRawValues } from '../utils/record_utils';

export default class OrderDocumentRecord extends Record({
  id: null,
  created_at: '',
  file_format: '',
  file_type: '',
  order_id: '',
}) {
  constructor(data) {
    data = convertRawValues(data);
    super(data);
  }
}
