// ----------------------
// ENTITIES
// ----------------------
export const CLEAR_ENTITIES = "CLEAR_ENTITIES";
export const CLEAR_ENTITY = "CLEAR_ENTITY";
export const RECEIVE_ENTITIES = "RECEIVE_ENTITIES";
export const RECEIVE_ENTITY = "RECEIVE_ENTITY";
export const RESET_ENTITIES = "RESET_ENTITIES";

// ----------------------
// GENERAL
// ----------------------
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_DEBUG = "SET_DEBUG";
export const SET_ERROR = "SET_ERROR";
export const TOGGLE_RETHROW = "TOGGLE_RETHROW";
export const APP_LOADING = "APP_LOADING";
export const RESET_GENERAL = "RESET_GENERAL";
export const SET_POSSESSED_USER = "SET_POSSESSED_USER";
export const SET_PROMO_ID = "SET_PROMO_ID";

// ----------------------
// FORMS
// ----------------------
export const UPDATE_INPUT = "UPDATE_INPUT";
