import React, { useState } from 'react';
import { Formik } from 'formik';

import CTAButton from '../../shared/CTAButton';
import usePaymentMethods from '../../../hooks/usePaymentMethods';
import { PaymentModalBody } from '../../forms/';

export default function PaymentModal({
  order,
  setModalState,
  nextScreen,
  toggleModal,
  resetScreen,
  resetModalState,
}) {
  const { defaultCard, defaultBank } = usePaymentMethods();
  const [disabled, setDisabled] = useState(true);

  const defaultPayment =
    order.entity_type === 'account' ? defaultCard : defaultBank;

  const setConfirmModal = (values) => {
    setModalState(values);
    nextScreen();
  };

  return (
    <>
      <div className="modal-card mobile-modal">
        <section className="modal-card-body">
          <Formik
            initialValues={{
              amount: order.product.next_payment_due_amount.toString(),
              payment_method_id: defaultPayment?.get('id'),
              loan_id:
                order.product_type === 'mambu_loan' ? order.product.id : '',
              order_id: order.product_type !== 'mambu_loan' ? order.id : '',
            }}
            validate={async (values) => {
              const errors = {};
              if ([NaN, 0].includes(parseFloat(values.amount)))
                errors.amount = 'Value must be greater than zero (0)';
              if (!values.payment_method_id) {
                errors.payment_method_id = '* Payment Method Required';
              }
              return errors;
            }}
            onSubmit={async (values) => {
              setConfirmModal(values);
            }}
          >
            {(props) => (
              <>
                <PaymentModalBody
                  {...props}
                  order={order}
                  disabled={disabled}
                  toggleModal={toggleModal}
                  setDisabled={setDisabled}
                />
              </>
            )}
          </Formik>
        </section>
        <CTAButton
          buttonClass="bg-transparent shadow-none text-primary-blue hover:bg-transparent hover:text-opaque-navy"
          buttonText="Cancel"
          containerClass="justify-center"
          onClick={() => {
            toggleModal();
            resetScreen();
            resetModalState();
          }}
        />
      </div>
    </>
  );
}
