import React from 'react';
import { DateTime } from 'luxon';

import { fromISO } from '../../lib/utils/datetime_formatters';

export default function NextPayment({ order }) {
  let payment_date = 'N/A';
  let className;

  switch (order.product.status) {
    case 'funded':
    case 'active':
      payment_date = order.product.next_payment_date
        ? fromISO(order.product.next_payment_date).toLocaleString(DateTime.DATE_SHORT)
        : 'Paid: Final Payment Pending';
      className = order.product.next_payment_date && 'font-avenir-medium';
      break;
    case 'created':
    case 'pending':
      payment_date = 'Not Yet Scheduled';
      className = 'text-opaque-navy';
      break;
    case 'paid':
    case 'void':
      payment_date = 'Paid Off';
      className = 'text-opaque-navy';
      break;
    default:
      return payment_date;
  }

  return (
    <span role={NextPayment.role} className={className}>
      {payment_date}
    </span>
  );
}

NextPayment.role = 'next-payment-component';
