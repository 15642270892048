import React, { useEffect, useState } from 'react';
import { Listbox } from '@headlessui/react';

import CTAButton from '../shared/CTAButton';
import Loading from '../shared/components/Loading';
import { ListBox } from '../shared/components/Dropdown';
import { ModalWrapper } from '.';

export default function DefaultTermSettingsModal({
  onSave,
  modalState,
  isModalActive,
  toggleModal,
}) {
  const [term, setTerm] = useState(modalState?.default_term);

  const handleChange = (value) => {
    setTerm(value);
  };

  useEffect(() => {
    setTerm(modalState?.default_term);
  }, [modalState]);

  return (
    <ModalWrapper
      toggleModal={toggleModal}
      isModalActive={isModalActive}
      modalHeight="sm:max-h-recharge sm:min-h-recharge"
    >
      <div className="flex items-end justify-center px-4 text-center sm:block sm:px-0 max-w-md max-h-md mx-auto">
        {!modalState?.term_options ? (
          <Loading className="w-14 h-14" />
        ) : (
          <>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <div className="mx-6 text-center text-navy-blue">
                <p className="text-lg my-2 font-avenir-black">
                  Default Terms for {modalState?.merchant_name}
                </p>
                <p className="my-2">
                  Here you can change the default term length for future
                  <span className="text-primary-blue font-avenir-medium mx-1">
                    {modalState?.merchant_name}
                  </span>
                  in-store or replenishment purchases
                </p>
              </div>
              <div className="my-10 text-center">
                <h1 className="text-navy-blue">Adjust Number of Months</h1>
                <ListBox
                  initialValue={modalState?.default_term?.term}
                  buttonText={term?.term}
                  handleChange={handleChange}
                  listBoxClass="w-4/5 my-3 mx-auto rounded-md border border-dark-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5 relative"
                >
                  {modalState?.term_options?.map((op) => (
                    <Listbox.Option
                      key={op.term}
                      value={op}
                      className="cursor-pointer p-2 hover:bg-primary-blue hover:bg-opacity-70 hover:text-white last:hover:rounded-b-md"
                    >
                      {op.term}
                    </Listbox.Option>
                  ))}
                </ListBox>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="sm:mb-8 w-full m-auto px-4 py-3 sm:px-6 flex justify-center items-center">
        <CTAButton
          buttonClass="px-14 bg-primary-blue text-white"
          buttonText="Save"
          containerClass="mx-2"
          icon={['far', 'lock']}
          onClick={() => {
            onSave(modalState?.merchant_id, term);
            toggleModal();
          }}
        />
        <CTAButton
          ariaLabel="Close"
          buttonClass="px-14 bg-light-blue text-opaque-navy hover:bg-gray-500 hover:text-white"
          containerClass="mx-2"
          buttonText="Cancel"
          onClick={toggleModal}
        />
      </div>
    </ModalWrapper>
  );
}

DefaultTermSettingsModal.role = 'default-term-settings-modal';
