import React from 'react';
import { useSelector } from 'react-redux';

import Card from '../../shared/layout/Card';
import Loading from '../../shared/components/Loading';
import useConsumerAccount from '../../../hooks/useConsumerAccount';
import { formatCurrency } from '../../../lib/utils/formatters';

export default function AccountDetails() {
  const [account] = useConsumerAccount();
  const approvedUser = account?.status === 'approved';
  const isLoading = useSelector((state) => state.general.get('loading'));

  let statusIcon = '';

  switch (account?.status) {
    case 'pending':
    case 'hold':
      statusIcon = 'warning-yellow';
      break;
    case 'approved':
      statusIcon = 'positive-green';
      break;
    case 'declined':
    case 'canceled':
      statusIcon = 'negative-red';
      break;
    default:
      statusIcon = 'primary-blue';
  }

  if (!account) return null;

  if (isLoading) return <Loading />;

  return (
    <div className="md:row-span-2 md:col-span-2">
      <Card>
        {{
          header: {
            title: account?.name,
            subtitle: account?.status,
            subtitle_color: statusIcon,
          },
          content: (
            <>
              <div className="grid grid-cols-3 md:grid-cols-1 lg:grid-cols-2 mxl:grid-cols-3 text-navy-blue">
                <div className="md:my-2">
                  <div className="font-avenir-black text-navy-blue text-xl md:text-2xl">
                    {!approvedUser ? '--' : formatCurrency(account?.tier_limit)}
                  </div>
                  <div className="flex items-center mt-1">
                    <div className="mr-1 font-avenir-book text-opaque-navy text-sm">
                      <span>Maximum Purchase</span>
                    </div>
                  </div>
                </div>
                <div className="md:my-2">
                  <div className="font-avenir-black text-xl md:text-2xl text-opaque-navy">
                    {!approvedUser
                      ? '--'
                      : formatCurrency(account?.purchase_amount_in_use)}
                  </div>
                  <div className="flex items-center mt-1">
                    <div className="mr-1 font-avenir-book text-opaque-navy text-sm">
                      <span>Amount in Use</span>
                    </div>
                  </div>
                </div>
                <div className="md:my-2">
                  <div className="font-avenir-black text-xl md:text-2xl text-positive-green">
                    {!approvedUser
                      ? '--'
                      : formatCurrency(account?.tier_limit_available)}
                  </div>
                  <div className="flex items-center mt-1">
                    <div className="mr-1 font-avenir-book text-opaque-navy text-sm">
                      <span>Remaining Amount</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-3 -mx-4 md:-mx-8 mt-3 md:mt-6 border-t border-black border-opacity-5" />
              <div className="bg-primary-blue bg-secondary-purple bg-warning-yellow"></div>
            </>
          ),
          footer: {
            content: <div className="flex items-center justify-between" />,
          },
        }}
      </Card>
    </div>
  );
}
