import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from '../../shared/layout/Card';
import Loading from '../../shared/components/Loading';
import useCurrentUser from '../../../hooks/useCurrentUser';
import usePaymentMethods from '../../../hooks/usePaymentMethods';
import useEntity from '../../../hooks/useEntity';

export default function PrimaryPaymentMethod() {
  const navigate = useNavigate();
  const [user] = useCurrentUser();
  const [entity] = useEntity();
  const { payment_methods, defaultCard, defaultBank } = usePaymentMethods();
  const [defaultPM, setDefaultPM] = useState(undefined);

  const noData =
    user?.hasAccount === undefined ||
    user?.hasCompany === undefined ||
    !payment_methods ||
    !entity;

  useEffect(() => {
    if (entity && payment_methods) {
      entity?.type === 'company'
        ? setDefaultPM(defaultBank)
        : setDefaultPM(defaultCard);
    }
  }, [entity, payment_methods]);

  return (
    <div className="md:col-span-2 md:row-span-2">
      <Card>
        {{
          header: {
            type: noData
              ? ''
              : !entity?.is_bank_connected || !payment_methods.size
                ? 'bg-negative-red'
                : '',
            title: 'Primary Payment Method',
            action: 'View All',
            actionHandler: () => navigate('/payment-methods'),
          },
          content: noData ? (
            <Loading />
          ) : !entity?.is_bank_connected || payment_methods.size === 0 ? (
            <Link
              to="/payment-methods"
              className="flex items-center text-negative-red"
            >
              <FontAwesomeIcon
                icon={['far', 'exclamation-triangle']}
                className="mr-3 text-xl"
              />
              <p className="font-avenir-black mt-1">
                Please connect a payment method immediately!
              </p>
              <FontAwesomeIcon
                icon={['far', 'chevron-right']}
                className="text-xs font-avenir-black ml-3"
              />
            </Link>
          ) : !defaultPM ? (
            <Loading />
          ) : (
            <div className="flex justify-between mt-4 md:mt-7">
              <div className="flex justify-between">
                <div className="text-opaque-navy text-sm mr-3">
                  {defaultPM?.payment_method_label}
                </div>
                <div className="text-opaque-navy text-sm">
                  {defaultPM?.account_number_masked}
                </div>
              </div>

              <div className="text-positive-green font-avenir-medium text-sm capitalize">
                Default
              </div>
            </div>
          ),
        }}
      </Card>
    </div>
  );
}
