import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';

import CTAButton from './CTAButton';
import StickyInput from './StickyInput';
import { emailRegex } from '../../lib/constants/SiteVariables';

export default function UserVerification({
  defaultValue,
  onSubmit,
  buttonText = 'Next',
}) {
  const [valid, setValid] = useState(false);
  const isLoading = useSelector((state) => state.general.get('loading'));
  const [label, setLabel] = useState('Mobile Phone');

  function detect_input_type(value) {
    const phoneRegex = /^\d{0,4}$/;
    const emailRegex = /^[a-zA-Z0-9._-]{0,4}$/;

    const val = value.replace(/[\W]/g, '').slice(0, 4);

    if (val && phoneRegex.test(val)) {
      setLabel('Mobile Phone');
      return 'phone';
    } else if (val && emailRegex.test(val)) {
      setLabel('Email');
      return 'email';
    }

    return 'unknown';
  }

  return (
    <div role="form">
      <Formik
        initialValues={{
          contact: defaultValue,
        }}
        onSubmit={(values) => {
          onSubmit(values, detect_input_type(values.contact));
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="my-12">
              <StickyInput
                id="contact"
                name="contact"
                type="text"
                placeholder={label}
                mask={[
                  { ckType: 'unknown', mask: '**********' },
                  {
                    ckType: 'phone',
                    mask: '(999) 999-9999',
                    definitions: { 9: /\d/ },
                  },
                  { ckType: 'email', mask: /^\S*@?\S*$/gm },
                ]}
                dispatch={(appended, masked, flags) => {
                  const typed = `${masked.value}${appended}`;
                  const newMask = masked.compiledMasks.find((m) => {
                    return m.ckType === detect_input_type(typed);
                  });

                  if (newMask.ckType === 'email') {
                    setValid(emailRegex.test(typed));
                  } else {
                    setValid(newMask.isComplete);
                  }

                  return newMask;
                }}
              />
            </div>
            <CTAButton
              ariaLabel="login-button"
              buttonClass="w-full bg-primary-blue text-white rounded-full py-3 px-6 cursor-pointer text-sm md:text-base inline-block hover:bg-navy-blue flex justify-center items-center"
              buttonText={buttonText}
              icon={['far', 'lock']}
              disabled={!valid || isLoading}
              isLoading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
