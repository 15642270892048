import { useSelector } from 'react-redux';

function useCurrentUser() {
  const possessedUserId = useSelector((state) =>
    state.general.get('possessed_user_id'),
  );

  const loggedInUser = useSelector((state) =>
    state.entities.get('user'),
  ).first();

  const possessedUser = useSelector((state) => state.entities.get('user')).get(
    possessedUserId,
  );

  return [possessedUser || loggedInUser];
}

export default useCurrentUser;
