import React, { useRef } from 'react';
import { Menu } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Dropdown } from '../../../shared/components/Dropdown';
import { DefaultButton } from './DefaultButton';
import { DeleteButton } from './DeleteButton';

export default function PaymentMethodList({ payment_methods }) {
  const deleteRef = useRef();
  const defaultRef = useRef();

  return payment_methods.map((pm, idx) => {
    return (
      <div key={idx}>
        <div className="flex items-center justify-between py-4 md:py-7 border-b border-black border-opacity-5 w-full">
          <div className="flex items-center">
            <div className="font-avenir-black text-navy-blue text-sm sm:text-base ml-2 md:ml-4 mr-3 md:mr-7">
              {pm.label}
            </div>
            <div className="text-opaque-navy text-xs md:text-sm">
              {pm.payment_type === 'bank_account'
                ? `${pm.bank_name || pm.account_holder} ${pm.account_number_masked}`
                : pm.credit_card_masked}
            </div>
          </div>

          <div>
            <div className="flex justify-center items-center">
              {pm.is_default && (
                <p className="text-xs md:text-sm md:mx-3 rounded-full py-1 px-3 bg-positive-green text-white">
                  Default
                </p>
              )}
              <Dropdown
                button={
                  <FontAwesomeIcon
                    icon="ellipsis-vertical"
                    className="w-10 text-opaque-navy hover:text-navy-blue"
                  />
                }
              >
                {!pm.is_default && (
                  <Menu.Item>
                    <DefaultButton payment_method={pm} ref={defaultRef} />
                  </Menu.Item>
                )}
                <Menu.Item>
                  <DeleteButton payment_method={pm} ref={deleteRef} />
                </Menu.Item>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  });
}
