import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ZendeskChatLink from '../../shared/ZendeskChatLink';

export default function ErrorAlert({ hasAlert, nextScreen }) {
  return (
    <div
      className={classNames(
        'bg-negative-red bg-opacity-30 border-l-4 rounded-md border-negative-red p-4 my-3',
        { hidden: !hasAlert },
      )}
      role={ErrorAlert.role}
    >
      <div className="flex text-negative-red">
        <div className="flex-shrink-0">
          <FontAwesomeIcon icon={['far', 'exclamation-triangle']} />
        </div>
        <div className="ml-3 text-sm">
          <h3>
            We're having some trouble validating your bank information. You can
            try the following options:
          </h3>
          <div className="mt-2">
            <ul role="list" className="list-disc pl-5 space-y-1">
              <li>
                <ZendeskChatLink
                  msg="Upload a voided check directly to Credit Key Support"
                  onClick={nextScreen}
                />
              </li>
              <li>
                Double check your routing and account numbers and try to enter
                them again
              </li>
              <li>
                Contact
                <a
                  href="mailto: support@creditkey.com"
                  className="mx-1 cursor-pointer underline"
                >
                  Credit Key Support
                </a>
                for assistance
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorAlert.role = 'error-alert-component';
