import React from 'react';
import classNames from 'classnames';
import { startCase } from 'lodash';

export default function OrderStatus({ order, isTable }) {
  let statusColor = 'warning-yellow';
  let status = order.product.status;

  switch (order.product.status) {
    case 'funded':
    case 'active':
      status = 'active';
      statusColor = 'positive-green';
      break;
    case 'created':
    case 'pending':
      status = 'pending';
      statusColor = 'warning-yellow';
      break;
    case 'paid':
    case 'void':
      statusColor = 'navy-blue';
      break;
    case 'canceled':
      statusColor = 'negative-red';
      break;
    default:
      statusColor = 'primary-blue';
  }

  return (
    <span role={OrderStatus.role} className='flex flex-col lg:flex-row'>
      <span
        className={classNames(
          'capitalize font-avenir-medium',
          isTable
            ? `text-${statusColor}`
            : `text-white bg-${statusColor} rounded-full px-3 py-1 text-sm`,
        )}
      >
        {startCase(status)}
      </span>
      {order.product.isPastDue && (
        <span className="text-negative-red text-xxs ml-0 lg:ml-2">
          {order.product.daysPastDue} Days Past Due!
        </span>
      )}
    </span>
  );
}

OrderStatus.role = 'order-status-component';
