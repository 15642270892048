import React from 'react';

import CreditKeyAnywhere from './CreditKeyAnywhere';
import SupportInfo from './SupportInfo';
import VirtualCard from './VirtualCard';
import useCompany from '../../../../hooks/useCompany';
import { BaseGridItem } from '../../../shared/layout/BaseGrid';
import useEntity from '../../../../hooks/useEntity';

export default function WidgetRow() {
  let supportWidgetSpan;

  const [company] = useCompany();
  const [entity] = useEntity();
  const activeBanner = company?.cta_banners.find((b) => b.get('enabled'));

  if (entity?.type === 'account') supportWidgetSpan = 'col-span-full';

  const getBanner = () => {
    if (!activeBanner) return null;
    switch (activeBanner.get('name')) {
      case 'virtual_card':
        return <VirtualCard columnSpan="md:col-span-full lg:col-span-2" />;
      case 'connect_bank':
        supportWidgetSpan = 'col-span-full';
        return;
      case 'cka':
        return (
          <CreditKeyAnywhere columnSpan="md:col-span-full lg:col-span-2" />
        );
    }
  };

  return (
    <>
      {entity?.type === 'company' && <BaseGridItem>{getBanner()}</BaseGridItem>}

      <BaseGridItem>
        <SupportInfo supportWidgetSpan={supportWidgetSpan} />
      </BaseGridItem>
    </>
  );
}
