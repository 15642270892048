import { DateTime } from 'luxon';

export const differenceOfDays = (start_date, end_date) => {
  return Math.floor(start_date.diff(end_date, 'days').toObject().days);
};

export const differenceOfWeeks = (start_date, end_date) => {
  return Math.floor(
    end_date.startOf('week').diff(start_date.startOf('week'), 'weeks').values
      .weeks,
  );
};

export const differenceOfMonths = (start_date, end_date) => {
  return Math.floor(
    end_date.startOf('month').diff(start_date.startOf('month'), 'months').values
      .months,
  );
};

export const fromISO = (str) => {
  return DateTime.fromISO(str).isValid
    ? DateTime.fromISO(str)
    : DateTime.fromISO(str.replace(' ', 'T'));
};

export const fromPreviousFormat = (str, previousFormat) => {
  return DateTime.fromFormat(str, previousFormat);
};

export const endDate = (date) => {
  const today = DateTime.now();
  const end = DateTime.fromISO(date);

  if (end.startOf('day') < today.startOf('day')) {
    return end.endOf('day');
  }

  return end;
};
