import Network from '../../lib/utils/network';

const api = Network();

const path = (resource) => {
  if (!resource) resource = '';
  return { resource: 'api/credit_key_anywhere_requests/' + resource };
};

export const createCreditKeyAnywhereRequest = (cka_request) => {
  return (dispatch, getState) => {
    return api.postFormData(path(), cka_request);
  };
};
