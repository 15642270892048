import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import VCList from './VCList';
import Box from '../../../shared/layout/Box';
import useModal from '../../../../hooks/useModal';
import useOrders from '../../../../hooks/useOrders';
import ListBox from '../../../shared/layout/ListBox';
import { VCModalWrapper } from '../../../modals';

export default function VirtualCard() {
  const { isModalActive, toggleModal } = useModal();
  const { orders, ordersLoading } = useOrders();

  const virtualCardOrders = orders.filter(
    (o) =>
      o.transaction_type === 'virtual_card' &&
      !o.virtual_card_details.isEmpty(),
  );

  return (
    <div className="md:col-span-full lg:col-span-2" role={VirtualCard.role}>
      {!virtualCardOrders.size ? (
        <Box>
          {{
            type: 'vc',
            color: 'bg-warning-yellow',
            headerIcon: <FontAwesomeIcon icon={['far', 'credit-card']} />,
            title: 'Pay with Credit Key at any vendor',
            subtext: (
              <p>
                <span>
                  Request a virtual card and use your line of credit at any
                  merchant!
                </span>
                <br />
                <span>
                  <a
                    href="https://creditkey.zendesk.com/hc/en-us/articles/360060554031-How-can-I-use-the-Credit-Key-Virtual-Card-"
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More Here
                  </a>
                </span>
              </p>
            ),
            submit: {
              label: 'Get your virtual card now!',
              handler: toggleModal,
            },
          }}
        </Box>
      ) : (
        <ListBox>
          {{
            loading: ordersLoading,
            type: 'vc-list',
            color: 'bg-warning-yellow',
            headerSubtext: 'Request New Card',
            headerIcon: <FontAwesomeIcon icon={['far', 'credit-card']} />,
            title: 'Active Virtual Cards',
            subtext: <VCList virtual_cards={virtualCardOrders} />,
            submit: {
              label: 'Scroll for More',
              handler: toggleModal,
            },
          }}
        </ListBox>
      )}
      <VCModalWrapper isModalActive={isModalActive} toggleModal={toggleModal} />
    </div>
  );
}

VirtualCard.role = 'virtual-card-widget';
