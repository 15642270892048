import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BankPendHelp from './BankPendHelp';
import ModalFooter from '../layout/ModalFooter';
import ModalWrapper from '../layout/ModalWrapper';
import useModal from '../../../hooks/useModal';
import { PaymentMethodModal } from '..';

export default function PaymentMethodModalWrapper({
  isModalActive,
  toggleModal,
}) {
  const [hasAlert, setHasAlert] = useState(false);
  const isLoading = useSelector((state) => state.general.get('loading'));

  const orderedScreens = ['payment_modal', 'bank_help'];

  const { activeScreen, nextScreen, resetScreen } = useModal(orderedScreens);

  useEffect(() => {
    // Start at the first screen in the list
    resetScreen();
  }, []);

  const modalProps = {
    isLoading,
    nextScreen,
    resetScreen,
    toggleModal,
    hasAlert,
    setHasAlert,
  };

  const getScreen = () => {
    if (!activeScreen) return null;

    switch (activeScreen) {
      case 'payment_modal':
        return <PaymentMethodModal {...modalProps} />;
      case 'bank_help':
        return <BankPendHelp {...modalProps} />;
    }
  };

  if (!isModalActive) return null;

  return (
    <ModalWrapper isModalActive={isModalActive} toggleModal={toggleModal}>
      {getScreen()}
      <ModalFooter className="text-sm mt-2" />
    </ModalWrapper>
  );
}

PaymentMethodModalWrapper.role = 'payment-method-modal-wrapper';
