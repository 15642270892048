import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  PaymentProgress,
  AdditionalFeesDisclosure,
  PaymentScheduleDisclosure,
  OrderStats,
  Documents,
} from './components';
import { ReturnsModal } from '../../modals';
import Loading from '../../shared/components/Loading';
import PageLayout from './PageLayout';
import useModal from '../../../hooks/useModal';
import useToast from '../../../hooks/useToast';
import {
  downloadInvoice,
  downloadAgreement,
  getDocuments,
} from '../../../store/actions/orders';

export default function OrderDetail() {
  const dispatch = useDispatch();
  const { isModalActive, setIsModalActive, toggleModal } = useModal();

  const { id } = useParams();
  const { makeToast } = useToast();

  const orders = useSelector((state) => state.entities.get('order'));

  const order = orders?.get(id);

  const downloadDocument = async (type, id) => {
    let download;

    switch (type) {
      case 'agreement':
        download = await downloadAgreement(order);
        break;
      case 'invoice_document':
        download = await downloadInvoice(order, id);
        break;
    }

    download.start().catch(function (error) {
      let msg = '';
      if (error.request.status === 404) {
        msg = (
          <span>
            <span className="capitalize">{type.replace('_', ' ')}</span> not
            found, please contact support.
          </span>
        );
      } else {
        msg = (
          <span>
            <span className="capitalize">{type.replace('_', ' ')}</span>{' '}
            unavailable, please contact support.
          </span>
        );
      }

      makeToast(msg, 'negative-red', ['far', 'octagon-xmark']);
    });
  };

  useEffect(() => {
    if (order) {
      dispatch(getDocuments(order));
    }
  }, [order]);

  // The loan id didn't match anything, go back to the index
  if (orders.size > 0 && !order) return <Navigate to="/orders" />;

  return (
    <PageLayout order={order}>
      <div role={OrderDetail.role}>
        {order ? (
          <div>
            <OrderStats order={order} />
            <PaymentProgress order={order} />
            <div className="flex flex-col sm:flex-row justify-between sm:items-start">
              <Documents
                buttonClass="cursor-pointer text-sm text-primary-blue flex items-center my-1"
                containerClass="flex flex-col px-2"
                downloadDocument={downloadDocument}
                order={order}
                withIcons={false}
              />
              <div className="flex flex-col px-2">
                <p
                  onClick={toggleModal}
                  className="cursor-pointer text-sm text-primary-blue flex items-center my-1"
                >
                  Cancellations and Returns Policy
                </p>
                <ReturnsModal
                  modalProps={{ isModalActive, setIsModalActive, toggleModal }}
                />
              </div>
            </div>
            {parseFloat(order?.expected_fees) > 0 && (
              <AdditionalFeesDisclosure order={order} />
            )}
            <PaymentScheduleDisclosure order={order} />
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </PageLayout>
  );
}

OrderDetail.role = 'order-detail-component';
