import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Error() {
  return (
    <div
      className="w-1/2 mx-auto bg-negative-red bg-opacity-30 border-l-4 rounded-md border-negative-red p-4 my-3"
      role={Error.role}
    >
      <div className="flex text-negative-red">
        <div className="flex-shrink-0">
          <FontAwesomeIcon icon={['far', 'exclamation-triangle']} />
        </div>
        <div className="ml-3 text-sm">
          <h3>
            Something went wrong, please
            <Link to="/init/existing_user" className="mx-1 underline">
              go back
            </Link>
            and try again.
          </h3>
        </div>
      </div>
    </div>
  );
}

Error.role = 'error-component';
