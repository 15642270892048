import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export default function PrivateRoute({ children }) {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!auth.isAuthenticated()) {
      auth.signout();
      auth.redirectToLogin(location);
    }
  }, [auth, location]);

  return children;
}
