import { Record } from 'immutable';
import { normalizePhone } from '../utils/formatters';
import { convertRawValues } from '../utils/record_utils';
import AddressRecord from './address';

export default class UserRecord extends Record({
  id: null,
  address: new AddressRecord(),
  first_name: '',
  last_name: '',
  suffix: '',
  email: '',
  phone_number: '',
  company_name: '',
  company_id: null,
  account_id: null,
  role: '',
}) {
  constructor(data) {
    data = convertRawValues(data);

    if (data && data.get('phone'))
      data = data.set('phone_number', normalizePhone(data.get('phone')));
    if (data && data.get('phone_number'))
      data = data.set('phone_number', normalizePhone(data.get('phone_number')));

    if (data && data.get('address')) {
      data = data.set('address', new AddressRecord(data.get('address')));
    }

    super(data);
  }

  get phone() {
    return this.phone_number;
  }

  get full_name() {
    return `${this.first_name || ''} ${this.last_name || ''}`.trim();
  }

  get is_admin_user() {
    return ['admin', 'superadmin'].includes(this.role);
  }

  get hasAccount() {
    return !!(this.has('account_id') && this.account_id);
  }

  get hasCompany() {
    return !!(this.has('company_id') && this.company_id);
  }
}
