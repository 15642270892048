import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useCompany from './useCompany';
import useConsumerAccount from './useConsumerAccount';
import useCurrentUser from './useCurrentUser';
import useEntity from './useEntity';
import { asyncActions } from '../store/actions/general';
import { getPaymentMethods } from '../store/actions/payment_methods';

function usePaymentMethods() {
  const dispatch = useDispatch();
  const [entity] = useEntity();
  const [account] = useConsumerAccount();
  const [company] = useCompany();
  const [user] = useCurrentUser();
  const [loading, setLoading] = useState(false);

  const payment_methods = useSelector((state) =>
    state.entities.get('payment_method'),
  )
    .filter((i) => i.get('status') !== 'removed')
    .filter(
      (i) =>
        i.get('account_id') === account?.id ||
        i.get('user_id') === company?.borrower?.id,
    );

  const defaultCard = payment_methods?.find(
    (pm) => pm.get('payment_type') === 'card' && pm.get('is_default'),
  );
  const defaultBank = payment_methods?.find(
    (pm) => pm.get('payment_type') === 'bank_account' && pm.get('is_default'),
  );

  const hasConnectedCards =
    payment_methods?.filter((pm) => pm.get('payment_type') === 'card').size > 0;
  const hasConnectedBanks =
    payment_methods?.filter((pm) => pm.get('payment_type') === 'bank_account')
      .size > 0;

  const defaultBankLabel = hasConnectedBanks
    ? defaultBank?.label || `${defaultBank?.account_holder} Account`
    : null;

  const defaultCardLabel = hasConnectedCards ? defaultCard?.label : null;

  useEffect(() => {
    if (user && !loading && payment_methods.size === 0) {
      let encodedEmail = encodeURIComponent(user.email);

      setLoading(true);
      dispatch(
        asyncActions(getPaymentMethods(`filter[email]=${encodedEmail}`)),
      ).finally(() => setLoading(false));
    }
  }, [entity, payment_methods]);

  return {
    payment_methods,
    defaultBankLabel,
    defaultCardLabel,
    defaultCard,
    defaultBank,
    loading,
  };
}

export default usePaymentMethods;
