import { Disclosure } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../lib/images/logo.svg';
import { supportLink } from '../../lib/constants/SiteVariables';

export default function TopNav() {
  return (
    <Disclosure as="nav" className="bg-white shadow">
      <div className="mx-auto px-2 sm:px-4 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex px-2 lg:px-0">
            <div className="flex-shrink-0 flex items-center mx-6">
              <img src={logo} alt="Credit Key Logo" />
            </div>
          </div>
          <div className="ml-4 flex items-center">
            <a
              href={supportLink}
              className="flex-shrink-0 bg-white p-1 text-primary-blue rounded-full hover:text-navy-blue"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={['fal', 'circle-question']}
                className="px-2 md:px-4 shrink-0 min-w-[24px] cursor-pointer text-xl"
              />
            </a>
          </div>
        </div>
      </div>
    </Disclosure>
  );
}
