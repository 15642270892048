import React from 'react';
import classNames from 'classnames';
import { ErrorMessage, Field, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CTAButton from '../../shared/CTAButton';
import ColumnInput from '../../shared/ColumnInput';
import StickyInput from '../../shared/StickyInput';
import { formStyles } from '../../../lib/constants/SiteVariables';

export default function Step2Form({
  errors,
  setFieldValue,
  fileName,
  setFileName,
}) {
  return (
    <Form className="my-4 flex flex-col items-center w-full">
      <div className="mt-2 flex justify-between items-center w-full px-6">
        <ColumnInput>
          <StickyInput
            id="vendor_contact_first_name"
            name="vendor_contact_first_name"
            placeholder="Billing First (optional)"
            type="text"
            style={{ marginRight: '0.5rem' }}
          />
        </ColumnInput>
        <ColumnInput>
          <StickyInput
            id="vendor_contact_last_name"
            name="vendor_contact_last_name"
            placeholder="Billing Last (optional)"
            type="text"
          />
        </ColumnInput>
      </div>
      <div className="flex flex-col items-center w-full px-6">
        <ColumnInput>
          <StickyInput
            id="vendor_contact_email"
            name="vendor_contact_email"
            placeholder="Vendor Billing Contact Email (optional)"
            type="text"
          />
        </ColumnInput>
        <ColumnInput error={errors.vendor_contact_phone}>
          <StickyInput
            id="vendor_contact_phone"
            name="vendor_contact_phone"
            mask="+{1} (000) 000-0000"
            placeholder="Vendor Billing Contact Phone"
            type="text"
          />
        </ColumnInput>
        <ColumnInput error={errors.vendor_url}>
          <StickyInput
            id="vendor_url"
            name="vendor_url"
            placeholder="Vendor Website URL"
            type="text"
          />
        </ColumnInput>
        <ColumnInput>
          <StickyInput
            id="order_id"
            name="order_id"
            placeholder="Order ID (optional)"
            type="text"
          />
        </ColumnInput>
        <ColumnInput error={errors.description}>
          <StickyInput
            id="description"
            name="description"
            placeholder="Description (New restaurant supplies, e.g.)"
            type="text"
          />
        </ColumnInput>
      </div>
      <div className="flex flex-col items-center justify-center bg-grey-lighter w-full px-6">
        <label
          className={classNames(
            'w-full flex flex-col items-center py-4 bg-white rounded-lg shadow-lg tracking-wide uppercase border cursor-pointer text-center',
            errors.invoice_document
              ? 'border-negative-red text-negative-red hover:bg-negative-red hover:bg-opacity-10'
              : 'border-primary-blue hover:bg-primary-blue text-primary-blue hover:bg-opacity-10',
          )}
        >
          <FontAwesomeIcon icon="file-arrow-up" className="mr-3" />
          <span
            className={classNames(
              'mt-2 leading-normal',
              fileName ? 'text-xs' : 'text-base',
            )}
          >
            {fileName ? fileName : 'Attach Invoice'}
          </span>
          <Field
            className="hidden"
            id="invoice_document"
            name="invoice_document"
            type="file"
            value=""
            accept="image/jpeg,image/jpg,image/png,application/pdf,application/msword"
            onChange={(e) => {
              setFieldValue('invoice_document', e.currentTarget.files[0]);
              setFileName(e.currentTarget.files[0].name);
            }}
          />
        </label>
      </div>
      <div className="w-full px-6">
        {errors.invoice_document && (
          <ErrorMessage
            name="invoice_document"
            component="span"
            className={formStyles.validation}
          />
        )}
        {fileName && (
          <button
            className="text-negative-red text-sm"
            onClick={() => {
              setFileName(null);
              setFieldValue('invoice_document', null);
            }}
          >
            <span className="mr-2">
              <FontAwesomeIcon icon="times" />
            </span>
            <span>Remove</span>
          </button>
        )}
      </div>
      <CTAButton
        buttonClass='px-32 bg-primary-blue text-white'
        buttonText="Submit"
        containerClass="pt-6"
        icon={['far', 'envelope']}
      />
    </Form>
  );
}
