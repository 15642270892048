import React from 'react';
import { Field, ErrorMessage } from 'formik';

import usePaymentMethods from '../../../hooks/usePaymentMethods';
import { formStyles } from '../../../lib/constants/SiteVariables';

export default function PaymentMethods({ order, setFieldValue }) {
  let paymentList = [];
  const { payment_methods } = usePaymentMethods();

  if (!payment_methods) return;

  if (order.entity_type === 'account') {
    paymentList = payment_methods
      .valueSeq()
      .filter((pm) => pm.payment_type === 'card');
  } else {
    paymentList = payment_methods
      .valueSeq()
      .filter((pm) => pm.payment_type === 'bank_account');
  }

  return (
    <div>
      <fieldset className="mt-2">
        <legend className="sr-only">Payment Methods</legend>
        <div className="divide-y divide-border-gray">
          {paymentList.map((pm, idx) => {
            return (
              <div key={idx} className="relative flex items-start py-4">
                <div className="flex items-center">
                  <Field
                    id={pm.get('id').toString()}
                    type="radio"
                    name="payment_method_id"
                    value={pm.get('id').toString()}
                    className="focus:ring-primary-blue h-4 w-4 mr-3 text-primary-blue border-light-gray"
                  />
                  <div
                    className="min-w-0 flex-1 cursor-pointer"
                    onClick={() =>
                      setFieldValue(
                        'payment_method_id',
                        pm.get('id').toString(),
                      )
                    }
                  >
                    <label className="font-avenir-black text-navy-blue">
                      {order.entity_type === 'account'
                        ? pm.credit_card_type
                        : pm.payment_method_label}
                      <span className="mx-4 font-avenir-medium text-opaque-navy text-sm">
                        x{pm.payment_method_last_4}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </fieldset>
      <ErrorMessage
        name="payment_method_id"
        component="div"
        className={formStyles.validation}
      />
    </div>
  );
}
