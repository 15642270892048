import React from 'react';
import { Form } from 'formik';

import PaymentMethods from './PaymentMethods';
import PaymentOptions from './PaymentOptions';
import CTAButton from '../../shared/CTAButton';

export default function PaymentModalBody({
  order,
  disabled,
  setDisabled,
  setFieldValue,
}) {
  return (
    <Form>
      <h2 className="font-avenir-medium text-lg text-navy-blue">
        Choose Payment Option
      </h2>
      <div className="mt-4">
        <PaymentOptions
          order={order}
          setFieldValue={setFieldValue}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      </div>
      <h2 className="font-avenir-medium text-lg text-navy-blue">
        Choose Payment Method
      </h2>
      <div className="px-1">
        <PaymentMethods order={order} setFieldValue={setFieldValue} />
      </div>
      <CTAButton
        buttonClass="w-full px-16 bg-primary-blue text-white"
        containerClass="my-4"
        buttonText="Confirm Payment"
        icon={['far', 'lock']}
        type="submit"
      />
    </Form>
  );
}
