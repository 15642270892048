import { Record } from 'immutable';
import { DateTime } from 'luxon';
import { differenceOfDays, fromISO } from '../utils/datetime_formatters';
import { convertRawValues } from '../utils/record_utils';

export default class LoanRecord extends Record({
  id: null,
  order_id: null,
  status: '',
  installments: '',
  monthly_interest: '',
  amount: '',
  payoff_balance: '',
  total_balance: '',
  total_monthly_fees: '',
  mambu_loan_id: '',
  lifecycle_status: '',
  next_payment_date: '',
  last_past_due_date: '',
  next_payment_amount: '',
  payment_schedule: [],
}) {
  constructor(data) {
    data = convertRawValues(data);
    super(data);
  }
  get progress() {
    if (
      ['returned', 'canceled', 'error', 'declined', 'paid'].includes(
        this.status,
      )
    ) {
      return 100;
    }
    return (
      (this.paid_amount /
        (parseFloat(this.amount) + parseFloat(this.total_monthly_fees))) *
      100
    );
  }

  get daysPastDue() {
    let today = DateTime.now();
    let due_date = fromISO(this.last_past_due_date);

    return differenceOfDays(today, due_date);
  }

  get isPastDue() {
    return this.lifecycle_status === 'past_due';
  }

  get next_payment_due_amount() {
    return this.next_payment_amount;
  }

  get paid_amount() {
    return (
      parseFloat(this.amount) +
      parseFloat(this.total_monthly_fees) -
      this.total_balance
    );
  }
}
