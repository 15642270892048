import React from 'react';
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import ModalWrapper from './layout/ModalWrapper';
import useToast from '../../hooks/useToast';
import { deleteCompanyUser } from '../../store/actions/users';
import { loading } from '../../store/actions/general';

export default function DeleteUserModal({
  isModalActive,
  toggleModal,
  user,
}) {
  const dispatch = useDispatch();
  const { makeToast } = useToast();

  const deleteUser = () => {
    dispatch(loading(true));
    dispatch(deleteCompanyUser(user))
      .then(
        () =>
          makeToast(
            'User Successfully Deleted',
            'positive-green',
            'check-circle',
          ),
      )
      .finally(() => dispatch(loading(false)));
  };

  return (
    <div role={DeleteUserModal.role}>
      <ModalWrapper isModalActive={isModalActive} toggleModal={toggleModal}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <FontAwesomeIcon
              icon={['far', 'exclamation-triangle']}
              className="text-negative-red mb-1"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 text-negative-red"
            >
              Are you sure?
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-base text-opaque-navy">
                Please confirm you'd like to delete
                <span className="mx-1">
                  {user?.first_name} {user?.last_name}
                </span>
                as a purchasing user.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => {
              deleteUser();
              toggleModal();
            }}
          >
            Delete
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-opaque-navy-border shadow-sm px-4 py-2 bg-white text-base font-medium text-navy-blue hover:text-opaque-navy sm:mt-0 sm:w-auto sm:text-sm"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </ModalWrapper>
    </div>
  );
}

DeleteUserModal.role = 'delete-user-modal-component';
