import React from 'react';
import { ModalWrapper, ModalFooter } from './';

export default function ReturnsModal({ modalProps }) {
  return (
    <ModalWrapper
      isModalActive={modalProps.isModalActive}
      setIsModalActive={modalProps.setIsModalActive}
      toggleModal={modalProps.toggleModal}
    >
      <div className="p-4 mb-6" role={ReturnsModal.role}>
        <header className="text-center text-primary-blue font-avenir-black text-xl mb-6">
          Cancellations and Returns
        </header>
        <section className='divide-y'>
          <div className='mb-6'>
            <p className="text-justify text-base">
              To cancel your order or request a refund, first contact your
              merchant and follow their cancellation/refund policies. Notify our
              support team that the cancellation or return has been fully
              processed, then we'll update your Credit Key loan accordingly.
            </p>
            <h5 className="font-avenir-black text-lg mt-4 mb-1 text-navy-blue">
              Making Payments
            </h5>
            <p>
              While you're waiting for your refund, please continue to make any
              monthly payments that are due.
            </p>
          </div>
          <div>
            <header className="text-center text-primary-blue font-avenir-black text-xl mt-6">
              How are refunds applied?
            </header>

            <h5 className="font-avenir-black text-lg mt-4 mb-1 text-navy-blue">
              Refund is less than balance
            </h5>
            <p>
              If your refund is less than your total purchase balance, it might
              not lower or cover your next monthly payment. Instead, you can
              expect fewer monthly payments, a smaller final payment, or both.
            </p>

            <h5 className="font-avenir-black text-lg mt-4 mb-1 text-navy-blue">
              Refund is more than balance
            </h5>
            <p>
              If your refund is more than your purchase balance, the difference
              will be refunded, minus any interest paid. Credit Key will credit
              your payment method within 3–10 business days.
            </p>
          </div>
        </section>
      </div>
      <ModalFooter className="text-sm text-opaque-navy" />
    </ModalWrapper>
  );
}

ReturnsModal.role = 'returns-modal-component';
