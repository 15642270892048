import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { supportPhone } from '../../lib/constants/SiteVariables';

export default function LockedOut() {
  return (
    <div className="has-text-centered mt-6">
      <div className="content">
        <FontAwesomeIcon
          icon={['far', 'user-times']}
          className="is-size-1 locked-icon"
        />
        <h1>Max Attempts Exceeded</h1>
        <p className="block my-5">
          We're sorry, an error has occurred and your account has been locked.
          <br />
          Please contact Credit Key technical support to unlock your account.
        </p>
        Questions? Issues? Please contact Credit Key Support:
        <br />
        <a href="mailto: support@creditkey.com">
          support@creditkey.com
        </a> or {supportPhone}
      </div>
    </div>
  );
}
