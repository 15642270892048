import React from 'react';
import { Link } from 'react-router-dom';

export default function TableHeader() {
  return (
    <div className="m-4">
      <div className="border-b border-gray-200 dark:border-gray-700">
        <div className="font-avenir-medium text-navy-blue capitalize w-full flex items-center justify-between my-6 px-3">
          <p className="text-base">Active Loans</p>
          <Link
            className="font-avenir-book text-opaque-navy text-sm cursor-pointer w-14"
            to="/orders"
          >
            View All
          </Link>
        </div>
      </div>
    </div>
  );
}
