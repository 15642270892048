import { Listbox, Popover } from '@headlessui/react';

import check from '../../../lib/images/check.gif';
import useDropdown from '../../../hooks/useDropdown';
import useEntity from '../../../hooks/useEntity';
import useOrders from '../../../hooks/useOrders';
import { BankForm, CreditCardForm } from '../../forms';
import { ListBox } from '../../shared/components/Dropdown';

export default function PaymentMethodModal({
  hasAlert,
  setHasAlert,
  isLoading,
  nextScreen,
  toggleModal,
}) {
  const [entity] = useEntity();
  const { orders } = useOrders();

  const hasPi4Orders =
    orders.filter(
      (order) =>
        order.entity_type === 'account' && order.product.status === 'active',
    ).size > 0;

  const defaultContentId = entity?.type === 'company' ? 'ach' : 'cc';
  const { contentItems, setContent } = useDropdown(defaultContentId);
  const listOptions = [
    { id: 'ach', text: 'ACH Bank Account' },
    { id: 'cc', text: 'Credit Card' },
  ];

  return (
    <>
      <div>
        <div className="text-center">
          <p className="text-2xl my-2 font-avenir-black text-primary-blue">
            New Payment Method
          </p>
          <p className="mb-0">
            Please confirm your{' '}
            {contentItems === 'ach' ? 'business bank' : 'credit card'} details.
          </p>
          {contentItems === 'ach' && (
            <Popover className="relative">
              <Popover.Button className="text-xs text-center text-primary-blue underline cursor-pointer">
                Need Help?
              </Popover.Button>

              <Popover.Panel className="absolute left-20 z-10 bg-navy-blue text-white p-4 rounded-md w-80 h-50">
                <img src={check} alt="example check" className="mx-auto" />
              </Popover.Panel>
            </Popover>
          )}

          {entity?.type === 'company' && hasPi4Orders && (
            <ListBox
              initialValue={defaultContentId}
              buttonText={
                contentItems === 'ach'
                  ? listOptions[0].text
                  : listOptions[1].text
              }
              handleChange={setContent}
              listBoxClass="w-4/5 xs:w-1/2 my-3 relative"
            >
              {listOptions.map((op) => (
                <Listbox.Option
                  key={op.id}
                  value={op.id}
                  className="cursor-pointer p-2 hover:bg-gray-500 hover:text-white last:hover:rounded-b-md"
                >
                  {op.text}
                </Listbox.Option>
              ))}
            </ListBox>
          )}
        </div>
        <div className="layer" />
      </div>
      <section>
        {contentItems === 'ach' ? (
          <BankForm
            hasAlert={hasAlert}
            isLoading={isLoading}
            nextScreen={nextScreen}
            toggleModal={toggleModal}
            setHasAlert={setHasAlert}
          />
        ) : (
          <CreditCardForm
            hasAlert={hasAlert}
            nextScreen={nextScreen}
            toggleModal={toggleModal}
          />
        )}
      </section>
    </>
  );
}
