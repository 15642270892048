import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from 'react-table';

import Loading from '../../../shared/components/Loading';
import TableHeader from './TableHeader';
import BaseTable from '../../../shared/BaseTable';
import { DashboardTableColumns } from './DashboardTableColumns';

export default function ActiveOrdersTable({ orders, ordersLoading }) {
  const navigate = useNavigate();
  const activeOrders = orders.filter((i) =>
    ['active', 'funded', 'created'].includes(i.product.status),
  );

  const data = useMemo(() => activeOrders.valueSeq(), [ordersLoading]);
  const columns = useMemo(() => DashboardTableColumns, []);
  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 5,
        sortBy: [
          { id: 'created_at', desc: true },
          { id: 'status', desc: true },
        ],
      },
      manualSortBy: true,
      disableSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  if (ordersLoading)
    return <Loading className="mt-6" containerClass="col-span-full" />;

  return (
    <div
      role={ActiveOrdersTable.role}
      className="col-span-full rounded-lg border border-black border-opacity-5 py-1 overflow-x-scroll"
    >
      <TableHeader />
      <BaseTable
        tableInstance={instance}
        noDataFoundWarning="You do not currently have any active orders."
        onClickHandler={(value) => navigate(`/orders/${value.id}`)}
      />
    </div>
  );
}

ActiveOrdersTable.role = 'active-orders-table-component';
