/**
 * General wrapper to cache API validation calls.
 */
export const cacheAsyncResponse = (asyncValidate) => {
  let _valid = false;
  let _args = '';

  return async (...args) => {
    if (args.toString() !== _args) {
      const response = await asyncValidate(...args);
      _args = args.toString();
      _valid = response;

      return response;
    }

    return _valid;
  };
};

export const isNotANumber = (value) => value !== value;
