import React from 'react';
import CTAButton from '../../shared/CTAButton';

export default function CKASuccess({ resetModalState, resetScreen, toggleModal }) {
  return (
    <div className="min-w-20 my-2" role={CKASuccess.role}>
      <section className="flex flex-col justify-center items-center">
        <div className="mx-6 text-center">
          <p className="text-2xl mb-4 uppercase font-avenir-black">
            Thank You!
          </p>
          <p className="text-base my-2">
            Your request has been submitted. Credit Key Support will process
            this invoice as soon as possible, and will contact you with any
            questions or issues.
          </p>
        </div>
        <CTAButton
          ariaLabel="Close"
          buttonClass='px-32 bg-primary-blue text-white'
          buttonText="Close"
          containerClass='mt-4 mb-6'
          onClick={() => {
            toggleModal();
            resetModalState();
            resetScreen();
          }}
        />
      </section>
    </div>
  );
}

CKASuccess.role = 'cka-success-component';
