import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Documents({
  buttonClass,
  containerClass,
  downloadDocument,
  order,
  withIcons,
}) {
  const invoiceDocument = useSelector((state) =>
    state.entities.get('order_document'),
  ).find((d) => d.file_type === 'invoice_document' && d.order_id === order?.id);

  const net30InvoiceDocument = useSelector((state) =>
    state.entities.get('order_document'),
  ).find((d) => d.file_type === 'invoice' && d.order_id === order?.id);

  return (
    <div className={containerClass}>
      <div
        onClick={() => downloadDocument('agreement')}
        className={buttonClass}
      >
        {withIcons && (
          <FontAwesomeIcon icon="download" className="mr-2 mb-0.5" />
        )}
        <p>
          Download
          <span className="mx-1">
            {order.product_type === 'mambu_loan'
              ? 'Loan Agreement'
              : 'Payment Plan Agreement'}
          </span>
        </p>
      </div>
      {invoiceDocument && (
        <div
          onClick={() => downloadDocument('invoice_document', invoiceDocument?.id)}
          className={buttonClass}
        >
          {withIcons && (
            <FontAwesomeIcon icon="file-lines" className="mr-2 mb-0.5" />
          )}
          <p>Download Order Invoice</p>
        </div>
      )}
      {net30InvoiceDocument && (
        <div
          onClick={() => downloadDocument('invoice_document', net30InvoiceDocument?.id)}
          className={buttonClass}
        >
          {withIcons && (
            <FontAwesomeIcon icon="file-lines" className="mr-2 mb-0.5" />
          )}
          <p>Download Order Invoice</p>
        </div>
      )}
    </div>
  );
}
