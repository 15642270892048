import React from 'react';

import { formatCurrency } from '../../../../lib/utils/formatters';
import { DateTime } from 'luxon';

export default function ConfirmationData({
  order,
  chosenMethod,
  accountNum,
  modalState,
}) {
  let displayItems = [
    {
      id: 'date',
      title: 'Date',
      display_text: DateTime.now().toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
    },
    {
      id: 'order_id',
      title: 'Order ID',
      display_text: order.merchant_order_id,
    },
    {
      id: 'bank_account',
      title: 'Payment Method',
      display_text:
        order.product_type === 'payment_plan'
          ? chosenMethod?.credit_card_type
          : chosenMethod?.payment_method_label,
    },
    {
      id: 'amount',
      title: 'Payment Amount',
      display_text: formatCurrency(modalState.amount),
      style: 'font-avenir-black text-primary-blue text-xl',
    },
  ];

  return (
    <div role={ConfirmationData.role}>
      {displayItems.map((item, idx) => {
        return (
          <div key={idx}>
            <span className="flex items-center justify-between font-avenir-medium my-5">
              <p className="text-opaque-navy">{item.title}</p>
              <div className="flex items-baseline">
                <span className={item.style || 'text-navy-blue'}>{item.display_text}</span>
                {item.id === 'bank_account' && (
                  <span className="ml-1.5 text-sm text-opaque-navy font-avenir-medium">
                    {accountNum}
                  </span>
                )}
              </div>
            </span>
          </div>
        );
      })}
    </div>
  );
}

ConfirmationData.role = 'confirmation-data-component';
