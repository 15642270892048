import classNames from 'classnames';
import { startCase } from 'lodash';

import Loading from '../components/Loading';

export default function Card({ children }) {
  const { header, content, footer } = children;

  if (!children) return <Loading />
  return (
    <div
      className={classNames(
        `h-full flex flex-col rounded-lg border border-black border-opacity-5 ${header?.type} bg-opacity-10`,
        {
          'px-4 md:px-8 pt-4 md:pt-8': footer,
          'p-4 md:p-8': !footer,
        },
      )}
    >
      {header && (
        <div className="flex flex-col xxs:flex-row items-start xxs:items-center justify-between">
          <div className="font-avenir-medium text-navy-blue text-sm md:text-base capitalize w-full flex items-center justify-between">
            {header.title}
            {header.subtitle && (
              <span
                className={`ml-3 font-avenir-book text-sm text-white bg-${header.subtitle_color} rounded-full py-1 px-3`}
              >
                {startCase(header.subtitle)}
              </span>
            )}
          </div>
          {header.action && (
            <div
              className="text-opaque-navy text-sm cursor-pointer w-1/5 hover:text-primary-blue flex xs:justify-end"
              onClick={header.actionHandler}
            >
              {header.action}
            </div>
          )}
        </div>
      )}

      {content && <div className="mt-3 md:mt-6">{content}</div>}
      {footer && (
        <div className="flex-grow -mx-4 md:-mx-8 px-4 md:px-8">
          <div className="font-avenir-medium text-navy-blue text-sm md:text-base mt-6">
            {footer.title}
          </div>
          <div className="my-4">{footer.content}</div>
        </div>
      )}
    </div>
  );
}
