import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import CTAButton from '../../../shared/CTAButton';
import ColumnInput from '../../../shared/ColumnInput';
import StickyInput from '../../../shared/StickyInput';
import UserRecord from '../../../../lib/records/user';
import useToast from '../../../../hooks/useToast';
import { createCompanyUser } from '../../../../store/actions/users';
import { emailRegex } from '../../../../lib/constants/SiteVariables';
import { loading } from '../../../../store/actions/general';
import { validatePhone } from '../../../../store/actions/users';

export default function AddUserForm({ company, setOpen }) {
  const form = useRef(null);
  const dispatch = useDispatch();
  const { makeToast } = useToast();

  const isLoading = useSelector((state) => state.general.get('loading'));

  const checkPhone = async (val) => {
    const res = await dispatch(validatePhone(val));
    return res.result;
  };

  const onSubmit = async (values, { resetForm, setFieldError }) => {
    dispatch(loading(true));
    dispatch(createCompanyUser(company?.id, new UserRecord(values)))
      .then(() => {
        resetForm();
        makeToast(
          'New Purchaser User Added!',
          'positive-green',
          'check-circle',
        );
        setOpen(false);
      })
      .catch((err) => {
        if (!err) return;
        err.then((res) => {
          switch (res.message) {
            case 'Invalid phone number':
              setFieldError(
                'phone',
                '* Invalid phone number. Please try again.',
              );
              break;
            case 'Phone number already in use':
              setFieldError(
                'phone',
                '* Phone number already in use. Please try again.',
              );
              break;
            case 'Email address already in use':
              setFieldError(
                'email',
                '* Email address already in use. Please try again.',
              );
              break;
          }
        });
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };

  return (
    <Formik
      innerRef={form}
      initialValues={{
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
      }}
      validate={async (values) => {
        const errors = {};
        if (!values.first_name) errors.first_name = '* Required';
        if (!values.last_name) {
          errors.last_name = '* Required';
        }
        if (!values.phone) errors.phone = '* Required';
        if (!values.email) {
          errors.email = '* Required';
        } else if (!emailRegex.test(values.email)) {
          errors.email = '* Invalid email address';
        }
        return errors;
      }}
      onSubmit={async (values, { setFieldError, resetForm }) => {
        const phoneCheck = await checkPhone(values.phone);

        if (!phoneCheck.valid) {
          setFieldError('phone', '* Please enter a valid phone number');
        }
        phoneCheck.valid && onSubmit(values, { resetForm, setFieldError });
      }}
    >
      {({ errors, touched, resetForm }) => (
        <Form>
          <div className="w-full xs:w-1/2">
            <ColumnInput error={errors.first_name} className="mb-5">
              <StickyInput id="first_name" placeholder="First Name" />
            </ColumnInput>
            <ColumnInput error={errors.last_name} className="mb-5">
              <StickyInput id="last_name" placeholder="Last Name" />
            </ColumnInput>
            <ColumnInput error={errors.phone} className="mb-5">
              <StickyInput
                id="phone"
                type="tel"
                mask="(999) 999-9999"
                placeholder="Mobile Number"
              />
            </ColumnInput>
            <ColumnInput error={errors.email} className="mb-5">
              <StickyInput id="email" type="email" placeholder="Email" />
            </ColumnInput>
            <div className="flex flex-col xs:flex-row my-4">
              <CTAButton
                ariaLabel="save-purchaser-user"
                buttonClass="px-6 bg-primary-blue text-white"
                buttonText="Add Purchaser User"
                containerClass="mr-0 xs:mr-2 my-1.5 xs:my-0"
                disabled={
                  Object.keys(errors).length !== 0 ||
                  isLoading ||
                  !Object.keys(touched).length
                }
                icon={['far', 'lock']}
                isLoading={isLoading}
              />
              <CTAButton
                ariaLabel="cancel"
                buttonClass="px-6 bg-negative-red text-white"
                buttonText="Cancel"
                onClick={() => setOpen(false)}
                containerClass="ml-0 xs:ml-2 my-1.5 xs:my-0"
                disabled={isLoading}
                icon="times"
                isLoading={isLoading}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
