import { Record, Map } from 'immutable';

import LoanRecord from './loan';
import MerchantRecord from './merchant';
import PaymentPlanRecord from './payment_plan';
import { convertRawValues } from '../utils/record_utils';

export default class OrderRecord extends Record({
  id: null,
  entity_id: null,
  entity_type: '',
  created_at: '',
  merchant_order_id: null,
  status: '',
  amount: '',
  amount_refunded: '',
  expected_interest: '',
  expected_fees: '',
  processing_fee_amount: '',
  product_type: '',
  total_balance: '',
  merchant: new MerchantRecord(),
  product: {},
  virtual_card_details: new Map(),
  transaction_type: '',
}) {
  constructor(data) {
    data = convertRawValues(data);

    if (data.get('merchant')) {
      data = data.set('merchant', new MerchantRecord(data.get('merchant')));
    }

    if (data.get('product_type') === 'mambu_loan') {
      data = data.set('product', new LoanRecord(data.get('product')));
    } else {
      data = data.set('product', new PaymentPlanRecord(data.get('product')));
    }

    super(data);
  }

  get net_amount() {
    return this.amount - this.amount_refunded;
  }
}
