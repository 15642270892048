import React, { useState, useRef } from 'react';
import { Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { formStyles } from '../../../lib/constants/SiteVariables';
import { formatCurrency } from '../../../lib/utils/formatters';

export default function PaymentOptions({
  setFieldValue,
  disabled,
  setDisabled,
  order,
}) {
  const [active, setActive] = useState('minimum');
  const minimumRef = useRef(null);
  const remainingRef = useRef(null);
  const customPaymentRef = useRef(null);

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <Field
          name="amount"
          value={minimumRef.current ? minimumRef.current.value : ''}
        >
          {() => (
            <div
              className={classNames(
                'col-span-1 flex flex-col rounded-lg shadow cursor-pointer hover:border-2 hover:border-primary-blue w-1/3 mr-2',
                {
                  'border-2 border-primary-blue bg-primary-blue bg-opacity-10':
                    active === 'minimum',
                },
              )}
              onClick={() => {
                setActive('minimum');
                setDisabled(true);
                setFieldValue('amount', minimumRef.current.value);
              }}
            >
              <div className="flex-1 flex flex-col p-4">
                <h3 className="text-opaque-navy text-sm">Make Minimum Payment</h3>
                <dl className="mt-1 flex-grow flex flex-col justify-between">
                  <dt className="sr-only">Minimum Payment</dt>
                  <dd
                    className={classNames('text-md font-avenir-black', {
                      'text-primary-blue': active === 'minimum',
                    })}
                  >
                    {formatCurrency(order.product.next_payment_due_amount)}
                  </dd>
                </dl>
                <input
                  value={order.product.next_payment_due_amount.toString()}
                  name="minimum_payment"
                  ref={minimumRef}
                  className="hidden"
                  readOnly
                />
              </div>
            </div>
          )}
        </Field>
        <Field
          name="amount"
          value={remainingRef.current ? remainingRef.current.value : ''}
        >
          {() => (
            <div
              className={classNames(
                'col-span-1 flex flex-col rounded-lg shadow cursor-pointer hover:border-2 hover:border-primary-blue w-1/3',
                {
                  'border-2 border-primary-blue bg-primary-blue bg-opacity-10':
                    active === 'remaining',
                },
              )}
              onClick={() => {
                setActive('remaining');
                setDisabled(true);
                setFieldValue('amount', remainingRef.current.value);
              }}
            >
              <div className="flex-1 flex flex-col p-4">
                <h3 className="text-opaque-navy text-sm">Pay Off Full Balance</h3>
                <dl className="mt-1 flex-grow flex flex-col justify-between">
                  <dt className="sr-only">Remaining Balance</dt>
                  <dd
                    className={classNames('text-md text-navy-blue font-avenir-black', {
                      'text-primary-blue': active === 'remaining',
                    })}
                  >
                    {formatCurrency(order.product.payoff_balance)}
                  </dd>
                </dl>
                <input
                  value={order.product.payoff_balance.toString()}
                  name="remaining_balance"
                  ref={remainingRef}
                  className="hidden"
                  readOnly
                />
              </div>
            </div>
          )}
        </Field>
        <Field
          name="amount"
          value={customPaymentRef.current ? customPaymentRef.current.value : ''}
        >
          {() => (
            <div
              className={classNames(
                'col-span-1 flex flex-col rounded-lg shadow cursor-pointer hover:border-2 hover:border-primary-blue w-1/3 ml-2',
                {
                  'border-2 border-primary-blue bg-primary-blue bg-opacity-10':
                    active === 'custom',
                },
              )}
              onClick={() => {
                setActive('custom');
                setDisabled(false);
                setFieldValue('amount', customPaymentRef.current.value);
                customPaymentRef.current.focus();
              }}
            >
              <div className="flex-1 flex flex-col p-4">
                <h3 className="text-opaque-navy text-sm">Make Custom Payment</h3>
                <dl className="mt-1 flex-grow flex flex-col justify-between">
                  <dt className="sr-only">Custom Payment</dt>
                  <dd
                    className={classNames('text-md font-avenir-black text-navy-blue', {
                      'text-primary-blue': active === 'custom',
                    })}
                  >
                    {customPaymentRef.current &&
                    customPaymentRef.current.value > 0
                      ? formatCurrency(customPaymentRef.current.value)
                      : '---'}
                  </dd>
                </dl>
              </div>
            </div>
          )}
        </Field>
      </div>
      <label className="text-sm text-opaque-navy px-0.5">
        Please input custom payment amount
        <div className="relative rounded-md flex items-center">
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center">
            <FontAwesomeIcon icon={['far', 'dollar-sign']} />
          </div>
          <input
            name="payment_option_custom"
            ref={customPaymentRef}
            className={classNames(
              'w-full rounded-md mx-auto block pl-8 pt-2.5 sm:text-sm text-black',
              disabled
                ? 'cursor-not-allowed border-opaque-navy-border'
                : 'border-primary-blue border',
            )}
            type="number"
            min="0.01"
            step="0.01"
            disabled={disabled}
            placeholder='Enter payment amount'
            onFocus={(e) => {
              setFieldValue('amount', e.target.value);
            }}
            onChange={(e) => {
              setFieldValue('amount', e.target.value);
            }}
          />
        </div>
        <ErrorMessage
          name="amount"
          component="div"
          className={formStyles.validation}
        />
      </label>
    </>
  );
}
