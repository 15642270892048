import React from 'react';

import Box from '../../../shared/layout/Box';
import ZendeskChatLink from '../../../shared/ZendeskChatLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { supportLink } from '../../../../lib/constants/SiteVariables';

export default function SupportInfo({ supportWidgetSpan }) {
  return (
    <div
      className={supportWidgetSpan || 'md:col-span-full lg:col-span-2'}
      role={SupportInfo.role}
    >
      <Box>
        {{
          type: 'support',
          color: 'bg-secondary-purple',
          headerIcon: <FontAwesomeIcon icon="question" />,
          title: 'Need Support?',
          subtext: (
            <>
              <p>
                <span className="font-avenir-black">Monday - Friday:</span>{' '}
                10am-6pm CST
              </p>
              <p className="mt-2">
                Reach us at:
                <a
                  href="mailto: support@creditkey.com"
                  className="underline mx-2"
                >
                  support@creditkey.com
                </a>
                or <ZendeskChatLink />
              </p>
            </>
          ),
          submit: {
            label: 'Check out our Help Center for common questions!',
            handler: () => window.open(supportLink, '_blank'),
          },
        }}
      </Box>
    </div>
  );
}

SupportInfo.role = 'support-info-component';
