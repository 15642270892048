import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';

import Index from './components/Index';
import Login from './components/pages/Login';
import LoginToken from './components/pages/LoginToken';
import LockedOut from './components/shared/LockedOut';
import PrivateRoute from './components/shared/PrivateRoute';
import OrderDetail from './components/pages/order-detail/OrderDetail';
import Orders from './components/pages/orders/Orders';
import PaymentMethods from './components/pages/payment-methods/PaymentMethods';
import Profile from './components/pages/profile/Profile';
import Promo from './components/pages/Promo';
import PortalLayout from './components/layout/PortalLayout';
import Support from './components/shared/Support';
import { ProvideAuth } from './hooks/useAuth';
import { SuperTokensConfig } from './config';
import { USE_SUPERTOKENS } from './lib/constants/SiteVariables';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faBan,
  faPlus,
  faCircle,
  faCheckCircle,
  faDownload,
  faTimes,
  faArrowLeft,
  faExclamation,
  faFileInvoiceDollar as fasFileInvoiceDollar,
  faArrowRotateLeft,
  faQuestion,
  faFileArrowDown,
  faFileArrowUp,
  faEllipsisVertical,
  faSpinner,
  faArrowLeftLong,
  faArrowRightLong,
  faClipboard,
  faEye,
  faFileLines,
} from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';

import {
  faSearch,
  faMoneyBillTransfer,
  faCartShopping,
  faCircleQuestion,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faCircleInfo,
  faCalendarCheck,
  faUser,
} from '@fortawesome/pro-light-svg-icons';

import {
  faHouseBlank,
  faTrashCanXmark,
  faLockKeyhole,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faAngleDown,
  faAngleUp,
  faEdit,
  faDollarSign,
  faBuilding,
  faClock,
  faLock,
  faExclamationTriangle,
  faFileInvoiceDollar,
  faEnvelope,
  faChevronLeft,
  faChevronRight,
  faBars,
  faOctagonXmark,
  faHourglassClock,
  faCartShopping as farCartShopping,
  faXmark,
  faChevronsRight,
  faMegaphone,
  faCircleExclamationCheck,
  faChartPieSimple,
} from '@fortawesome/pro-regular-svg-icons';

import 'react-datepicker/dist/react-datepicker.css';

// Add icons to FA library for global usage
library.add(
  faCheck,
  faBan,
  faPlus,
  faCircle,
  faCheckCircle,
  faAngleDown,
  faAngleUp,
  faLock,
  faDownload,
  faEdit,
  faDollarSign,
  faSearch,
  faTimes,
  faBuilding,
  faClock,
  faFileInvoiceDollar,
  fasFileInvoiceDollar,
  faArrowLeft,
  faExclamationTriangle,
  faExclamation,
  faEnvelope,
  faArrowRotateLeft,
  faChevronLeft,
  faChevronRight,
  faBars,
  faHouseBlank,
  faCartShopping,
  faCircleQuestion,
  faMoneyBillTransfer,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faCircleInfo,
  faCreditCard,
  faQuestion,
  faFileArrowDown,
  faFileArrowUp,
  faEllipsisVertical,
  faOctagonXmark,
  faHourglassClock,
  faTrashCanXmark,
  farCartShopping,
  faCalendarCheck,
  faXmark,
  faSpinner,
  faArrowLeftLong,
  faArrowRightLong,
  faChevronsRight,
  faClipboard,
  faLockKeyhole,
  faEye,
  faMegaphone,
  faUser,
  faFileLines,
  faCircleExclamationCheck,
  faChartPieSimple,
);

if (USE_SUPERTOKENS) SuperTokens.init(SuperTokensConfig);

const Router = () => {
  return (
    <ProvideAuth>
      <PortalLayout>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Index />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders/:id"
            element={
              <PrivateRoute>
                <OrderDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment-methods"
            element={
              <PrivateRoute>
                <PaymentMethods />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route path="/promo/:promo_id" element={<Promo />} />
          <Route path="/support" element={<Support />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login-token/:type/:contact" element={<LoginToken />} />
          <Route path="/user_lockout" element={<LockedOut />} />
        </Routes>
      </PortalLayout>
    </ProvideAuth>
  );
};

export default function App() {
  return USE_SUPERTOKENS ? (
    <SuperTokensWrapper>
      <SessionAuth>
        <Router />
      </SessionAuth>
    </SuperTokensWrapper>
  ) : (
    <Router />
  );
}
