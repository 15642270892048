import Network, { addAuthorization } from '../../lib/utils/network';
import JsFileDownloader from 'js-file-downloader';
import { receiveEntity, receiveEntities, clearEntities } from './entities';
import { server } from '../../lib/constants/SiteVariables';

const type = 'orders';
const api = Network();

const path = (resource, params = '') => {
  if (!resource) resource = '';

  return {
    resource: 'api/' + type + '/' + resource,
    params: params ? `?${params}` : '',
  };
};

export const getOrder = (id, filters = '') => {
  return (dispatch, getState) => {
    return api
      .get(path(id, filters))
      .then((res) => dispatch(receiveEntity(res)));
  };
};

export const getOrders = (entity, filters = '') => {
  return (dispatch, getState) => {
    return api
      .get(path(null, filters))
      .then((res) => dispatch(receiveEntities(res)));
  };
};

export const clearOrders = () => {
  return (dispatch, getState) => {
    return dispatch(clearEntities('order'));
  };
};

export const downloadAgreement = async (order) => {
  const fileUrl = `${server()}/api/orders/${order.id}/agreement`;
  const options = await addAuthorization({ headers: {} });

  const agreementDownload = new JsFileDownloader({
    url: fileUrl,
    autoStart: false,
    contentType: false,
    headers: [
      {
        name: Object.keys(options.headers)[0],
        value: Object.values(options.headers)[0],
      },
    ],
  });

  return agreementDownload;
};

export const getDocuments = (order) => {
  return (dispatch, getState) => {
    return api.get(path(`${order.id}/documents`)).then((res) => {
      const withIDs = {
        data: res.data.map((i) => {
          return { ...i, attributes: { ...i.attributes, order_id: order?.id } };
        }),
      };
      dispatch(receiveEntities(withIDs));
    });
  };
};

export const downloadInvoice = async (order, documentID) => {
  const fileUrl = `${server()}/api/orders/${order.id}/documents/${documentID}`;
  const options = await addAuthorization({ headers: {} });

  const invoiceDownload = new JsFileDownloader({
    url: fileUrl,
    autoStart: false,
    contentType: false,
    headers: [
      {
        name: Object.keys(options.headers)[0],
        value: Object.values(options.headers)[0],
      },
    ],
  });

  return invoiceDownload;
};
