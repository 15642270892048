import React from 'react';
import useModal from '../../../../hooks/useModal';
import { DeletePmModal } from '../../../modals';

export const DeleteButton = React.forwardRef((props, ref) => {
  const { isModalActive, toggleModal } = useModal();
  return (
    <>
      <DeletePmModal
        isModalActive={isModalActive}
        payment_method={props.payment_method}
        toggleModal={toggleModal}
      />
      <div role={DeleteButton.role} ref={ref}>
        <button
          className="dropdown-item text-sm py-2 px-4 font-avenir-medium block w-full whitespace-nowrap bg-transparent text-negative-red hover:bg-negative-red hover:bg-opacity-20 text-left"
          onClick={toggleModal}
        >
          Delete Payment Method
        </button>
      </div>
    </>
  );
});

DeleteButton.role = 'delete-button';
