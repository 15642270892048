import { convertRawValues } from '../utils/record_utils';
import { Record } from 'immutable';
import { upperCase } from 'lodash';

export default class PaymentMethodRecord extends Record({
  id: null,
  user_id: null,
  account_id: null,
  payment_type: '',
  label: '',
  account_holder: '',
  bank_name: '',
  bank_account: '',
  bank_routing: '',
  status: '',
  is_default: false,
}) {
  constructor(data) {
    data = convertRawValues(data);
    super(data);
  }

  get payment_method_label() {
    return this.label || this.account_holder;
  }

  get payment_method_last_4() {
    return this.bank_account.slice(-4) || this.label.slice(-4);
  }

  get credit_card_masked() {
    return `x${this.label.slice(-4)}`;
  }

  get credit_card_type() {
    return upperCase(this.label.substring(0, 4));
  }

  get account_number_masked() {
    return (
      this.bank_account.slice(0, -4).replace(/./g, '*') +
      this.payment_method_last_4
    );
  }
}
