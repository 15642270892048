import classNames from 'classnames';
import { formatPhoneNumber } from '../../../../../lib/utils/formatters';
import DeleteUserButton from './DeleteUserButton';

export const BuildStack = ({ original }) => {
  return (
    <>
      <p className="font-avenir-black lg:font-avenir-medium">
        <span className="text-navy-blue">
          {original.first_name} {original.last_name}
        </span>
      </p>
      <p className="block xs:hidden">
        Email:
        <span className="font-avenir-black text-navy-blue ml-1.5">
          {original.email}
        </span>
      </p>
      <p className="block sm:hidden">
        Phone:
        <span className="font-avenir-black text-navy-blue ml-1.5">
          {formatPhoneNumber(original.phone)}
        </span>
      </p>
      <p className="block lg:hidden">
        Role:
        <span
          className={classNames(
            'font-avenir-black ml-1.5',
            original.role === 'borrower'
              ? 'text-positive-green'
              : 'text-navy-blue',
          )}
        >
          {original.role === 'borrower' ? 'Primary Owner' : 'Purchaser'}
        </span>
      </p>
      <div className="block lg:hidden text-negative-red">
        {original.role === 'borrower' ? null : (
          <DeleteUserButton user={original} />
        )}
      </div>
    </>
  );
};
