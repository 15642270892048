import { fromJS, OrderedMap } from 'immutable';
import * as ActionTypes from '../ActionTypes';

import AddressRecord from '../../lib/records/address';
import CompanyRecord from '../../lib/records/company';
import AccountRecord from '../../lib/records/account';
import LoanRecord from '../../lib/records/loan';
import MerchantRecord from '../../lib/records/merchant';
import OrderDocumentRecord from '../../lib/records/order_document';
import OrderRecord from '../../lib/records/order';
import PaymentMethodRecord from '../../lib/records/payment_method';
import PrequalificationRecord from '../../lib/records/prequalification';
import ShoppingCartRecord from '../../lib/records/shopping_cart';
import UserRecord from '../../lib/records/user';

const models = {
  account: AccountRecord,
  address: AddressRecord,
  company: CompanyRecord,
  loan: LoanRecord,
  merchant: MerchantRecord,
  order: OrderRecord,
  order_document: OrderDocumentRecord,
  payment_method: PaymentMethodRecord,
  prequalification: PrequalificationRecord,
  shopping_cart: ShoppingCartRecord,
  user: UserRecord,
};

export const entitiesState = fromJS(models).map((m) => new OrderedMap());

function setState(state, newState) {
  return state.merge(newState);
}

export default function entities(state = entitiesState, action) {
  switch (action.type) {
    case ActionTypes.RESET_ENTITIES:
      return entitiesState;

    case ActionTypes.CLEAR_ENTITIES:
      if (action.payload) {
        return setState(
          state,
          state.set(action.payload, entitiesState.get(action.payload)),
        );
      }
      return entitiesState;

    case ActionTypes.CLEAR_ENTITY:
      if (!action.payload) return state;

      return setState(
        state,
        state.deleteIn([action.payload.data.type, action.payload.data.id]),
      );

    case ActionTypes.RECEIVE_ENTITIES:
      if (action.payload.data && action.payload.data.length < 1) return state;

      action.payload.data.map(
        (resource) =>
          (state = setState(
            state,
            state.setIn(
              [resource.type, resource.id],
              new models[resource.type](
                fromJS({ ...{ id: resource.id }, ...resource.attributes }),
              ),
            ),
          )),
      );

      return state;

    case ActionTypes.RECEIVE_ENTITY:
      return setState(
        state,
        state.setIn(
          [action.payload.data.type, action.payload.data.id],
          new models[action.payload.data.type](
            fromJS({
              ...{ id: action.payload.data.id },
              ...action.payload.data.attributes,
            }),
          ),
        ),
      );

    default:
      return state;
  }
}
