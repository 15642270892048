import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CTAButton from '../../shared/CTAButton';
import Loading from '../../shared/components/Loading';
import PageLayout from './PageLayout';
import PaymentMethodList from './components/PaymentMethodList';
import Tooltip from '../../shared/components/Tooltip';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useEntity from '../../../hooks/useEntity';
import useModal from '../../../hooks/useModal';
import usePaymentMethods from '../../../hooks/usePaymentMethods';

export default function PaymentMethods() {
  const { isModalActive, toggleModal } = useModal();
  const { payment_methods } = usePaymentMethods();
  const [user] = useCurrentUser();
  const [entity] = useEntity();

  const bank_accounts = payment_methods
    .valueSeq()
    .filter((pm) => pm.payment_type === 'bank_account');

  const cards = payment_methods
    .valueSeq()
    .filter((pm) => pm.payment_type === 'card');

  const headerProps = { isModalActive, toggleModal };
  const checkIsDefault =
    payment_methods.find((pm) => pm.is_default) ===
    payment_methods.find((pm) => pm.payment_type === 'check');

  if (!payment_methods) return <Loading />;

  return (
    <PageLayout {...headerProps}>
      <div className="flex-1">
        <div className="mx-auto mt-4 md:mt-9 px-4 md:px-0">
          <div className="mt-9 md:mt-16">
            <div className="font-avenir-black text-base sm:text-lg text-primary-blue">
              Bank Accounts
            </div>
            {entity?.type === 'company' && (
              <PaymentMethodList payment_methods={bank_accounts} />
            )}
          </div>
          {checkIsDefault && (
            <div className="mt-8 md:mt-12">
              <div className="my-2 flex items-center text-opaque-navy">
                <span className="hidden xs:flex xs:mr-2">
                  <FontAwesomeIcon
                    icon={['fal', 'circle-info']}
                    className="hidden xs:inline h-4 w-4 xs:pb-0.5"
                  />
                </span>
                <div className="font-avenir-medium text-sm md:text-base">
                  <Tooltip
                    tooltip_text={
                      <>
                        <p>
                          To set a default payment method, click the 3 dots
                        </p>
                        <p>
                          to the right of your preferred payment method and
                          select
                          <span className="text-navy-blue ml-1.5">Set as Default</span>
                        </p>
                      </>
                    }
                    trigger_class="border-b border-dotted border-black cursor-pointer"
                    container_class="text-center p-4 px-6 bg-dark-gray"
                  >
                    <span>
                      In order to make payments online, please set a default{' '}
                      {user?.hasAccount
                        ? 'bank account or credit card'
                        : 'bank account'}
                      .
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
          {user?.hasAccount && (
            <div className="mt-8 md:mt-12">
              <div className="font-avenir-black text-base sm:text-lg text-primary-blue">
                Credit/Debit Cards
              </div>
              {user?.hasCompany &&
                user?.hasAccount &&
                entity?.type === 'company' &&
                cards.count() > 0 && (
                  <div className="my-2 flex items-center text-opaque-navy">
                    <span className="hidden xs:flex xs:mr-3.5">
                      <FontAwesomeIcon
                        icon={['fal', 'circle-info']}
                        className="hidden xs:inline xs:h-5 xs:w-5 md:h-6 md:w-6 xs:pb-0.5"
                      />
                    </span>
                    <p className="font-avenir-medium text-sm md:text-base">
                      <span>
                        You’ve been upgraded to a Credit Key business credit
                        account. <br />
                        Business accounts require use of a bank account as the
                        payment method on all new orders.
                      </span>
                    </p>
                  </div>
                )}
              <PaymentMethodList payment_methods={cards} />
            </div>
          )}
        </div>
      </div>
      <CTAButton
        ariaLabel="add_payment_method_button"
        buttonClass="bg-primary-blue text-white font-avenir-medium rounded-full py-3 px-6 cursor-pointer text-sm md:text-base inline-block hover:bg-navy-blue"
        buttonText="Add new payment method"
        containerClass="px-4 sm:px-0 mt-6 md:mt-9 flex justify-flex-start"
        onClick={toggleModal}
      />
    </PageLayout>
  );
}

PaymentMethods.role = 'payment-methods-component';
