import Network from '../../lib/utils/network';

const api = Network();

const path = (resource, namespace, params = '') => {
  if (!resource) resource = '';
  if (!namespace) namespace = '';
  return { 
    resource: `api/${namespace}payments/` + resource,
    params: params ? `?${params}` : ''
  };
};

export const createLoanPayment = (payment) => {
  return (dispatch, getState) => {
    return api.post(path(), { payment });
  };
};

export const createPaymentPlanPayment = (payment) => {
  return (dispatch, getState) => {
    return api.post(path('', 'pi4/'), { payment });
  };
};

export const exportPaymentHistory = ({userId, startDate, endDate}) => {
  return (dispatch, getState) => {
    const params = `user_id=${userId}&start_date=${startDate}&end_date=${endDate}`;
    return api.get(path('export', null, params));
  };
};
