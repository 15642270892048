import { Record } from 'immutable';

import { convertRawValues } from '../utils/record_utils';

export default class AccountRecord extends Record({
  id: null,
  type: 'account',
  name: '',
  status: '',
  bank_verification_attempts: 0,
  applicant_fico_score: '',
  tier: '',
  tier_limit: '',
  tier_limit_available: '',
  is_past_due: false,
  is_bank_connected: '',
  is_bank_connection_pending: '',
}) {
  constructor(data) {
    data = convertRawValues(data);
    super(data);
  }

  get purchase_amount_in_use() {
    return this.tier_limit - this.tier_limit_available;
  }
}
